import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import LogoHorizontal from '../logo/logo-horizontal'
import { Section, SidebarItem } from './index'


interface SidebarProps {
    sections: Section[]
}

const Sidebar = ({ sections }: SidebarProps) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const handleNavigate = useCallback((to?: string) => {
        if (to) {
            navigate(to)
        }
    }, [navigate])
    
    return (
        <div className='hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col'>
            <div className='flex grow flex-col gap-y-3 overflow-y-auto bg-white p-6 lg:px-8 pt-3 border-r'>
                <div className='flex py-1.5 shrink-0 items-center'>
                    <LogoHorizontal to='/dashboard'/>
                </div>
                <nav className='flex flex-1 flex-col mt-2'>
                    <ul className='flex flex-1 flex-col gap-y-3'>
                        {sections.map((section, index) => (
                            <React.Fragment key={index}>
                                {section.disclosure ?
                                 <Disclosure as='div'>
                                     {({ open }) => (
                                         <React.Fragment>
                                             <Disclosure.Button
                                                 as='button'
                                                 className={classesOf(
                                                     'invisible sm:visible',
                                                     'flex items-center gap-3',
                                                     'font-semibold leading-7',
                                                     'mb-3',
                                                     'cursor-pointer',
                                                     palette.side_bar.category_text
                                                 )}>
                                                 <ChevronRightIcon
                                                     className={classesOf(
                                                         open ? 'rotate-90' : '',
                                                         'h-4 w-4'
                                                     )}
                                                 />
                                                 {section.category}
                                             </Disclosure.Button>
                                             <Disclosure.Panel as='ul' className={classesOf(
                                                 'flex flex-row sm:flex-col',
                                                 'gap-x-6 sm:gap-y-1',
                                                 'sm:mb-6'
                                             )}>
                                                 {section.items?.map((item, i) => (
                                                     <SidebarItem key={i} {...item} />
                                                 ))}
                                             </Disclosure.Panel>
                                         </React.Fragment>
                                     )}
                                 </Disclosure> :
                                 <li className='cursor-pointer'>
                                     {section.category && (
                                         <div onClick={() => handleNavigate(section?.to)}
                                              className={classesOf(
                                                  pathname.includes(section.to ?? '')
                                                  ? 'bg-gray-50 text-orange-500'
                                                  : 'hover:text-orange-500 hover:bg-gray-50',
                                                  'font-semibold mb-2 p-2 pl-4'
                                              )}>
                                             {section.category}
                                         </div>
                                     )}
                                     
                                     <ul className='-mx-2 space-y-1'>
                                         {section.items?.map(item => (
                                             <li key={item.name}>
                                                 <SidebarItem {...item} />
                                             </li>
                                         ))}
                                     </ul>
                                 </li>
                                }
                            </React.Fragment>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Sidebar
