import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import { TabPath } from '../../../components/tab'
import { TabItemProps } from '../../../components/tab/tab-path-item'
import { trans } from '../../../locales/ko'


const ViewSetting = () => {
    const tabItems: TabItemProps[] = useMemo(() => {
        return [
            { name: trans.views.dashboard.setting.score_strategy.it, to: `/setting` },
            { name: trans.views.dashboard.setting.learning_option.it, to: `/setting/learning-option` },
            { name: trans.views.dashboard.setting.apply_option.it, to: `/setting/apply-option` },
        ]
    }, [])
    
    return (
        <React.Fragment>
            <div className='px-4 sm:px-6 lg:px-8'>
                <div className='sm:flex sm:items-center'>
                    <div className='sm:flex-auto'>
                        <h1 className='text-2xl font-semibold leading-6 mb-4'>{trans.views.dashboard.setting.it}</h1>
                    </div>
                </div>
                <div>
                    <TabPath items={tabItems}/>
                    <Outlet/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ViewSetting