import { ErrorMessage, Field, FieldProps } from 'formik'
import React, { useEffect, useRef } from 'react'
import { NumericFormat } from 'react-number-format'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


interface FieldNumberSchemeInlinedProps {
    id?: string
    name?: string
    scheme: string
    host?: string
    hostComponent?: React.ReactNode
    autoComplete?: string
    placeholder?: string
    error?: any
    autoFocus?: boolean
    className?: string
    containerClassName?: string
    schemeClassName?: string
    
    [key: string]: any
}

const FieldNumberSchemeInlined = ({
    scheme,
    host,
    hostComponent,
    error,
    className = '',
    containerClassName = '',
    schemeClassName = '',
    autoFocus = false,
    ...props
}: FieldNumberSchemeInlinedProps) => {
    const fieldEl = useRef<HTMLInputElement>(null)
    
    useEffect(() => {
        if (autoFocus && fieldEl.current) {
            fieldEl.current.focus()
        }
    }, [autoFocus])
    
    return (
        <div className={classesOf('relative flex-col', containerClassName)}>
            <div className={classesOf(
                'flex',
                'rounded-md',
                'border border-gray-300',
                'shadow-sm',
                'focus-within:ring-2 focus-within:ring-inset', palette.field_scheme.ring_focus,
                error ? 'ring-1 ring-inset' : '',
                error ? palette.field_scheme.ring_error : ''
            )}>
                <span className={classesOf(
                    'inline-flex items-center justify-center',
                    'pl-3',
                    'text-sm', palette.field_scheme.text_scheme,
                    error ? palette.field_scheme.text_error : '',
                    'shrink-0',
                    schemeClassName
                )}>{scheme}</span>
                
                <Field
                    {...props}
                    className={classesOf(
                        'block relative w-full',
                        'border-0',
                        'bg-transparent',
                        'py-2 pl-1',
                        host ? 'pr-1' : 'pr-3',
                        palette.field_scheme.text, palette.field_scheme.text_placeholder,
                        'focus:ring-0',
                        'text-sm leading-6',
                        className
                    )} innerRef={fieldEl}
                >
                    {({ field }: FieldProps) => (
                        <NumericFormat
                            {...props}
                            {...field}
                            id={field.name}
                            className={classesOf(
                                'block relative w-full',
                                'border-0',
                                'bg-transparent',
                                'py-2 pl-1',
                                host ? 'pr-1' : 'pr-3',
                                palette.field_scheme.text, palette.field_scheme.text_placeholder,
                                'focus:ring-0',
                                'text-sm leading-6',
                                className
                            )}
                            thousandSeparator={true}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }}
                        />
                    )}
                </Field>
                
                {host && (
                    <span className={classesOf(
                        'flex select-none items-center',
                        'pr-3',
                        'text-sm', palette.field_scheme.text_scheme,
                        error ? palette.field_scheme.text_error : ''
                    )}>{host}</span>
                )}
                {hostComponent && (
                    <div>
                        {hostComponent}
                    </div>
                )}
            </div>
            {props.name && <ErrorMessage name={props.name} component='p' className={classesOf('mt-2 ml-1 text-sm whitespace-pre-line', palette.field.text_error)}/>}
        </div>
    )
}

export default FieldNumberSchemeInlined
