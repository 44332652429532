import { CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useState } from 'react'

import useCopyToClipboard from '../../hooks/use-copy-to-clipboard'
import { classesOf } from '../../libs/utils/classes-of'


interface CopyProps {
    text: string
    icon?: React.ElementType
    copiedIcon?: React.ElementType
}

const Copy = ({ text, icon: Icon = DocumentDuplicateIcon, copiedIcon: CopiedIcon = CheckIcon }: CopyProps) => {
    const [isCopied, setIsCopied] = useState(false)
    const copy = useCopyToClipboard()
    
    const handleCopy = useCallback(async (text: string) => {
        if (isCopied) return
        
        await copy(text)
        setIsCopied(true)
        
        setTimeout(() => {
            setIsCopied(false)
        }, 600)
    }, [isCopied, copy])
    
    return (
        <React.Fragment>
            {isCopied ? (
                <CopiedIcon className={classesOf('w-auto h-5 shrink-0', 'stroke-2 text-gray-500', 'cursor-pointer')}/>
            ) : (
                 <Icon className={classesOf('w-auto h-5 shrink-0', 'stroke-2 text-gray-500', 'cursor-pointer')}
                       onClick={() => handleCopy(text)}
                 />
             )}
        </React.Fragment>
    )
}

export default Copy
