import * as XLSX from 'xlsx'


export const exportToExcel = (fields: string[], data: any[], filename: string, type: 'xlsx' | 'csv') => {
    const ws = XLSX.utils.json_to_sheet(data)
    XLSX.utils.sheet_add_aoa(ws, [fields], { origin: 'A1' })
    
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')
    const excelData = XLSX.write(wb, { bookType: type, bookSST: false, type: 'array' })
    
    const blob = new Blob([excelData], { type: 'application/octet-stream' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', `${filename}.${type}`)
    link.click()
}
