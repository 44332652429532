import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

import { CheckboxOption } from '../checkbox'
import CheckboxOptionText from '../checkbox/checkbox-option-text'
import { FieldCheckbox } from '../field'


interface FilterPopoverProps {
    filterLength: number
    filterOptions: CheckboxOption<any>[]
}

const FilterPopover = ({ filterLength, filterOptions }: FilterPopoverProps) => {
    return (
        <Popover.Group>
            <Popover
                as='div'
                className='relative inline-block text-left'
            >
                <div>
                    <Popover.Button className='group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900'>
                        <FunnelIcon className='mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500'/>
                        {filterLength} Filters
                        <ChevronDownIcon
                            className='-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500'
                            aria-hidden='true'
                        />
                    </Popover.Button>
                </div>
                
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    <Popover.Panel className='absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <FieldCheckbox name='filters' options={filterOptions} as={CheckboxOptionText}/>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </Popover.Group>
    )
}

export default FilterPopover