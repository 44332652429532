import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { isBefore, isSameDay } from 'date-fns'
import { Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useState } from 'react'
import * as Yup from 'yup'

import ExplorerHeader from '../../../../components/explorer/explorer-header'
import FilterPopover from '../../../../components/popover/filter-popover'
import TableLearningHistoryByWeek from '../../../../components/table/table-learning-history-by-week'
import TableLearningSession from '../../../../components/table/table-learning-session'
import { useLearningHistoryListQuery } from '../../../../hooks/use-learning-history'
import { LearningHistoryData, LearningHistoryPerDay } from '../../../../libs/model/learning-history'
import { msToHoursMinutesAndSeconds } from '../../../../libs/utils/fomatter-time'
import { trans } from '../../../../locales/ko'


interface IFormFilterPopover {
    filters: string[]
}

interface LearningStatisticsProps {
    userId: string
}

const LearningStatistics = ({ userId }: LearningStatisticsProps) => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1)
    const formattedDate = useMemo(() => `${currentYear}-${(currentMonth).toString().padStart(2, '0')}`, [currentYear, currentMonth])
    
    const isFuture = useMemo(() => {
        const firstDayOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth())
        const firstDayOfSelectedMonth = new Date(currentYear, currentMonth - 1)
        return isSameDay(firstDayOfCurrentMonth, firstDayOfSelectedMonth)
    }, [currentYear, currentMonth])
    
    const handlePreviousClick = useCallback(() => {
        setCurrentMonth(prevMonth => {
            if (prevMonth === 1) {
                setCurrentYear(currentYear - 1)
                return 12
            } else {
                return prevMonth - 1
            }
        })
    }, [currentYear, setCurrentMonth, setCurrentYear])
    
    const handleNextClick = useCallback(() => {
        setCurrentMonth(prevMonth => {
            if (prevMonth === 12) {
                setCurrentYear(currentYear + 1)
                return 1
            } else {
                return prevMonth + 1
            }
        })
    }, [currentYear, setCurrentMonth, setCurrentYear])
    
    const { data: learningHistoryList, isLoading } = useLearningHistoryListQuery([userId], formattedDate)
    const userLearningHistory = useMemo(() => {
        if (!userId || isEmpty(learningHistoryList)) return {}
        const [learningHistoryByUser] = Object.values(learningHistoryList)
        const startDayOfWeek = Object.keys(learningHistoryByUser)
        const learningHistoryByWeek = Object.values(learningHistoryByUser)
        
        return { learningHistoryByUser, startDayOfWeek, learningHistoryByWeek }
    }, [learningHistoryList, userId])
    
    const activeLearningHistory: LearningHistoryData[] = useMemo(() => {
        return userLearningHistory?.learningHistoryByWeek?.flatMap((each) => {
            return (
                Object.entries(each).flatMap(([date, learningHistoryData]: [string, Omit<LearningHistoryData, 'date'>[]]) => {
                    return learningHistoryData.map(data => ({ ...data, date }))
                })
            )
        }) ?? []
    }, [userLearningHistory?.learningHistoryByWeek])
    
    const averages = useMemo(() => {
        if (isEmpty(activeLearningHistory)) return {}
        const totals = activeLearningHistory.reduce((acc, curr) => {
            acc.score += curr.score
            acc.timeInMs += curr.timeInMs
            acc.count += 1
            return acc
        }, { score: 0, timeInMs: 0, count: 0 })
        
        return {
            totalTimeInMs: msToHoursMinutesAndSeconds(totals.timeInMs),
            timeInMs: msToHoursMinutesAndSeconds(totals.timeInMs / totals.count),
            score: (totals.score / totals.count).toFixed(1)
        }
    }, [activeLearningHistory])
    
    const statistics = useMemo(() => [
        { name: trans.views.dashboard.member_details.learning_statistics.month_study_time, value: averages.totalTimeInMs ?? '-' },
        { name: trans.views.dashboard.member_details.learning_statistics.average_conversation_time, value: averages.timeInMs ?? '-' },
        { name: trans.views.dashboard.member_details.learning_statistics.average_score, value: averages.score ?? '-' }
    ] ?? [], [averages.score, averages.timeInMs, averages.totalTimeInMs])
    
    const filterOptions = useMemo(() => [
        { value: 'accuracyPoint', label: '정확성', id: 'accuracyPoint' },
        { value: 'fluencyPoint', label: '유창성', id: 'fluencyPoint' },
        { value: 'missionPoint', label: '미션 수행', id: 'missionPoint' },
        { value: 'assistantPoint', label: '자기 주도', id: 'assistantPoint' },
        { value: 'levelPoint', label: '난이도', id: 'levelPoint' },
        { value: 'conversation_time', label: '대화 시간', id: 'conversation_time' },
        { value: 'learning_count', label: '학습 횟수', id: 'learning_count' },
        { value: 'score', label: '합계', id: 'score' }
    ], [])
    
    const getInitialFilters = useMemo(() => {
        const savedFilters = localStorage.getItem('filters_member')
        return savedFilters ? JSON.parse(savedFilters) : filterOptions.map(each => each.value)
    }, [filterOptions])
    
    const initialValues: IFormFilterPopover = {
        filters: getInitialFilters
    }
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            filters: Yup.array()
        })
    }, [])
    const onSubmit = useCallback(() => {
    }, [])
    
    return (
        <ExplorerHeader
            title={trans.views.dashboard.member_details.learning_statistics.it} hasBorder={false}
            action={
                <div className='flex items-center justify-center gap-4'>
                    <button onClick={handlePreviousClick}>
                        <ChevronLeftIcon className='w-6 h-auto'/>
                    </button>
                    <span>{`${currentYear}년 ${currentMonth}월`}</span>
                    {isFuture ? <div className='w-6'/> : <button onClick={handleNextClick}><ChevronRightIcon className='w-6 h-auto'/></button>}
                </div>
            }
        >
            <div className='grid grid-cols-1 gap-2 sm:grid-cols-4'>
                {statistics.map((each) => (
                    <div key={each.name} className='rounded-xl bg-orange-50 px-4 py-5 sm:p-6'>
                        <p className='truncate text-sm font-medium'>{each.name}</p>
                        {isLoading ?
                         <p className='mt-3 animate-pulse bg-gray-200 w-full h-8 rounded-md'/> :
                         <p className='mt-1 text-3xl font-semibold tracking-tight text-orange-500 break-words'>{each.value}</p>
                        }
                    </div>
                ))}
            </div>
            <React.Fragment>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
                    {({ values }) => {
                        localStorage.setItem('filters_member', JSON.stringify(values.filters))
                        return (
                            <Form>
                                <div className='mt-8 flex items-center justify-between'>
                                    <p className='font-semibold text-lg'>{trans.views.dashboard.member_details.learning_statistics.statistics_by_period}</p>
                                    <FilterPopover filterLength={values.filters.length} filterOptions={filterOptions}/>
                                </div>
                                {!isEmpty(Object.values(userLearningHistory)) ?
                                 <React.Fragment>
                                     {userLearningHistory?.learningHistoryByWeek?.map((weekData: LearningHistoryPerDay, index) => {
                                         if (isBefore(new Date(Object.keys(weekData)[0]), new Date)) return (
                                             <TableLearningHistoryByWeek weekData={weekData} index={index} key={index} filters={values.filters}/>
                                         )
                                     })}
                                 </React.Fragment> : null}
                                <p className='mt-7 mb-3 font-semibold text-lg'>{trans.views.dashboard.member_details.learning_statistics.training_specific_statistics}</p>
                            </Form>
                        )
                    }}
                </Formik>
                <TableLearningSession learningSessions={activeLearningHistory}/>
            </React.Fragment>
        
        </ExplorerHeader>
    )
}

export default LearningStatistics