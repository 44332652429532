import { Form, Formik } from 'formik'
import React, { useCallback, useMemo } from 'react'

import { ButtonSolid } from '../../../../components/button'
import ExplorerHeader from '../../../../components/explorer/explorer-header'
import ExplorerTextField from '../../../../components/explorer/explorer-text-field'
import { FieldSelector } from '../../../../components/field'
import Toggle from '../../../../components/toggle/toggle'
import { useUpdateLearningOptionsMutation } from '../../../../hooks/use-group'
import { useActiveStudyGroup } from '../../../../hooks/use-study-group'
import { useToast } from '../../../../hooks/use-toast'
import { classesOf } from '../../../../libs/utils/classes-of'
import { trans } from '../../../../locales/ko'


interface IFormLearningOption {
    hintAllowed: boolean
    hintPronunciationAllowed: boolean
    voiceReplayAllowed: boolean
    translateAllowed: boolean
    keyboardAllowed: boolean
    ttsMute: boolean
    ttsSpeed: number
    ttsLocale: string
    level: number
    learningTime: number
}

const ViewLearningOption = () => {
    const { showToast } = useToast()
    const { studyGroup } = useActiveStudyGroup()
    const { mutateAsync: updateLearningOptions, isPending } = useUpdateLearningOptionsMutation()
    
    const learningOptions = useMemo(() => studyGroup?.learningOptions ?? {}, [studyGroup?.learningOptions])
    
    const speedOptions = useMemo(() => ['0.7', '0.8', '0.9', '1.0', '1.1', '1.2', '1.3'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const LocaleOptions = useMemo(() => [
        { label: trans.views.dashboard.member_details.learning_setting.usa, value: 'en' },
        { label: trans.views.dashboard.member_details.learning_setting.uk, value: 'gb' },
        { label: trans.views.dashboard.member_details.learning_setting.australia, value: 'au' },
        { label: trans.views.dashboard.member_details.learning_setting.india, value: 'in' }
    ], [])
    
    const levelOptions = useMemo(() => ['1', '2', '3', '4', '5', '6'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const learningTimeOptions = useMemo(() => ['5', '10', '15', '20'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const initialValues: IFormLearningOption = useMemo(() => {
        return {
            hintAllowed: learningOptions.hintAllowed ?? false,
            hintPronunciationAllowed: learningOptions.hintPronunciationAllowed ?? false,
            voiceReplayAllowed: learningOptions.voiceReplayAllowed ?? false,
            translateAllowed: learningOptions.translateAllowed ?? false,
            keyboardAllowed: learningOptions.keyboardAllowed ?? false,
            ttsMute: learningOptions.ttsMute ?? false,
            ttsSpeed: learningOptions.ttsSpeed ?? 0,
            ttsLocale: learningOptions.ttsLocale ?? '',
            level: learningOptions.level ?? 0,
            learningTime: learningOptions.learningTime ?? 0
        }
    }, [learningOptions.hintAllowed, learningOptions.hintPronunciationAllowed, learningOptions.keyboardAllowed, learningOptions.learningTime, learningOptions.level, learningOptions.translateAllowed, learningOptions.ttsLocale, learningOptions.ttsMute, learningOptions.ttsSpeed, learningOptions.voiceReplayAllowed])
    
    const onSubmit = useCallback(async (values: IFormLearningOption) => {
        if (isPending) return
        
        try {
            await updateLearningOptions({ params: { ...values } })
            showToast({ title: trans.views.dashboard.sub_group_detail.learning_setting.update_success, type: 'success' })
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [isPending, showToast, updateLearningOptions])
    
    return (
        <div className='mt-6'>
            <p className='font-semibold whitespace-pre-line'>{trans.views.dashboard.setting.learning_option.description}</p>
            <div className={classesOf('relative', 'mt-6 p-4 sm:p-0')}>
                <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                    {({ values, setFieldValue, dirty }) => {
                        return (
                            <Form>
                                <ExplorerHeader
                                    title={trans.views.dashboard.sub_group_detail.learning_setting.it}
                                    action={<ButtonSolid type='submit' variant={dirty ? 'default' : 'disabled'} loading={isPending}>{trans.actions.save}</ButtonSolid>}
                                >
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.hintAllowed}
                                        content={<Toggle enabled={values.hintAllowed} onChange={(value: boolean) => setFieldValue('hintAllowed', value)}/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.hintPronunciationAllowed}
                                        content={<Toggle enabled={values.hintPronunciationAllowed} onChange={(value: boolean) => setFieldValue('hintPronunciationAllowed', value)}/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.voiceReplayAllowed}
                                        content={<Toggle enabled={values.voiceReplayAllowed} onChange={(value: boolean) => setFieldValue('voiceReplayAllowed', value)}/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.translateAllowed}
                                        content={<Toggle enabled={values.translateAllowed} onChange={(value: boolean) => setFieldValue('translateAllowed', value)}/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.keyboardAllowed}
                                        content={<Toggle enabled={values.keyboardAllowed} onChange={(value: boolean) => setFieldValue('keyboardAllowed', value)}/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.ttsMute}
                                        content={<Toggle enabled={values.ttsMute} onChange={(value: boolean) => setFieldValue('ttsMute', value)}/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.ttsSpeed}
                                        content={<FieldSelector name='ttsSpeed' options={speedOptions} containerClassName='w-full sm:w-1/2'/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.ttsLocale}
                                        content={<FieldSelector name='ttsLocale' options={LocaleOptions} containerClassName='w-full sm:w-1/2'/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.conversation_level}
                                        content={<FieldSelector name='level' options={levelOptions} containerClassName='w-full sm:w-1/2'/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.sub_group_detail.learning_setting.learningTime}
                                        content={<FieldSelector name='learningTime' options={learningTimeOptions} containerClassName='w-full sm:w-1/2'/>}
                                    />
                                </ExplorerHeader>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default ViewLearningOption