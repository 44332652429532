import { Form, Formik, FormikProps } from 'formik'
import React, { useCallback, useMemo, useRef } from 'react'
import * as Yup from 'yup'

import { useCreateSubGroupMutation } from '../../../hooks/use-sub-group'
import { useToast } from '../../../hooks/use-toast'
import { trans } from '../../../locales/ko'
import { FieldText } from '../../field'
import { Slide, SlideOver } from '../../slide'


interface IFormBuilderSubGroup {
    subGroupName: string
}

interface BuilderSubGroupProps {
    open: boolean
    onClose: () => void
}

const BuilderSubGroup = ({ open, onClose }: BuilderSubGroupProps) => {
    const { showToast } = useToast()
    const { mutateAsync: createSubGroup, isPending } = useCreateSubGroupMutation()
    
    const formRef = useRef<FormikProps<IFormBuilderSubGroup> | null>(null)
    
    const initialValues: IFormBuilderSubGroup = { subGroupName: '' }
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            subGroupName: Yup.string().required(trans.components.builder.sub_group.error.sub_group_name_required)
        })
    }, [])
    
    const handleConfirm = useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }, [])
    
    const onSubmit = useCallback(async (values: IFormBuilderSubGroup) => {
        if (isPending) return
        
        try {
            await createSubGroup({ subGroupName: values.subGroupName, memberIds: [] })
            onClose()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [createSubGroup, isPending, onClose, showToast])
    
    return (
        <SlideOver
            open={open}
            onClose={onClose}
            title={trans.components.builder.sub_group.title}
            confirm={trans.actions.create}
            handleConfirm={handleConfirm}
            isLoading={isPending}
        >
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={formRef}>
                {({ errors, touched, values, dirty }) => {
                    return (
                        <Slide.Content>
                            <Form>
                                <div className='space-y-6 pb-5'>
                                    <div className='sm:max-w-sm'>
                                        <label htmlFor='subGroupName' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.sub_group.name}
                                        </label>
                                        <FieldText
                                            id='subGroupName' name='subGroupName'
                                            error={errors.subGroupName && touched.subGroupName}
                                            placeholder={trans.components.builder.sub_group.name}
                                            autoFocus
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Slide.Content>
                    )
                }}
            </Formik>
        </SlideOver>
    )
}

export default BuilderSubGroup