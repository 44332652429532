import React from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import { CheckboxOption } from './index'


interface CheckboxOptionTextProps<TValue> {
    option: CheckboxOption<TValue>
    onChange: () => void
    checked: boolean
}

const CheckboxOptionText = <TValue, >({ option, onChange, checked }: CheckboxOptionTextProps<TValue>) => {
    return (
        <li className='flex items-center gap-3'>
            <input type='checkbox' id={option.id} checked={checked} onChange={onChange}
                   className={classesOf(
                       'w-4 h-4',
                       'rounded',
                       'text-orange-500',
                       'border-gray-300', palette.checkbox.option_text.active_ring,
                       'cursor-pointer'
                   )}
            />
            <label htmlFor={option.id} className='font-semibold truncate cursor-pointer'>{option.label}</label>
        </li>
    )
}

export default CheckboxOptionText
