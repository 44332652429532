import axios, { AxiosResponse } from 'axios'


export const requestKakaoSignIn = async (code: string, redirectUri: string) => {
    try {
        const response: AxiosResponse = await axios({
            method: 'POST',
            url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/signInKakao`,
            data: { code, redirectUri }
        })
        return response.data.result?.customToken
    } catch (ex) {
        console.warn('ex', ex)
        return null
    }
}