import { useField, useFormikContext } from 'formik'
import React, { useCallback } from 'react'

import { Checkbox, CheckboxOption } from '../checkbox'



interface FieldCheckboxProps<TValue> {
    name: string
    options: CheckboxOption<TValue>[]
    as?: React.ElementType
    className?: string
    inViewRef?: (node?: Element | null) => void
}

const FieldCheckbox = <TValue, >({ name, options, ...props }: FieldCheckboxProps<TValue>) => {
    const [field] = useField<TValue[]>(name)
    const { setFieldValue, setFieldTouched } = useFormikContext()
    
    const handleChange = useCallback(async (value: TValue) => {
        await setFieldTouched(name, true)
        
        if (field.value.includes(value)) {
            await setFieldValue(name, field.value.filter(item => item !== value))
        } else {
            await setFieldValue(name, [...field.value, value])
        }
    }, [field.value, name, setFieldTouched, setFieldValue])
    
    return (
        <Checkbox
            options={options}
            value={field.value}
            onChange={handleChange}
            as={props.as}
            className={props.className}
            inViewRef={props.inViewRef}
        />
    )
}

export default FieldCheckbox
