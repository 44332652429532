import { ErrorMessage, Form, Formik, FormikErrors, FormikProps, FormikTouched } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useRef } from 'react'
import * as Yup from 'yup'

import { useAddScoreStrategyMutation } from '../../../hooks/use-score-strategy'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { useToast } from '../../../hooks/use-toast'
import { ScoreType } from '../../../libs/model/score-strategy'
import { palette } from '../../../libs/palette'
import { classesOf } from '../../../libs/utils/classes-of'
import { trans } from '../../../locales/ko'
import { FieldCheckbox, FieldNumberSchemeInlined, FieldRadio, FieldSelector, FieldText } from '../../field'
import FieldInlineText from '../../field/field-inline-text'
import { RadioOptionButton } from '../../radio'
import { Slide, SlideOver } from '../../slide'


interface IFormBuilderScoreStrategy {
    name: string
    scoreType: ScoreType
    accuracyCut: string
    fluencyCut: string
    missionCut: string
    assistantCut: string
    levelCut: string
    scoreCut_first: string
    scoreCut_second: string
    scoreCut_third: string
    scoreCut_fourth: string
    scoreCut_fifth: string
    subGroupIds?: string[]
}

interface BuilderScoreStrategyProps {
    open: boolean
    onClose: () => void
}

const BuilderScoreStrategy = ({ open, onClose }: BuilderScoreStrategyProps) => {
    const { subGroups } = useActiveStudyGroup()
    const { showToastInline } = useToast()
    const { mutateAsync: addScoreStrategy, isPending } = useAddScoreStrategyMutation()
    
    const scoreTypeOptions = useMemo(() => [
        { value: ScoreType.STARS, label: trans.components.builder.score_strategy.score_type_stars },
        { value: ScoreType.SATISFACTORY, label: trans.components.builder.score_strategy.score_type_satisfactory },
        { value: ScoreType.EXCELLENT, label: trans.components.builder.score_strategy.score_type_excellent }
    ], [])
    const assistantCutOptions = useMemo(() => ['0', '1', '2', '3', '4'].map(each => {
        return { label: each, value: parseInt(each) }
    }), [])
    const levelCutOptions = useMemo(() => ['1', '2', '3', '4', '5', '6'].map(each => {
        return { label: each, value: parseInt(each) }
    }), [])
    const subGroupOptions = useMemo(() => {
        return subGroups?.map(each => {
            return { value: each.id, id: each.id, label: each.name }
        })
    }, [subGroups])
    
    const formRef = useRef<FormikProps<IFormBuilderScoreStrategy> | null>(null)
    
    const handleConfirm = useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }, [])
    
    const initialValues: IFormBuilderScoreStrategy = {
        name: '',
        scoreType: ScoreType.STARS,
        accuracyCut: '0',
        fluencyCut: '0',
        missionCut: '0',
        assistantCut: '0',
        levelCut: '1',
        scoreCut_first: '0',
        scoreCut_second: '0',
        scoreCut_third: '0',
        scoreCut_fourth: '0',
        scoreCut_fifth: '0',
        subGroupIds: []
    }
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            name: Yup.string().required(trans.components.builder.score_strategy.error.name_required),
            scoreType: Yup.number(),
            accuracyCut: Yup.number().integer(trans.components.builder.score_strategy.error.accuracyCut_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.accuracyCut_integer
            }).required(trans.components.builder.score_strategy.error.accuracyCut_required),
            fluencyCut: Yup.number().integer(trans.components.builder.score_strategy.error.fluencyCut_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 200
                },
                message: trans.components.builder.score_strategy.error.fluencyCut_integer
            }).required(trans.components.builder.score_strategy.error.fluencyCut_required),
            missionCut: Yup.number().integer(trans.components.builder.score_strategy.error.missionCut_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.missionCut_integer
            }).required(trans.components.builder.score_strategy.error.missionCut_required),
            assistantCut: Yup.number(),
            levelCut: Yup.number(),
            scoreCut_first: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer,
                test: (value: any, context) => {
                    return value >= 0 && value <= 100
                }
            }).test({
                message: trans.components.builder.score_strategy.error.scoreCut_descending_error,
                test: (value: any, context) => {
                    return context.parent.scoreCut_second <= value
                }
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required),
            scoreCut_second: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer
            }).test({
                message: trans.components.builder.score_strategy.error.scoreCut_descending_error,
                test: (value: any, context) => {
                    return context.parent.scoreCut_third <= value
                }
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required),
            scoreCut_third: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer
            }).test({
                message: trans.components.builder.score_strategy.error.scoreCut_descending_error,
                test: (value: any, context) => {
                    return context.parent.scoreCut_fourth <= value
                }
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required),
            scoreCut_fourth: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer
            }).test({
                message: trans.components.builder.score_strategy.error.scoreCut_descending_error,
                test: (value: any, context) => {
                    return context.parent.scoreCut_fifth <= value
                }
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required),
            scoreCut_fifth: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormBuilderScoreStrategy) => {
        if (isPending) return
        const scoreCutArray = () => {
            switch (values.scoreType) {
                case ScoreType.STARS:
                    return [parseInt(values.scoreCut_first), parseInt(values.scoreCut_second), parseInt(values.scoreCut_third), parseInt(values.scoreCut_fourth), parseInt(values.scoreCut_fifth)]
                case ScoreType.SATISFACTORY:
                    return [parseInt(values.scoreCut_first)]
                case ScoreType.EXCELLENT:
                    return [parseInt(values.scoreCut_first), parseInt(values.scoreCut_second), parseInt(values.scoreCut_third)]
                default:
                    return []
            }
        }
        
        try {
            await addScoreStrategy({
                strategy: {
                    name: values.name,
                    scoreType: values.scoreType,
                    scoreCutArray: scoreCutArray(),
                    accuracyCut: parseInt(values.accuracyCut),
                    fluencyCut: parseInt(values.fluencyCut),
                    missionCut: parseInt(values.missionCut),
                    assistantCut: parseInt(values.assistantCut),
                    levelCut: parseInt(values.levelCut),
                    subGroupIds: values.subGroupIds,
                }
            })
            onClose()
        } catch (e: any) {
            showToastInline({ title: e.message, type: 'error' })
        }
    }, [addScoreStrategy, isPending, onClose, showToastInline])
    
    const getTotalView = useCallback(({ scoreType, errors, touched }: { scoreType: ScoreType, errors: FormikErrors<IFormBuilderScoreStrategy>, touched: FormikTouched<IFormBuilderScoreStrategy> }) => {
        switch (scoreType) {
            case ScoreType.STARS:
                return (
                    <React.Fragment>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 5점 기준</span>
                            <FieldInlineText
                                id='scoreCut_first' name='scoreCut_first'
                                error={errors.scoreCut_first && touched.scoreCut_first}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_first' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 4점 기준</span>
                            <FieldInlineText
                                id='scoreCut_second' name='scoreCut_second'
                                error={errors.scoreCut_second && touched.scoreCut_second}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_second' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 3점 기준</span>
                            <FieldInlineText
                                id='scoreCut_third' name='scoreCut_third'
                                error={errors.scoreCut_third && touched.scoreCut_third}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_third' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 2점 기준</span>
                            <FieldInlineText
                                id='scoreCut_fourth' name='scoreCut_fourth'
                                error={errors.scoreCut_fourth && touched.scoreCut_fourth}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_fourth' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 1점 기준</span>
                            <FieldInlineText
                                id='scoreCut_fifth' name='scoreCut_fifth'
                                error={errors.scoreCut_fifth && touched.scoreCut_fifth}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_fifth' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                    </React.Fragment>
                )
            case ScoreType.SATISFACTORY:
                return (
                    <React.Fragment>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>Satisfactory 기준</span>
                            <FieldInlineText
                                id='scoreCut_first' name='scoreCut_first'
                                error={errors.scoreCut_first && touched.scoreCut_first}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_first' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                    </React.Fragment>
                )
            case ScoreType.EXCELLENT:
                return (
                    <React.Fragment>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>Excellent 기준</span>
                            <FieldInlineText
                                id='scoreCut_first' name='scoreCut_first'
                                error={errors.scoreCut_first && touched.scoreCut_first}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_first' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>Very good 기준</span>
                            <FieldInlineText
                                id='scoreCut_second' name='scoreCut_second'
                                error={errors.scoreCut_second && touched.scoreCut_second}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_second' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>Good 기준</span>
                            <FieldInlineText
                                id='scoreCut_third' name='scoreCut_third'
                                error={errors.scoreCut_third && touched.scoreCut_third}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_third' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                    </React.Fragment>
                )
            default:
                return null
        }
    }, [])
    
    return (
        <SlideOver
            open={open} onClose={onClose}
            title={trans.components.builder.score_strategy.title}
            confirm={trans.actions.save}
            handleConfirm={handleConfirm}
            isLoading={isPending}
        >
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={formRef}>
                {({ errors, touched, values, setFieldValue }) => {
                    return (
                        <Slide.Content>
                            <Form>
                                <div className='space-y-6 mb-5'>
                                    <div className='sm:max-w-sm'>
                                        <label htmlFor='name' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.name}
                                        </label>
                                        <FieldText
                                            id='name' name='name'
                                            error={errors.name && touched.name}
                                            placeholder={trans.components.builder.score_strategy.name}
                                            autoFocus
                                        />
                                    </div>
                                    
                                    <div className='sm:max-w-xs'>
                                        <label htmlFor='scoreType' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.score_type}
                                        </label>
                                        <FieldRadio
                                            name='scoreType'
                                            label={trans.components.builder.score_strategy.score_type}
                                            options={scoreTypeOptions}
                                            as={RadioOptionButton}
                                            className='space-y-4'
                                            onChange={async (value: ScoreType) => {
                                                await setFieldValue('scoreType', value)
                                                await setFieldValue('scoreCut_first', '0')
                                                await setFieldValue('scoreCut_second', '0')
                                                await setFieldValue('scoreCut_third', '0')
                                                await setFieldValue('scoreCut_fourth', '0')
                                                await setFieldValue('scoreCut_fifth', '0')
                                            }}
                                        />
                                    </div>
                                    
                                    <div className='sm:max-w-sm space-y-3'>
                                        <label className='block text-base font-semibold leading-7 text-gray-900 mb-2'>{trans.components.builder.score_strategy.grading_items}</label>
                                        
                                        <label htmlFor='accuracyCut' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.accuracy}
                                        </label>
                                        <FieldNumberSchemeInlined
                                            id='accuracyCut' name='accuracyCut'
                                            scheme=''
                                            host={'%'}
                                            containerClassName='sm:max-w-xs'
                                            error={errors.accuracyCut && touched.accuracyCut}
                                            placeholder='0'
                                        />
                                        <p className='block text-sm leading-7 text-gray-900 mb-2 ml-0.5'>
                                            정확한 문장 비율 <span className='text-orange-500 font-semibold'>{values.accuracyCut}%</span> 이상, 만점 20점
                                        </p>
                                        
                                        <label htmlFor='fluencyCut' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.fluency}
                                        </label>
                                        <FieldNumberSchemeInlined
                                            id='fluencyCut' name='fluencyCut'
                                            scheme=''
                                            host={'개'}
                                            containerClassName='sm:max-w-xs'
                                            error={errors.fluencyCut && touched.fluencyCut}
                                            placeholder='0'
                                        />
                                        <p className='block text-sm leading-7 text-gray-900 mb-2 ml-0.5'>
                                            중복 제외한 분당 단어 개수 <span className='text-orange-500 font-semibold'>{values.fluencyCut}개</span> 이상, 만점 20점
                                        </p>
                                        
                                        <label htmlFor='missionCut' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.mission}
                                        </label>
                                        <FieldNumberSchemeInlined
                                            id='missionCut' name='missionCut'
                                            scheme=''
                                            host={'%'}
                                            containerClassName='sm:max-w-xs'
                                            error={errors.missionCut && touched.missionCut}
                                            placeholder='0'
                                        />
                                        <p className='block text-sm leading-7 text-gray-900 mb-2 ml-0.5'>
                                            미션 완료율 <span className='text-orange-500 font-semibold'>{values.missionCut}%</span> 이상, 만점 20점
                                        </p>
                                        
                                        <label htmlFor='assistantCut' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.assistant}
                                        </label>
                                        <FieldSelector name='assistantCut' options={assistantCutOptions} containerClassName='sm:max-w-xs'/>
                                        <p className='block text-sm leading-7 text-gray-900 mb-2 ml-0.5'>
                                            학습 도움 기능 사용 <span className='text-orange-500 font-semibold'>{values.assistantCut}개</span> 이하, 만점 20점
                                        </p>
                                        
                                        <label htmlFor='levelCut' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.level}
                                        </label>
                                        <FieldSelector name='levelCut' options={levelCutOptions} containerClassName='sm:max-w-xs'/>
                                        <p className='block text-sm leading-7 text-gray-900 mb-2 ml-0.5'>
                                            AI 레벨 <span className='text-orange-500 font-semibold'>{values.levelCut}</span> 이상, 만점 20점
                                        </p>
                                    </div>
                                    
                                    <div className='sm:max-w-sm'>
                                        <label className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.total}
                                        </label>
                                        {getTotalView({ scoreType: values.scoreType, errors, touched })}
                                    </div>
                                    
                                    <div className='sm:max-w-sm'>
                                        <label htmlFor='subGroupIds' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                            {trans.components.builder.score_strategy.group}
                                        </label>
                                        {isEmpty(subGroupOptions) ?
                                         <div className='flex flex-col items-center gap-3'>
                                             <img src='/assets/empty_state.png' className='h-20'/>
                                             <p className='text-gray-300 font-semibold text-xl'>그룹이 없습니다.</p>
                                         </div> :
                                        <FieldCheckbox name='subGroupIds' options={subGroupOptions} className='space-y-3 text-sm max-h-48 overflow-y-scroll pr-6'/>}
                                    </div>
                                </div>
                            </Form>
                        </Slide.Content>
                    )
                }}
            </Formik>
        </SlideOver>
    )
}

export default BuilderScoreStrategy