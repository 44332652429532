import { cva, VariantProps } from 'class-variance-authority'
import React, { ButtonHTMLAttributes, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import Spinner from '../spinner'
import { ButtonProps } from './index'


const ButtonSolidVariants = cva(
    `flex justify-center items-center rounded-md
    px-3.5 py-2
    text-sm font-semibold leading-6
    shadow-sm
    whitespace-nowrap
    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`,
    {
        variants: {
            variant: {
                alert: Object.values(palette.button.solid.alert),
                success: Object.values(palette.button.solid.default),
                disabled: Object.values(palette.button.solid.disabled),
                default: Object.values(palette.button.solid.default),
                gray: Object.values(palette.button.solid.gray)
            },
            size: {
                expand: 'w-full',
                default: ''
            },
            shadow: {
                md: 'shadow-md'
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default'
        }
    }
)

interface ButtonSolidProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'onClick'>, VariantProps<typeof ButtonSolidVariants>, ButtonProps {
}

const ButtonSolid = ({
    children,
    type = 'button',
    onClick, href, to, loading, navigateOptions,
    variant, size, className = '',
    ...props
}: ButtonSolidProps) => {
    const navigate = useNavigate()
    
    const shouldHandleClick = useMemo(() => {
        return !!onClick || !!href || !!to
    }, [onClick, href, to])
    
    const handleClick = useCallback(() => {
        if (loading) return
        if (onClick) {
            onClick()
        } else if (href) {
            window.open(href, '_blank', 'noopener')
        } else if (to) {
            navigate(to, navigateOptions)
        }
    }, [loading, onClick, href, to, navigate, navigateOptions])
    
    return (
        <button
            {...props}
            type={type}
            onClick={variant !== 'disabled' && shouldHandleClick ? handleClick : undefined}
            className={classesOf(ButtonSolidVariants({ variant, size }), className)}
        >
            {loading && (
                <div className='mr-2'>
                    <Spinner/>
                </div>
            )}
            {children}
        </button>
    )
}

export default ButtonSolid
