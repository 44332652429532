import { RadioGroup } from '@headlessui/react'
import React from 'react'

import { RadioOption } from './radio-option'
import RadioOptionCard from './radio-option-card'


export interface RadioProps<TValue> {
    label: string
    value: TValue
    onChange: (value: TValue) => void
    options: RadioOption<TValue>[]
    groupClassName?: string
    className?: string
    as?: React.ElementType
}

const Radio = <TValue, >({
    groupClassName = '',
    className = '',
    as: OptionComponent = RadioOptionCard,
    ...props
}: RadioProps<TValue>) => {
    return (
        <RadioGroup value={props.value} onChange={props.onChange} className={groupClassName}>
            <RadioGroup.Label className='sr-only'>{props.label}</RadioGroup.Label>
            <div className={className}>
                {props.options.map(option => {
                    return <OptionComponent key={option.label} {...option}/>
                })}
            </div>
        </RadioGroup>
    )
}

export default Radio
