import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import React, { Fragment } from 'react'

import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import Spinner from '../spinner'


interface EditDeleteMenuProps {
    onDelete: () => void
    onEdit: () => void
    isLoading: boolean
}

const EditDeleteMenu = ({ onDelete, onEdit, isLoading }: EditDeleteMenuProps) => {
    return (
        <Menu as='div' className='relative'>
            {isLoading ? <Spinner className='h-6'/> :
             <Menu.Button className={classesOf(
                 'flex items-center justify-center rounded-md',
                 'text-sm font-semibold leading-6',
                 'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
             )}>
                 <EllipsisHorizontalIcon className='h-6 cursor-pointer hover:bg-emerald-200 rounded-md'/>
             </Menu.Button>
            }
            
            <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
            >
                <Menu.Items className={classesOf(
                    'mt-2 mb-4',
                    'flex flex-col',
                    'absolute left-11 -top-6 z-40',
                    'rounded-md',
                    'bg-white',
                    'shadow-lg',
                    'ring-1 ring-gray-900/5',
                    'focus:outline-none',
                    'overflow-hidden overflow-y-auto',
                    'divide-y divide-gray-200',
                    'min-w-[100px]'
                )}>
                    <Menu.Item>
                        <div onClick={onEdit} className='text-sm leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer px-3 py-2'>
                            {trans.actions.edit}
                        </div>
                    </Menu.Item>
                    <Menu.Item>
                        <div onClick={onDelete} className='text-sm leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer px-3 py-2'>
                            {trans.actions.delete}
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default EditDeleteMenu