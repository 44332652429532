import Stepper from './stepper'
import StepperItemVertical from './stepper-item-vertical'


export interface StepperItem {
    label: string
    clickable?: boolean
    
    [key: string]: any
}

export interface StepperItemProps extends StepperItem {
    index: number
    total: number
    active: number
    onItemClick?: (index: number) => void
}

export {
    StepperItemVertical
}

export default Stepper
