import { Transition } from '@headlessui/react'
import { useAtomValue } from 'jotai'
import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import Toast from '../../components/toast'
import { toastsAtom } from '../../hooks/use-toast'


const LayoutLanding: React.FC = () => {
    const toasts = useAtomValue(toastsAtom)
    
    return (
        <React.Fragment>
            <Outlet/>
            <Transition.Root show={toasts.length > 0} as={Fragment}>
                <div className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50'>
                    <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
                        {toasts.map((toast) => <Toast key={toast.id} {...toast}/>)}
                    </div>
                </div>
            </Transition.Root>
        </React.Fragment>
    )
}

export default LayoutLanding
