import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { addScoreStrategy, deleteScoreStrategy, getScoreStrategy, updateScoreStrategy, updateScoreStrategyPriority } from '../libs/firestore/score-strategy-operation'
import { ScoreStrategy } from '../libs/model/score-strategy'
import { useActiveStudyGroup } from './use-study-group'


export const useScoreStrategyListQuery = () => {
    const { studyGroupId } = useActiveStudyGroup()
    
    return useQuery({
        queryKey: ['score-strategy', studyGroupId],
        queryFn: () => {
            return getScoreStrategy(studyGroupId)
        }
    })
}

export const useAddScoreStrategyMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ strategy }: { strategy: Partial<ScoreStrategy> }) => addScoreStrategy({ studyGroupId, strategy }),
        onSuccess: (data, variables) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['score-strategy', studyGroupId] })
        }
    })
}

export const useDeleteScoreStrategyMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ strategyIds }: { strategyIds: string[] }) => deleteScoreStrategy({ studyGroupId, strategyIds }),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['score-strategy', studyGroupId] })
        }
    })
}

export const useUpdateScoreStrategyMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ strategyId, strategy }: { strategyId: string | null, strategy: ScoreStrategy }) => updateScoreStrategy({ studyGroupId, strategyId, strategy }),
        onSuccess: (data, variables) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['score-strategy', studyGroupId] })
        }
    })
}

export const useUpdateScoreStrategyPriorityMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ priority }: { priority: string[] }) => updateScoreStrategyPriority({ studyGroupId, priority }),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['score-strategy', studyGroupId] })
        }
    })
}