export const palette = {
    primary: {
        lighter: '#818DF8',
        light: '#6366F1',
        main: '#4E46E5',
        dark: '#4438CA',
        darker: '#372FA3',
        background: 'bg-indigo-600',
        text: 'text-orange-500'
    },
    warning: {
        lighter: '#FFF5CC',
        light: '#FFD666',
        main: '#FFAB00',
        dark: '#B76E00',
        darker: '#7A4100',
        text: 'text-red-600',
        text_hover: 'hover:text-red-500'
    },
    button: {
        solid: {
            default: {
                background: 'bg-orange-500',
                hover: 'hover:bg-orange-400',
                focus: 'focus-visible:outline-orange-500',
                text: 'text-white'
            },
            gray: {
                background: 'bg-gray-200',
                hover: 'hover:bg-gray-150',
                focus: 'focus-visible:outline-gray-500',
                text: 'text-gray-900'
            },
            disabled: {
                background: 'bg-gray-300',
                hover: 'hover:bg-gray-300',
                focus: 'focus-visible:outline-orange-500',
                text: 'text-gray-500'
            },
            alert: {
                background: 'bg-red-600',
                hover: 'hover:bg-red-500',
                focus: 'focus-visible:outline-red-600',
                text: 'text-white'
            }
        },
        outline: {
            default: {
                background: 'bg-white',
                hover: 'hover:bg-gray-50',
                focus: 'focus-visible:outline-orange-500',
                ring: 'ring-gray-300',
                text: 'text-gray-900'
            },
            disabled: {
                background: 'bg-gray-300',
                hover: 'hover:bg-gray-300',
                focus: 'focus-visible:outline-orange-500',
                ring: 'ring-gray-300',
                text: 'text-gray-500'
            },
            alert: {
                background: 'bg-white',
                hover: 'hover:bg-red-50',
                focus: 'focus-visible:outline-orange-500',
                ring: 'ring-red-300',
                text: 'text-red-600'
            }
        },
        text: {
            default: {
                hover: 'hover:text-orange-400',
                text: 'text-orange-500'
            },
            disabled: {
                hover: 'hover:text-gray-400',
                text: 'text-gray-500'
            },
            gray: {
                text: 'text-gray-500',
                hover: 'hover:text-gray-900'
            },
            gray900: {
                text: 'text-gray-900',
                hover: 'hover:text-gray-700'
            },
            alert: {
                hover: 'hover:text-red-500',
                text: 'text-red-600'
            }
        }
    },
    field: {
        text: 'text-gray-900',
        text_placeholder: 'placeholder:text-gray-400',
        text_error: 'text-red-600',
        text_ring_focus: 'focus:ring-orange-500',
        text_ring_error: 'ring-red-600'
    },
    field_scheme: {
        text: 'text-gray-900',
        text_scheme: 'text-gray-500',
        text_placeholder: 'placeholder:text-gray-400',
        text_error: 'text-red-600',
        text_ring_focus: 'focus:ring-orange-600',
        text_ring_error: 'ring-red-600',
        ring_focus: 'focus-within:ring-orange-600',
        ring_error: 'ring-red-600',
        divide_focus: 'focus-within:divide-orange-600',
        divide_error: 'divide-red-600'
    },
    modal: {
        icon: {
            success_background: 'bg-green-100',
            success_stroke: 'text-green-600',
            info_background: 'bg-blue-100',
            info_stroke: 'text-blue-600',
            alert_background: 'bg-red-100',
            alert_stroke: 'text-red-600'
        }
    },
    radio: {
        option_card: {
            active_border: 'border-indigo-600',
            active_ring: 'ring-indigo-600'
        }
    },
    selector: {
        option_text: {
            active: 'bg-orange-100',
            active_text: 'text-orange-900',
            selected_text: 'text-orange-500'
        },
        trigger: {
            background: 'bg-white',
            border: 'border-gray-300'
        },
        button: {
            text: 'text-indigo-600',
            background: 'bg-white',
            border: 'border-gray-300'
        }
    },
    cta: {
        background: 'bg-indigo-100'
    },
    doc: {
        highlight: 'bg-indigo-600',
        highlight_text: 'text-orange-500'
    },
    popover: {
        project: {
            hover_border: 'hover:border-indigo-600',
            hover_text: 'hover:text-orange-500',
            input: {
                focus_ring: 'focus:ring-indigo-600'
            }
        }
    },
    stepper: {
        complete: 'bg-orange-900',
        complete_ring: 'ring-orange-900',
        complete_text: 'text-orange-900',
        active: 'bg-orange-600',
        active_text: 'text-orange-500'
    },
    badge: {
        text: {
            gray: 'text-gray-600',
            red: 'text-red-700',
            yellow: 'text-yellow-800',
            green: 'text-green-700',
            blue: 'text-blue-700',
            indigo: 'text-indigo-700',
            purple: 'text-purple-700',
            pink: 'text-pink-700'
        },
        ring: {
            gray: 'ring-gray-500/10',
            red: 'ring-red-600/10',
            yellow: 'ring-yellow-600/20',
            green: 'ring-green-600/20',
            blue: 'ring-blue-700/10',
            indigo: 'ring-indigo-700/10',
            purple: 'ring-purple-700/10',
            pink: 'ring-pink-700/10'
        },
        background: {
            gray: 'bg-gray-50',
            red: 'bg-red-50',
            yellow: 'bg-yellow-50',
            green: 'bg-green-50',
            blue: 'bg-blue-50',
            indigo: 'bg-indigo-50',
            purple: 'bg-purple-50',
            pink: 'bg-pink-50'
        }
    },
    side_bar: {
        category_text: 'text-gray-900',
        item: {
            text_light: 'text-gray-400',
            text_hover: 'hover:text-orange-500',
            text_group_hover: 'group-hover:text-orange-500',
            background_hover: 'hover:bg-gray-50',
            background_focus: 'bg-gray-50',
            icon: 'text-gray-400',
            icon_hover: 'group-hover:text-orange-500',
            svg: 'text-gray-400',
            svg_hover: 'group-hover:text-orange-500',
            svg_focused: 'text-orange-500'
        }
    },
    builder: {
        text_title: 'text-gray-900'
    },
    toggle: {
        active_background: 'bg-orange-500',
        inactive_background: 'bg-gray-200',
        border: 'border-transparent',
        focus_ring: 'focus:ring-orange-500'
    },
    checkbox: {
        option_card: {
            active_border: 'peer-checked:border-orange-500'
        },
        option_text: {
            active_ring: 'focus:ring-orange-500'
        }
    },
    tab: {
        item: {
            text_current: 'text-orange-500'
        }
    },
}
