import React, { useCallback } from 'react'

import { classesOf } from '../../libs/utils/classes-of'
import { StepperItem } from './index'
import StepperItemVertical from './stepper-item-vertical'


export interface StepperProps {
    children: React.ReactNode[]
    active: number
    steps: StepperItem[]
    onChange?: (prev: number, next: number) => void
    className?: string
    stepClassName?: string
    childrenClassName?: string
    as?: React.ElementType
}

const Stepper = ({
    children,
    active,
    steps,
    onChange,
    className = '',
    stepClassName = '',
    childrenClassName = '',
    as: StepperItemComponent = StepperItemVertical
}: StepperProps) => {
    const handleItemClick = useCallback((next: number) => {
        if (active === next) return
        onChange?.call(this, active, next)
        setTimeout(() => {
            const element = document.getElementById('active-step')
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }, 100)
    }, [active, onChange])
    
    return (
        <React.Fragment>
            <ul className={className}>
                {steps.map((step, index) => {
                    return (
                        <li key={`${step.label}#${index}`} className={classesOf(
                            'relative',
                            stepClassName
                        )}>
                            <StepperItemComponent
                                index={index}
                                total={steps.length}
                                active={active}
                                onItemClick={handleItemClick}
                                label={step.label}
                                clickable={step.clickable}
                            />
                            
                            {children && children.length > active && active === index && (
                                <div className={classesOf(childrenClassName)}>
                                    {children[active] ?? <React.Fragment/>}
                                </div>
                            )}
                        </li>
                    )
                })}
            </ul>
        </React.Fragment>
    )
}

export default Stepper
