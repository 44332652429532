import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { useSetAtom } from 'jotai'
import { Fragment, useCallback, useEffect } from 'react'

import { toastsAtom, toastsInlineAtom } from '../../hooks/use-toast'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import { IToast } from './index'


type ToastProps = IToast

const Toast = ({
    id,
    ttl,
    title,
    description,
    icon: IconComponent = CheckCircleIcon,
    iconColor = 'text-gray-900'
}: ToastProps) => {
    const setToasts = useSetAtom(toastsAtom)
    const setToastsInline = useSetAtom(toastsInlineAtom)
    
    const handleDismiss = useCallback(() => {
        setToasts((prev) => prev.filter((each) => each.id !== id))
        setToastsInline((prev) => prev.filter((each) => each.id !== id))
    }, [id, setToasts, setToastsInline])
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            handleDismiss()
        }, ttl)
        return () => clearTimeout(timeout)
    }, [handleDismiss, ttl])
    
    return (
        <Transition.Child
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-full opacity-0 sm:translate-y-0 sm:translate-x-full'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
        >
            <div className={classesOf(
                'pointer-events-auto',
                'w-full max-w-sm overflow-hidden',
                'rounded-lg',
                'bg-white',
                'shadow-lg',
                'ring-1 ring-black ring-opacity-5'
            )}>
                <div className='p-4'>
                    <div className='flex items-start'>
                        <div className='flex-shrink-0'>
                            <IconComponent className={classesOf('h-6 w-6', iconColor)} aria-hidden='true'/>
                        </div>
                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                            <p className='text-sm font-medium text-gray-900'>{title}</p>
                            <p className='mt-1 text-sm text-gray-500'>{description}</p>
                        </div>
                        <div className='ml-4 flex flex-shrink-0'>
                            <button
                                type='button'
                                className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                onClick={handleDismiss}
                            >
                                <span className='sr-only'>{trans.components.toast.close}</span>
                                <XMarkIcon className='h-5 w-5' aria-hidden='true'/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition.Child>
    )
}

export default Toast
