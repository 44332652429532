import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'

import { trans } from '../../../locales/ko'



export interface SlideOverHeaderProps {
    title: string
    description?: string
    onClose: () => void
}

const SlideOverHeader = ({ title, description, onClose }: SlideOverHeaderProps) => {
    return (
        <div className='flex-shrink-0 bg-gray-50 px-4 py-6 sm:px-6'>
            <div className='flex items-start justify-between space-x-3'>
                <div className='space-y-1'>
                    <Dialog.Title className='text-xl font-semibold leading-6 text-gray-900'>
                        {title}
                    </Dialog.Title>
                    {description && <p className='text-sm text-gray-500'>{description}</p>}
                </div>
                <div className='flex h-7 items-center'>
                    <button
                        type='button'
                        className='relative text-gray-400 hover:text-gray-500'
                        onClick={onClose}
                    >
                        <span className='absolute -inset-2.5'/>
                        <span className='sr-only'>{trans.actions.close}</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true'/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SlideOverHeader
