import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useState } from 'react'

import { createOrUpdateAdminComment, getChatContentsOfLearningHistory } from '../libs/firestore/firestore-helper'
import { ChatContent } from '../libs/model/chat-content'
import { LearningHistoryData } from '../libs/model/learning-history'


export const useChatContent = (learningId: string | undefined | null): ChatContent[] => {
    const [chatContents, setChatContents] = useState<ChatContent[]>([])
    const [chatContentsCache, setChatContentsCache] = useState<Record<string, ChatContent[]>>({})
    
    const initialize = useCallback(async () => {
        if (!learningId) {
            setChatContents([])
            return
        }
        if (chatContentsCache[learningId]) {
            setChatContents(chatContentsCache[learningId])
        } else {
            setChatContents([])
            const contents = await getChatContentsOfLearningHistory(learningId)
            if (contents) {
                setChatContents(contents)
                chatContentsCache[learningId] = contents
                setChatContentsCache(chatContentsCache)
            } else {
                setChatContents([])
            }
        }
    }, [chatContentsCache, learningId])
    
    useEffect(() => {
        initialize()
    }, [initialize, learningId])
    
    return chatContents
}

export const useChatContentsListQuery = (learningId?: string) => {
    return useQuery({
        queryKey: ['chat-contents', learningId],
        queryFn: () => {
            return getChatContentsOfLearningHistory(learningId)
        },
        enabled: !isEmpty(learningId)
    })
}

export const useCreateOrUpdateAdminCommentMutation = () => {
    const queryClient = useQueryClient()
    
    return useMutation({
        mutationFn: ({ learningSession, comment }: { learningSession: LearningHistoryData, comment: string }) => createOrUpdateAdminComment({ learningId: learningSession.id, comment }),
        onSuccess: (data, variables, context) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['learning-history', variables.learningSession.userId, variables.learningSession.date?.substring(0, 7)] })
        }
    })
}