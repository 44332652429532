export enum ScoreType {
    STARS, SATISFACTORY, EXCELLENT
}

export interface ScoreStrategy {
    id: string // 디폴트: 'default', 나머지는 랜덤생성
    name: string
    scoreType: ScoreType
    scoreCutArray: number[]
    
    accuracyCut: number
    fluencyCut: number
    missionCut: number
    assistantCut: number
    levelCut: number
    
    subGroupIds: string[] // 디폴트인경우에는 필요가 없음, 그 외에는 subGroupId의 리스트를 저장
    priority?: string[] // score strategy id 배열
}

