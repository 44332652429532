import { UsersInput } from '../model/member'
import { StudyGroup } from '../model/study-group'
import { instance } from './instance'


export const createSubGroup = async ({ studyGroupId, subGroupName, memberIds }: { studyGroupId: string, subGroupName: string, memberIds?: string[] }): Promise<{ studyGroup: StudyGroup, users: any[] } | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-createSubGroup`,
        data: {
            studyGroupId, subGroupName, memberIds
        }
    })
    return resp.data.result
}

export const editUsersOfGroup = async ({ studyGroupId, bulkData, joinedOnly }: UsersInput) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-editUsersOfGroup`,
        data: {
            studyGroupId, bulkData, joinedOnly
        }
    })
    return resp.data.result
}