import { Field, useField } from 'formik'
import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


interface FieldInlineTextProps {
    id?: string
    name?: string
    type?: string
    autoComplete?: string
    placeholder?: string
    error?: any
    autoFocus?: boolean
    className?: string
    containerClassName?: string
    
    [key: string]: any
}

const FieldInlineText = ({
    error,
    className = '',
    containerClassName = '',
    autoComplete = 'off',
    autoFocus = false,
    ...props
}: FieldInlineTextProps) => {
    const nameReduced = useMemo(() => {
        return props.id || props.name || props.type || ''
    }, [props])
    
    const fieldEl = useRef<HTMLInputElement>(null)
    
    useEffect(() => {
        if (autoFocus && fieldEl.current) {
            fieldEl.current.focus()
        }
    }, [autoFocus])
    
    const [field, meta, helper] = useField<string>(nameReduced)
    const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const trimmedValue = e.target.value.trim()
        if (trimmedValue !== field.value) {
            helper.setValue(trimmedValue)
        }
    }
    
    return (
        <div className={classesOf('relative flex-col mx-2', containerClassName)}>
            <Field {...props} className={classesOf(
                'block relative w-full rounded-md border border-gray-300 py-1 px-2',
                palette.field.text, palette.field.text_placeholder,
                'shadow-sm',
                'focus:ring-2 focus:ring-inset focus:border-white', palette.field.text_ring_focus,
                'text-sm leading-6',
                error ? 'ring-1 ring-inset rin' : '',
                error ? palette.field.text_ring_error : '',
                className
            )} innerRef={fieldEl}
                   autoComplete={autoComplete}
                   onBlur={handleBlur}
            />
        </div>
    )
}

export default FieldInlineText
