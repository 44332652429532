export const trans = {
    meta: {
        adrop: 'ChitChat'
    },
    views: {
        auth: {
            sign_in: {
                it: 'Sign in',
                title: '칫챗 대시보드에서\n학습 데이터를 확인해 보세요.',
                label_email: '이메일 주소',
                label_password: '비밀번호',
                label_forgot_password: 'Forgot password?',
                separate_text: 'Or continue with',
                google_sign_in: '구글 로그인',
                apple_sign_in: '애플 로그인',
                kakao_sign_in: '카톡 로그인',
                email_sign_in: '이메일 로그인',
                social_sign_in: '소셜 계정으로 로그인',
                email_inquiry: '이메일 문의',
                error: {
                    email_required: '이메일을 입력하세요',
                    email_format: '이메일 주소가 올바르지 않습니다.',
                    password_required: '비밀번호를 입력하세요',
                    password_error_08: '비밀번호는 최소 8자 이상이어야 합니다',
                    password_error_16: '비밀번호는 16자를 초과할 수 없습니다',
                    fail: '로그인에 실패했습니다'
                }
            }
        },
        dashboard: {
            summary: {
                it: '개요',
                remaining_invitations: '남은 초대',
                invitation_link: '초대 링크'
            },
            group: {
                it: '그룹',
                delete: '그룹 삭제',
                name: '그룹 이름',
                learner: '학습자',
                member_edit: '멤버 편집',
                button_member_edit: '[편집]',
                details: '상세 보기',
                button_details: '[보기]',
                all_user: '전체',
                delete_modal: {
                    title: '그룹 삭제 확인',
                    description: '그룹을 삭제하시겠습니까?\n삭제된 그룹은 다시 복구할 수 없으며, 모든 관련 데이터도 함께 제거됩니다.'
                },
                error: {
                    empty_selected_member: '삭제할 그룹 선택해주세요.'
                }
            },
            sub_group_detail: {
                it: '그룹',
                info: '그룹 정보',
                name: '그룹 이름',
                learner: '학습자',
                learning_setting: {
                    it: '학습 설정',
                    hintAllowed: '힌트 보기',
                    hintPronunciationAllowed: '힌트 발음 보기',
                    voiceReplayAllowed: '다시 듣기',
                    translateAllowed: '번역 보기',
                    keyboardAllowed: '키보드 입력',
                    ttsSpeed: 'AI 음성 속도',
                    ttsMute: 'AI 음성 음소거',
                    ttsLocale: 'AI 억양',
                    usa: '미국',
                    uk: '영국',
                    australia: '호주',
                    india: '인도',
                    conversation_level: '대화 난이도',
                    level: '레벨',
                    learningTime: '대화 시간',
                    minute: '분',
                    update_success: '학습 설정이 변경되었습니다.'
                },
                learning_statistics: {},
                error: {
                    name_required: '이름을 입력해주세요.',
                    name_duplicate: '이 그룹 이름은 이미 사용 중입니다.',
                    empty_member: '멤버가 없습니다.'
                }
            },
            member: {
                it: '멤버',
                users_per_page: '페이지당',
                delete: '멤버 삭제',
                delete_all: '멤버 모두 삭제',
                add: '멤버 추가',
                name: '이름',
                email: '이메일 주소',
                status: '상태',
                group: '현재 그룹',
                details: '상세 보기',
                button_details: '[보기]',
                none: '없음',
                invited: '초대 수락 전',
                joined: '사용중',
                rejected: '거절됨',
                delete_modal: {
                    title: '멤버 삭제 확인',
                    description: '멤버를 삭제하시겠습니까?\n삭제된 멤버는 다시 복구할 수 없으며, 모든 관련 데이터도 함께 제거됩니다.'
                },
                delete_all_modal: {
                    title: '모든 멤버 삭제 확인',
                    description: '모든 멤버를 삭제하시겠습니까?\n삭제된 멤버는 다시 복구할 수 없으며, 모든 관련 데이터도 함께 제거됩니다.'
                },
                error: {
                    empty_selected_member: '삭제할 멤버를 선택해주세요.'
                }
            },
            member_details: {
                it: '멤버',
                profile: '프로필',
                name: '이름',
                email: '이메일',
                status: '상태',
                group: {
                    it: '현재 그룹',
                    edit: '+ 그룹 편집',
                    edit_title: '그룹 편집'
                },
                learning_setting: {
                    it: '학습 설정',
                    autoHintRecommend: '힌트 자동 추천',
                    hintPronunciation: '힌트 발음 보기',
                    ttsSpeed: 'AI 음성 속도',
                    ttsMute: 'AI 음성 음소거',
                    ttsLocale: 'AI 억양',
                    usa: '미국',
                    uk: '영국',
                    australia: '호주',
                    india: '인도',
                    conversation_level: '대화 난이도',
                    level: '레벨',
                    learningTime: '대화 시간',
                    minute: '분'
                },
                learning_statistics: {
                    it: '학습 통계',
                    statistics_by_period: '기간별 통계',
                    training_specific_statistics: '학습별 통계',
                    month_study_time: '이번 달 학습 시간',
                    month_study_time_minute: '이번 달 학습 시간 (분)',
                    month_study_count: '이번 달 학습 횟수',
                    average_conversation_time: '평균 대화 시간',
                    average_score: '평균 점수',
                    comprehensive_evaluation: 'AI 종합 평가',
                    date: '일자',
                    average: '평균',
                    accuracyPoint: '정확성',
                    fluencyPoint: '유창성',
                    missionPoint: '미션 수행',
                    assistantPoint: '자기 주도',
                    levelPoint: '난이도',
                    joined: '가입 여부',
                    failed: '다운로드에 실패했습니다. 지속될경우 고객센터로 문의해주세요.',
                    learning_count: '학습 횟수',
                    memo_count: '성찰 횟수',
                    score: '합계',
                    subject: '주제',
                    reflection: '성찰',
                    conversation_time: '대화 시간',
                    sentence_word: '문장/단어',
                    grammar_error: '문법 오류',
                    conversation_history: '대화 기록 보기',
                    conversation_history_button: '[보기]',
                    empty: '👻 학습 데이터가 없습니다.'
                },
                thread: {
                    title: '대화 기록',
                    ph: '코멘트를 추가하세요...',
                    action: '코멘트',
                    manager: '관리자'
                },
                error: {
                    real_name_required: '이름을 입력해주세요.'
                }
            },
            setting: {
                it: '설정',
                score_strategy: {
                    it: '채점 기준',
                    delete_button: '선택한 기준 삭제',
                    name: '기준 이름',
                    score_type: '채점 결과 표시',
                    details: '상세 보기',
                    button_details: '[보기]',
                    score_type_stars: '별점 5점',
                    score_type_satisfactory: 'Satisfactory/Unsatisfactory',
                    score_type_excellent: 'Excellent~Not bad',
                    delete_modal: {
                        title: '채점기준 삭제 확인',
                        description: '선택하신 채점기준을 삭제하시겠습니까? 삭제된 채점 기준은 복구할 수 없습니다.'
                    },
                    success: {
                        priority_update: '채점기준 우선순위 변경이 완료되었습니다.'
                    },
                    error: {
                        empty_selected_strategy: '삭제할 채점 기준을 선택해주세요.',
                        priority_update: '채점기준 우선순위 변경에 실패하였습니다.'
                    }
                },
                learning_option: {
                    it: '기본 학습 설정',
                    description: '기본 학습 설정은 따로 설정을 바꾸지 않았을 때 적용되는 공통 설정입니다.\n그룹마다 설정을 다르게 바꿀 수 있습니다.'
                },
                apply_option: {
                    it: '학습 환경 설정',
                    update_success: '학습 환경 설정이 변경되었습니다.',
                    learning_option: '기본 학습 설정 적용',
                    score_strategy: '채점 기준 적용',
                    group_contents: '그룹 컨텐츠 사용',
                    description: '그룹 참여자가 그룹에서 정한 설정을 적용하려면 각 옵션을 활성화하세요.\n비활성화할 경우, 개별적인 학습 선호도에 맞춰 설정을 조정할 수 있습니다.'
                }
            },
            setting_detail: {
                it: '채점기준',
                edit: '채점 기준 편집',
                score_type: '채점 결과 표시',
                score_type_method: '표시 방식',
                grading_items: '채점 항목',
                accuracy: '정확성',
                accuracy_description: '정확한 문장 비율 {{accuracyCut}}% 이상, 만점 20점',
                fluency: '유창성',
                mission: '미션 수행',
                assistant: '자기 주도',
                level: '난이도',
                group: '적용 중인 그룹',
                group_edit: '+ 그룹 편집',
                total: '합산 점수와 채점 결과 표시',
                total_method: '합산 점수',
                error: {
                    name_required: '기준 이름을 입력해주세요.',
                    accuracyCut_integer: '0에서 100 사이의 정수를 입력해주세요.',
                    accuracyCut_required: '정확한 문장 비율을 입력해주세요.',
                    fluencyCut_integer: '0에서 200 사이의 정수를 입력해주세요. 200을 넘으면 달성하기 어려울 수 있어요.',
                    fluencyCut_required: '중복 제외한 분당 단어 개수를 입력해주세요.',
                    missionCut_integer: '0에서 100 사이의 정수를 입력해주세요.',
                    missionCut_required: '미션 완료율을 입력해주세요.',
                    scoreCutArray_integer: '0에서 100 사이의 정수를 입력해주세요.',
                    scoreCutArray_required: '점수를 입력해주세요.',
                    scoreCut_descending_error: '점수는 내림차순으로 입력되어야 합니다.'
                }
            },
            content: {
                it: '컨텐츠',
                button_delete: '컨텐츠 삭제',
                name: '컨텐츠 이름',
                mission: '미션',
                category: '구분',
                detail: '상세 보기',
                error: {
                    empty_selected_content: '삭제할 컨텐츠를 선택해주세요.'
                }
            },
            content_detail: {
                it: '컨텐츠',
                group: '적용 중인 그룹',
                group_edit: '+ 그룹 편집',
                content_info: '컨텐츠 정보',
                name: '컨텐츠 이름',
                category: '구분',
                mission: '미션',
                expression: '사용해야 하는 단어 또는 표현',
                expression_ph: '단어 또는 표현',
                meaning: '뜻 (영어로 작성 필요)',
                error: {
                    name_required: '컨텐츠 이름을 입력해주세요.',
                    category_name_required: '구분 이름을 입력해주세요.'
                }
            }
        }
    },
    components: {
        builder: {
            member: {
                title: '엑셀로 멤버를 일괄 생성하세요.',
                instruction: '엑셀에서 이름, 이메일, 그룹 순으로 계정목록을 만든 후, 아래에 복사/붙여넣기를 해주세요.',
                placeholder: 'Control-V로 붙여넣으세요.',
                error: {
                    bulk_data_required: '계정목록을 붙여넣기 해주세요.',
                    bulk_data_missing_field: '잘못된 형식의 데이터가 포함되어 있습니다. 이름, 이메일, 그룹 정보가 모두 포함되어 있는지 확인해주세요.',
                    bulk_data_email_duplicate: '중복된 이메일 주소가 존재합니다:'
                }
            },
            sub_group: {
                title: '그룹 추가',
                name: '그룹 이름',
                error: {
                    sub_group_name_required: '그룹 이름을 입력해주세요.',
                    sub_group_name_duplicate: '중복된 그룹 이름이 존재합니다.'
                }
            },
            sub_group_member: {
                title: '멤버 편집',
                instruction: '엑셀에서 이메일 계정목록을 만든 후, 아래에 복사/붙여넣기를 해주세요.',
                placeholder: 'Control-V로 붙여넣으세요.',
                error: {
                    bulk_data_required: '계정목록을 붙여넣기 해주세요.',
                    bulk_data_missing_field: '잘못된 형식의 데이터가 포함되어 있습니다. 다시 한번 데이터를 확인해주세요.',
                    bulk_data_email_duplicate: '중복된 이메일 주소가 존재합니다:',
                    bulk_data_email_invalid: '유효하지 않은 형식의 이메일이 포함되어 있습니다.'
                }
            },
            score_strategy: {
                title: '채점 기준 추가',
                name: '기준 이름',
                score_type: '채점 결과 표시',
                score_type_stars: '별점 5점',
                score_type_satisfactory: 'Satisfactory/Unsatisfactory',
                score_type_excellent: 'Excellent~Not bad',
                grading_items: '채점 항목',
                accuracy: '정확성',
                accuracy_description: '정확한 문장 비율 {{accuracyCut}}% 이상, 만점 20점',
                fluency: '유창성',
                mission: '미션 수행',
                assistant: '자기 주도',
                level: '난이도',
                total: '총 합계',
                group: '적용할 그룹',
                error: {
                    name_required: '기준 이름을 입력해주세요.',
                    accuracyCut_integer: '0에서 100 사이의 정수를 입력해주세요.',
                    accuracyCut_required: '정확한 문장 비율을 입력해주세요.',
                    fluencyCut_integer: '0에서 200 사이의 정수를 입력해주세요.\n200을 넘으면 달성하기 어려울 수 있어요.',
                    fluencyCut_required: '중복 제외한 분당 단어 개수를 입력해주세요.',
                    missionCut_integer: '0에서 100 사이의 정수를 입력해주세요.',
                    missionCut_required: '미션 완료율을 입력해주세요.',
                    scoreCutArray_integer: '0에서 100 사이의 정수를 입력해주세요.',
                    scoreCutArray_required: '점수를 입력해주세요.',
                    scoreCut_descending_error: '점수는 내림차순으로 입력되어야 합니다.'
                }
            },
            score_strategy_updater: {
                title: '채점 기준 편집'
            },
            content: {
                title: '컨텐츠 추가',
                name: '컨텐츠 이름',
                sub_group: '적용할 그룹',
                category_name: '구분',
                content_info: '컨텐츠 정보',
                mission_info: '미션 정의',
                mission: '미션',
                expression: '사용해야 하는 단어 또는 표현',
                expression_ph: '단어 또는 표현',
                meaning: '뜻',
                meaning_ph: '뜻 (영어로 작성 필요)',
                add_mission: '+ 미션 추가',
                create_role_play: '롤 플레이 만들기',
                role_play_example: '등장 인물 정보와 대화 예시',
                recreate: '다시 만들기',
                ai: 'AI',
                learner: '학습자',
                add_button: '컨텐츠 추가하기',
                error: {
                    name_duplicate: '이 컨텐츠 이름은 이미 사용 중입니다.',
                    expression_required: '단어 또는 표현을 입력해주세요.',
                    meaning_required: '뜻을 입력해주세요.',
                    english_only: '영어로 작성해주세요.'
                }
            }
        },
        modal: {
            confirm: '확인',
            cancel: '취소',
            confirmation_guide: '확인을 위해 다음을 입력하세요:',
            delete_app: {
                title: 'Delete App from project',
                description: 'Are you sure you want to delete your app? All of your data will be permanently removed from our servers forever.',
                delete: 'Delete'
            },
            archive_ad_unit: {
                title: 'Archive Ad unit from project',
                description: 'Archived Ad unit is no longer eligible for monetization.\nPlease review the ad unit configuration of the deployed client\nonce again.'
            },
            delete_member: {
                title: 'Delete Member from project',
                description: 'Are you sure you want to delete member?'
            },
            delete_content: {
                title: '컨텐츠 삭제 확인',
                description: '컨텐츠를 삭제하시겠습니까?\n삭제된 컨텐츠는 다시 복구할 수 없으며, 모든 관련 데이터도 함께 제거됩니다.'
            }
        },
        sidebar: {
            items: {
                admin: {
                    overview: '개요',
                    group: '그룹',
                    member: '멤버',
                    learner: '학습자',
                    manager: '관리자',
                    contents: '컨텐츠',
                    settings: '설정'
                },
                member: {}
            }
        },
        toast: {
            close: 'Close'
        },
        popover: {
            account: {
                items: {
                    profile: 'Profile',
                    group: 'Group',
                    logout: 'Logout'
                }
            }
        },
        header: {
            landing: {
                go_to_console: 'Go to console',
                sign_up: 'Register',
                sign_in: 'Sign in'
            }
        }
    },
    keywords: {
        just_before: '방금 전',
        learner: '학습자',
        manager: '관리자',
        group: '그룹'
    },
    actions: {
        delete: '삭제',
        update: '수정',
        cancel: '취소',
        leave: '나가기',
        save: '저장',
        create: '생성',
        add: '추가',
        edit: '편집',
        archive: '삭제',
        unarchive: '삭제 되돌리기',
        turn_on: '켜기',
        details: '자세히 보기',
        next: '다음',
        prev: '이전',
        back: '뒤로',
        submit: '제출',
        publish: '게시',
        pause: '일시 중지',
        resume: '재개',
        reject: '거절',
        approve: '수락',
        close: '닫기',
        confirm: '확인',
        export: '내보내기'
    },
    errors: {
        basic: '서버에 문제가 발생했습니다. 잠시 후 다시 시도해 주세요.',
        not_member: '접근 권한이 없습니다. 이 페이지는 스터디 그룹 멤버 전용입니다.'
    }
}
