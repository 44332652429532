class AdropLogger {
    static log(message?: any, ...optionalParams: any[]) {
        let caller = 'INVALID_CALLER'
        try {
            caller = new Error().stack?.split('\n')[2].trim().split(' ')[1] ?? ''
        } catch {
        
        } finally {
            console.log(`[${caller}]`, message, ...optionalParams)
        }
    }
    
    static error(message?: any, ...optionalParams: any[]) {
        let caller = 'INVALID_CALLER'
        try {
            caller = new Error().stack?.split('\n')[2].trim().split(' ')[1] ?? ''
        } catch {
        
        } finally {
            console.error(`[${caller}]`, message, ...optionalParams)
        }
    }
    
    static warn(message?: any, ...optionalParams: any[]) {
        let caller = 'INVALID_CALLER'
        try {
            caller = new Error().stack?.split('\n')[2].trim().split(' ')[1] ?? ''
        } catch {
        
        } finally {
            console.warn(`[${caller}]`, message, ...optionalParams)
        }
    }
}

export default AdropLogger

