import { CheckCircleIcon, ExclamationTriangleIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline'
import { atom, useSetAtom } from 'jotai'
import { nanoid } from 'nanoid'
import { useCallback } from 'react'

import { IToast } from '../components/toast'
import { trans } from '../locales/ko'


export interface ToastParams extends Omit<IToast, 'id'> {
    type: 'success' | 'info' | 'error'
}

export const toastsAtom = atom<IToast[]>([])
export const toastsInlineAtom = atom<IToast[]>([])

export const useToast = () => {
    const setToasts = useSetAtom(toastsAtom)
    const setToastsInline = useSetAtom(toastsInlineAtom)
    
    const buildToast = useCallback(({ title, description, type, icon, iconColor, ttl = 5000 }: ToastParams): IToast => {
        switch (type) {
            case 'success':
                icon = CheckCircleIcon
                iconColor = 'text-green-600'
                break
            case 'info':
                icon = SpeakerWaveIcon
                iconColor = 'text-gray-900'
                break
            case 'error':
                icon = ExclamationTriangleIcon
                iconColor = 'text-red-600'
                title = title || trans.errors.basic
                break
        }
        return { id: nanoid(), title, ttl, description, icon, iconColor }
    }, [])
    
    const showToast = useCallback((params: ToastParams) => {
        const toast = buildToast(params)
        setToasts((prev) => prev.length < 5 ? [toast, ...prev] : [toast, ...prev.slice(0, 4)])
    }, [buildToast, setToasts])
    
    const showToastInline = useCallback((params: ToastParams) => {
        const toast = buildToast(params)
        setToastsInline((prev) => prev.length < 5 ? [toast, ...prev] : [toast, ...prev.slice(0, 4)])
    }, [buildToast, setToastsInline])
    
    return { showToast, showToastInline }
}
