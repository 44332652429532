import isEmpty from 'lodash/isEmpty'
import React, { createContext, useMemo } from 'react'

import { useStudyGroupQuery } from '../../hooks/use-group'
import useSession from '../../hooks/use-session'
import { StudyGroup, SubGroup } from '../../libs/model/study-group'


interface ContextProps {
    studyGroup: StudyGroup | null
    studyGroupId: string
    subGroups: SubGroup[] | []
    isLoading: boolean
    isError: boolean
}

export const Context = createContext<ContextProps>({
    studyGroup: null,
    studyGroupId: '',
    subGroups: [],
    isLoading: false,
    isError: false
})

const StudyGroupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { activeUser } = useSession()
    
    const activeId = useMemo(() => activeUser?.studyGroupId ?? '', [activeUser?.studyGroupId])
    const studyGroupQuery = useStudyGroupQuery(activeId)
    const subGroups = useMemo(() => {
        if (isEmpty(studyGroupQuery.data)) return []
        return Object.values(studyGroupQuery.data.subGroups).map(subGroup => subGroup) ?? []
    }, [studyGroupQuery.data])
    
    return (
        <Context.Provider value={{
            studyGroup: studyGroupQuery.data ?? null,
            studyGroupId: activeId,
            subGroups: subGroups,
            isLoading: studyGroupQuery.isLoading,
            isError: studyGroupQuery.isError
        }}>{children}</Context.Provider>
    )
}

export default StudyGroupProvider
