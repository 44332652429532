import { atom } from 'jotai'
import { atomWithReducer } from 'jotai/utils'


export const redirectionAtom = atom<string | undefined>(undefined)

export const appCreatedAtom = atom<string>('')
export const adUnitCreatedAtom = atom<string>('')

export const unauthorizedAtom = atomWithCompare<boolean>(false)

export function atomWithCompare<Value>(
    initialValue: Value,
    areEqual: (prev: Value, next: Value) => boolean = (prev, next) => prev === next
) {
    return atomWithReducer(initialValue, (prev: Value, next: Value) => {
        if (areEqual(prev, next)) {
            return prev
        }
        return next
    })
}
