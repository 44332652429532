import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useState } from 'react'

import { BuilderContent } from '../../../components/builder/content'
import { ButtonOutline, ButtonSolid } from '../../../components/button'
import { DestructiveModal } from '../../../components/modal'
import TableContent from '../../../components/table/table-content'
import { useDeleteCustomPromptMutation } from '../../../hooks/use-content'
import { useToast } from '../../../hooks/use-toast'
import { CustomPrompt } from '../../../libs/model/content'
import { trans } from '../../../locales/ko'


const ViewContent = () => {
    const [openContentBuilder, setOpenContentBuilder] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedContent, setSelectedContent] = useState<CustomPrompt[]>([])
    
    const { showToast } = useToast()
    
    const { mutateAsync: deleteCustomPrompt, isPending } = useDeleteCustomPromptMutation()
    
    const handleDeleteContent = useCallback(async () => {
        if (isPending) return
        
        const promptIds = selectedContent.map(each => each.id)
        try {
            await deleteCustomPrompt(promptIds)
            setOpenDeleteModal(false)
            setSelectedContent([])
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [deleteCustomPrompt, isPending, selectedContent, showToast])
    
    return (
        <React.Fragment>
            <div className='px-4 sm:px-6 lg:px-8'>
                <div className='sm:flex sm:items-center'>
                    <div className='sm:flex-auto'>
                        <h1 className='text-2xl font-semibold leading-6 text-gray-900'>{trans.views.dashboard.content.it}</h1>
                    </div>
                    <div className='mt-4 sm:ml-16 sm:mt-0 flex items-center gap-3'>
                        <ButtonSolid onClick={() => setOpenContentBuilder(true)}>
                            {trans.actions.add}
                        </ButtonSolid>
                        <ButtonOutline variant='alert' onClick={() => {
                            if (isEmpty(selectedContent)) return showToast({ title: trans.views.dashboard.content.error.empty_selected_content, type: 'error' })
                            setOpenDeleteModal(true)
                        }}>
                            {isEmpty(selectedContent) ? `${trans.views.dashboard.content.button_delete}` :
                             `${selectedContent.length}개 ${trans.views.dashboard.content.button_delete}`}
                        </ButtonOutline>
                    </div>
                </div>
                <TableContent selectedContent={selectedContent} setSelectedContent={setSelectedContent}/>
            </div>
            
            
            <BuilderContent open={openContentBuilder} onClose={() => setOpenContentBuilder(false)}/>
            <DestructiveModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onConfirm={handleDeleteContent}
                title={trans.components.modal.delete_content.title}
                description={trans.components.modal.delete_content.description}
                confirmCode={trans.actions.delete}
                loading={isPending}
            />
        </React.Fragment>
    )
}

export default ViewContent