import React, { SVGProps } from 'react'
import { ulid } from 'ulid'


const LogoChitChatText = ({ ref, ...props }: SVGProps<SVGElement>) => {
    const uniqueId = ulid()
    
    return (
        <svg width="118" height="40" viewBox="0 0 118 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9.41873 37.2073H7.23314L6.53159 35.2915H3.38812L2.68657 37.2073H0.500977L4.14363 27.8173H5.77608L9.41873 37.2073ZM5.88401 33.5377L5.53324 32.4583L4.98009 30.5965H4.92613L4.38647 32.4314L4.0357 33.5377H5.88401Z" fill="#F05C35"/>
            <path d="M12.439 37.2073H10.3614V27.8173H12.439V37.2073Z" fill="#F05C35"/>
            <path d="M24.59 37.2073H17.9522V27.8173H24.59V29.6791H20.0299V31.5814H23.9154V33.3758H20.0299V35.3455H24.59V37.2073Z" fill="#F05C35"/>
            <path d="M31.4527 37.2073H29.5369V33.2678C29.5369 32.5933 29.1321 32.2425 28.5925 32.2425C28.0259 32.2425 27.5806 32.5933 27.5806 33.2678V37.2073H25.6514V30.5291H27.5806V31.0822H27.6076C28.0124 30.7045 28.7274 30.4616 29.2266 30.4616C30.6702 30.4616 31.4527 31.2981 31.4527 32.8631V37.2073Z" fill="#F05C35"/>
            <path d="M38.6097 30.5291V37.0319C38.6097 39.1635 37.0987 40 35.4797 40C34.6703 40 33.7933 39.8246 32.9973 39.1365L33.9417 37.8009C34.4814 38.1517 35.1155 38.2731 35.4663 38.2731C36.1273 38.2731 36.694 37.8953 36.694 37.1938V36.6676L36.667 36.6541C36.3567 37.0319 35.7226 37.2882 35.2774 37.2882C33.0513 37.2882 32.7545 35.3995 32.7545 34.0233C32.7545 32.5933 33.0108 30.4751 35.2639 30.4751C35.7631 30.4751 36.2488 30.5831 36.6535 31.0148L36.6805 31.0013V30.5291H38.6097ZM36.6805 33.8884C36.6805 33.2408 36.6265 32.256 35.7226 32.256C34.7782 32.256 34.7242 33.2004 34.7242 33.8884C34.7242 34.6035 34.7917 35.5074 35.7226 35.5074C36.613 35.5074 36.6805 34.563 36.6805 33.8884Z" fill="#F05C35"/>
            <path d="M42.9123 37.2073H42.2243C41.1315 37.2073 40.1601 36.816 40.1601 35.2241V27.4126H42.0894V34.8598C42.0894 35.4534 42.3457 35.5344 42.6965 35.5344H42.9123V37.2073Z" fill="#F05C35"/>
            <path d="M45.9323 29.5038H44.0031V27.8173H45.9323V29.5038ZM45.9323 37.2073H44.0031V30.5291H45.9323V37.2073Z" fill="#F05C35"/>
            <path d="M52.7221 35.1161C52.7221 36.5462 51.346 37.2882 49.9024 37.2882C48.9041 37.2882 47.9327 37.1533 46.9073 36.2494L47.8517 34.9003C48.5128 35.5344 49.2548 35.6693 49.9834 35.6693C50.4691 35.6693 50.8333 35.4399 50.8333 35.1701C50.8333 34.9273 50.7119 34.6709 49.9969 34.59C48.6612 34.4416 47.3121 34.1852 47.3121 32.6202C47.3121 31.1632 48.4723 30.4616 49.8485 30.4616C50.8603 30.4616 51.7372 30.6235 52.5737 31.2441L51.7103 32.6742C50.9952 32.1885 50.2667 32.1211 49.8889 32.1211C49.3088 32.1211 49.1064 32.3639 49.1064 32.5528C49.1064 32.8631 49.4437 32.998 50.4556 33.1194C51.6833 33.2678 52.7221 33.6591 52.7221 35.1161Z" fill="#F05C35"/>
            <path d="M59.5823 37.2073H57.6665V33.2678C57.6665 32.5933 57.2618 32.2425 56.7221 32.2425C56.1555 32.2425 55.7103 32.5933 55.7103 33.2678V37.2073H53.781V27.4126H55.7103V31.0013L55.7642 31.0148C56.1285 30.7045 56.8165 30.4616 57.3157 30.4616C58.5704 30.4616 59.5823 31.2981 59.5823 32.8631V37.2073Z" fill="#F05C35"/>
            <path d="M71.3532 28.6133L70.3953 30.2728C69.5049 29.7061 68.5875 29.6387 68.0883 29.6387C67.063 29.6387 66.8471 30.1378 66.8471 30.5156C66.8471 31.1092 67.2653 31.2711 68.8168 31.514C70.3684 31.7568 71.7175 32.4179 71.7175 34.3202C71.7175 36.2764 69.8422 37.2882 68.0074 37.2882C66.7392 37.2882 65.417 37.0859 64.1758 35.9931L65.3766 34.4011C66.2535 35.1836 67.1574 35.386 68.1153 35.386C69.0192 35.386 69.5319 34.9542 69.5319 34.4551C69.5319 33.9964 69.3835 33.5377 68.2502 33.4297C66.4694 33.2543 64.7829 32.7282 64.7829 30.6235C64.7829 28.6268 66.4963 27.7364 68.0343 27.7364C69.1811 27.7364 70.3549 27.9522 71.3532 28.6133Z" fill="#F05C35"/>
            <path d="M72.8705 30.5291H74.7863V31.0013L74.8267 31.0148C75.2045 30.61 75.7846 30.4751 76.2703 30.4751C78.5773 30.4751 78.7122 32.5123 78.7122 33.9559C78.7122 35.332 78.348 37.2882 76.1624 37.2882C75.7172 37.2882 75.0966 37.0859 74.8267 36.6811L74.7863 36.6946V39.9325H72.8705V30.5291ZM74.7863 33.8884C74.7863 34.563 74.8672 35.5344 75.7576 35.5344C76.6481 35.5344 76.7425 34.6035 76.7425 33.8884C76.7425 33.2004 76.702 32.229 75.7576 32.229C74.8402 32.229 74.7863 33.2408 74.7863 33.8884Z" fill="#F05C35"/>
            <path d="M85.7536 34.3741H81.6253C81.7062 35.2376 82.0974 35.5479 82.745 35.5479C83.0823 35.5479 83.7029 35.4264 84.2561 34.9273L85.4433 36.2494C84.5933 37.0319 83.541 37.2882 82.6911 37.2882C81.2205 37.2882 79.6285 36.5732 79.6285 33.8884C79.6285 31.3656 81.18 30.4616 82.6776 30.4616C84.7957 30.4616 85.7536 31.8917 85.7536 33.9019V34.3741ZM83.6759 33.1329C83.6085 32.5258 83.1768 32.175 82.6911 32.175C82.2054 32.175 81.7871 32.5393 81.6927 33.1329H83.6759Z" fill="#F05C35"/>
            <path d="M92.6061 37.2073H90.6903V36.5867L90.6498 36.5732C90.1776 37.1263 89.4491 37.2747 89.0039 37.2747C87.7087 37.2747 86.7643 36.6541 86.7643 35.2241C86.7643 34.0908 87.6278 33.1599 89.1793 33.1599H90.6903V32.944C90.6903 32.6472 90.5689 32.1346 89.5705 32.1346C88.9634 32.1346 88.4372 32.2695 87.992 32.6607L87.0611 31.3521C87.8706 30.6235 88.8285 30.4616 89.7729 30.4616C91.3244 30.4616 92.6061 30.9743 92.6061 32.7417V37.2073ZM90.6903 34.7789V34.563H89.4221C88.7341 34.563 88.6126 34.8598 88.6126 35.0622C88.6126 35.2376 88.7341 35.6828 89.53 35.6828C90.1237 35.6828 90.6903 35.4129 90.6903 34.7789Z" fill="#F05C35"/>
            <path d="M100.584 37.2073H98.3849L96.5906 34.563L95.9295 35.278V37.2073H94.0002V27.4126H95.9295V32.8091L95.9565 32.8226L96.8334 31.7703L97.9937 30.5291H100.395L97.8453 33.2274L100.584 37.2073Z" fill="#F05C35"/>
            <path d="M103.106 29.5038H101.177V27.8173H103.106V29.5038ZM103.106 37.2073H101.177V30.5291H103.106V37.2073Z" fill="#F05C35"/>
            <path d="M110.503 37.2073H108.587V33.2678C108.587 32.5933 108.182 32.2425 107.642 32.2425C107.076 32.2425 106.631 32.5933 106.631 33.2678V37.2073H104.701V30.5291H106.631V31.0822H106.658C107.062 30.7045 107.777 30.4616 108.277 30.4616C109.72 30.4616 110.503 31.2981 110.503 32.8631V37.2073Z" fill="#F05C35"/>
            <path d="M117.66 30.5291V37.0319C117.66 39.1635 116.149 40 114.53 40C113.72 40 112.843 39.8246 112.047 39.1365L112.992 37.8009C113.531 38.1517 114.165 38.2731 114.516 38.2731C115.177 38.2731 115.744 37.8953 115.744 37.1938V36.6676L115.717 36.6541C115.407 37.0319 114.773 37.2882 114.327 37.2882C112.101 37.2882 111.804 35.3995 111.804 34.0233C111.804 32.5933 112.061 30.4751 114.314 30.4751C114.813 30.4751 115.299 30.5831 115.703 31.0148L115.73 31.0013V30.5291H117.66ZM115.73 33.8884C115.73 33.2408 115.676 32.256 114.773 32.256C113.828 32.256 113.774 33.2004 113.774 33.8884C113.774 34.6035 113.842 35.5074 114.773 35.5074C115.663 35.5074 115.73 34.563 115.73 33.8884Z" fill="#F05C35"/>
            <path d="M10.9842 15.0505L12.0932 15.5522C11.1691 17.823 9.10953 19.2752 6.33706 19.2752C1.98033 19.2752 0 16.1859 0 12.5949V7.1292C0 3.61741 2.08595 0.580898 6.33706 0.580898C9.05672 0.580898 11.0635 1.95393 12.014 4.01347L10.905 4.56797C10.1657 2.93089 8.55503 1.71629 6.36347 1.71629C2.90449 1.71629 1.2146 4.25111 1.2146 7.182V12.6213C1.2146 15.5522 2.90449 18.1398 6.36347 18.1398C8.60784 18.1398 10.1393 16.9252 10.9842 15.0505Z" fill="#F05C35"/>
            <path d="M24.2163 19.0904H23.0545V10.7202C23.0545 8.29099 21.8135 6.97077 19.3843 6.97077C17.1663 6.97077 15.318 8.68706 15.318 11.1691V19.0904H14.1826V0H15.318V8.26459H15.3972C16.2686 6.60111 18.0905 5.88819 19.6219 5.88819C22.5528 5.88819 24.2163 7.49886 24.2163 10.3241V19.0904Z" fill="#F05C35"/>
            <path d="M29.1176 1.98033H27.7973V0.686516H29.1176V1.98033ZM29.0648 19.0904H27.903V5.99381H29.0648V19.0904Z" fill="#F05C35"/>
            <path d="M37.4093 19.0904H36.1683C34.188 19.0904 33.4751 17.9286 33.4751 16.0275V6.97077H31.6796V5.99381H33.4751V2.53483H34.6633V5.99381H37.0925V6.97077H34.6633V15.9219C34.6633 17.1365 34.9537 18.0606 36.5908 18.0606H37.4093V19.0904Z" fill="#F05C35"/>
            <path d="M56.1035 15.0505L57.2125 15.5522C56.2884 17.823 54.2288 19.2752 51.4564 19.2752C47.0996 19.2752 45.1193 16.1859 45.1193 12.5949V7.1292C45.1193 3.61741 47.2052 0.580898 51.4564 0.580898C54.176 0.580898 56.1828 1.95393 57.1333 4.01347L56.0243 4.56797C55.285 2.93089 53.6743 1.71629 51.4828 1.71629C48.0238 1.71629 46.3339 4.25111 46.3339 7.182V12.6213C46.3339 15.5522 48.0238 18.1398 51.4828 18.1398C53.7271 18.1398 55.2586 16.9252 56.1035 15.0505Z" fill="#F05C35"/>
            <path d="M69.3356 19.0904H68.1738V10.7202C68.1738 8.29099 66.9328 6.97077 64.5036 6.97077C62.2856 6.97077 60.4373 8.68706 60.4373 11.1691V19.0904H59.3019V0H60.4373V8.26459H60.5165C61.3879 6.60111 63.2098 5.88819 64.7412 5.88819C67.6721 5.88819 69.3356 7.49886 69.3356 10.3241V19.0904Z" fill="#F05C35"/>
            <path d="M80.9172 17.6646H80.8908C80.0722 18.9584 78.3031 19.2488 76.5076 19.2488C73.9992 19.2488 71.9133 18.0342 71.9133 15.5258C71.9133 13.4134 73.3391 11.8556 76.1116 11.8556H80.9172V9.98087C80.9172 8.05335 79.9402 6.94437 77.1414 6.94437C75.8475 6.94437 74.5537 7.23481 73.4975 8.21178L72.7582 7.41964C73.8144 6.38987 75.3194 5.86178 77.1678 5.86178C79.8346 5.86178 82.079 6.65392 82.079 9.92807V19.0904H80.9172V17.6646ZM80.9172 12.8326H76.3756C74.2897 12.8326 73.0223 13.7039 73.0223 15.473C73.0223 17.2685 74.4481 18.2455 76.6661 18.2455C78.356 18.2455 80.9172 17.7438 80.9172 15.4466V12.8326Z" fill="#F05C35"/>
            <path d="M89.7744 19.0904H88.5334C86.553 19.0904 85.8401 17.9286 85.8401 16.0275V6.97077H84.0446V5.99381H85.8401V2.53483H87.0283V5.99381H89.4575V6.97077H87.0283V15.9219C87.0283 17.1365 87.3188 18.0606 88.9558 18.0606H89.7744V19.0904Z" fill="#F05C35"/>
        </svg>

    
    )
}

export default LogoChitChatText
