import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { BuilderSubGroupMember } from '../../../components/builder/member'
import { ButtonSolid, ButtonText } from '../../../components/button'
import { ExplorerEditorSubGroupName } from '../../../components/explorer/editor/explorer-editor-sub-group'
import ExplorerHeader from '../../../components/explorer/explorer-header'
import ExplorerTextField from '../../../components/explorer/explorer-text-field'
import { FieldSelector } from '../../../components/field'
import Toggle from '../../../components/toggle/toggle'
import { useUpdateLearningOptionsMutation } from '../../../hooks/use-group'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { useToast } from '../../../hooks/use-toast'
import { classesOf } from '../../../libs/utils/classes-of'
import { trans } from '../../../locales/ko'
import LearningStatistics from './learning-statistics/learning-statistics'


interface IFormLearningOption {
    hintAllowed: boolean
    hintPronunciationAllowed: boolean
    voiceReplayAllowed: boolean
    translateAllowed: boolean
    keyboardAllowed: boolean
    ttsMute: boolean
    ttsSpeed: number
    ttsLocale: string
    level: number
    learningTime: number
}

const ViewSubGroupDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { showToast } = useToast()
    
    const [openMemberUpdater, setOpenMemberUpdater] = useState(false)
    
    const { subGroups } = useActiveStudyGroup()
    const { mutateAsync: updateLearningOptions, isPending } = useUpdateLearningOptionsMutation()
    
    const subGroup = useMemo(() => subGroups?.find(each => each.id === id), [id, subGroups])
    const learningOptions = useMemo(() => subGroup?.learningOptions ?? {}, [subGroup?.learningOptions])
    
    const speedOptions = useMemo(() => ['0.7', '0.8', '0.9', '1.0', '1.1', '1.2', '1.3'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const LocaleOptions = useMemo(() => [
        { label: trans.views.dashboard.member_details.learning_setting.usa, value: 'en' },
        { label: trans.views.dashboard.member_details.learning_setting.uk, value: 'gb' },
        { label: trans.views.dashboard.member_details.learning_setting.australia, value: 'au' },
        { label: trans.views.dashboard.member_details.learning_setting.india, value: 'in' }
    ], [])
    
    const levelOptions = useMemo(() => ['1', '2', '3', '4', '5', '6'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const learningTimeOptions = useMemo(() => ['5', '10', '15', '20'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const initialValues: IFormLearningOption = useMemo(() => {
        return {
            hintAllowed: learningOptions.hintAllowed ?? false,
            hintPronunciationAllowed: learningOptions.hintPronunciationAllowed ?? false,
            voiceReplayAllowed: learningOptions.voiceReplayAllowed ?? false,
            translateAllowed: learningOptions.translateAllowed ?? false,
            keyboardAllowed: learningOptions.keyboardAllowed ?? false,
            ttsMute: learningOptions.ttsMute ?? false,
            ttsSpeed: learningOptions.ttsSpeed ?? 0,
            ttsLocale: learningOptions.ttsLocale ?? '',
            level: learningOptions.level ?? 0,
            learningTime: learningOptions.learningTime ?? 0
        }
    }, [learningOptions.hintAllowed, learningOptions.hintPronunciationAllowed, learningOptions.keyboardAllowed, learningOptions.learningTime, learningOptions.level, learningOptions.translateAllowed, learningOptions.ttsLocale, learningOptions.ttsMute, learningOptions.ttsSpeed, learningOptions.voiceReplayAllowed])
    
    const onSubmit = useCallback(async (values: IFormLearningOption) => {
        if (isPending || !subGroup?.id) return
        
        try {
            await updateLearningOptions({ subGroupId: subGroup?.id, params: { ...values } })
            showToast({ title: trans.views.dashboard.sub_group_detail.learning_setting.update_success, type: 'success' })
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [isPending, showToast, subGroup?.id, updateLearningOptions])
    
    if (isEmpty(subGroup)) return null
    return (
        <React.Fragment>
            <div className='space-y-6 px-4 sm:px-6 lg:px-8'>
                <div className='w-full sm:w-3/4 text-2xl font-semibold flex items-center gap-2'>
                    <p className='flex-shrink-0 hover:font-bold cursor-pointer' onClick={() => navigate('/group')}>{trans.views.dashboard.sub_group_detail.it}</p>
                    <ChevronRightIcon className='shrink-0 w-7 h-auto'/>
                    <p className='truncate'>{subGroup?.name}</p>
                </div>
                
                <div className={classesOf('relative', 'p-4 sm:p-0')}>
                    <div className='w-full sm:w-3/4'>
                        {/* 그룹 정보 */}
                        <ExplorerHeader title={trans.views.dashboard.sub_group_detail.info}>
                            <ExplorerTextField
                                label={trans.views.dashboard.sub_group_detail.name}
                                content={subGroup?.name}
                                editor={(close) => <ExplorerEditorSubGroupName subGroup={subGroup} close={close}/>}
                            />
                            <ExplorerTextField
                                label={trans.views.dashboard.sub_group_detail.learner}
                                content={subGroup?.members.length}
                                trailing={<ButtonText onClick={() => setOpenMemberUpdater(true)}>{trans.actions.edit}</ButtonText>}
                            />
                        </ExplorerHeader>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                            {({ values, setFieldValue, dirty }) => {
                                return (
                                    <Form>
                                        {/* 학습 설정 */}
                                        <ExplorerHeader
                                            title={trans.views.dashboard.sub_group_detail.learning_setting.it}
                                            action={<ButtonSolid type='submit' variant={dirty ? 'default' : 'disabled'} loading={isPending}>{trans.actions.save}</ButtonSolid>}
                                        >
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.hintAllowed}
                                                content={<Toggle enabled={values.hintAllowed} onChange={(value: boolean) => setFieldValue('hintAllowed', value)}/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.hintPronunciationAllowed}
                                                content={<Toggle enabled={values.hintPronunciationAllowed} onChange={(value: boolean) => setFieldValue('hintPronunciationAllowed', value)}/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.voiceReplayAllowed}
                                                content={<Toggle enabled={values.voiceReplayAllowed} onChange={(value: boolean) => setFieldValue('voiceReplayAllowed', value)}/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.translateAllowed}
                                                content={<Toggle enabled={values.translateAllowed} onChange={(value: boolean) => setFieldValue('translateAllowed', value)}/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.keyboardAllowed}
                                                content={<Toggle enabled={values.keyboardAllowed} onChange={(value: boolean) => setFieldValue('keyboardAllowed', value)}/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.ttsMute}
                                                content={<Toggle enabled={values.ttsMute} onChange={(value: boolean) => setFieldValue('ttsMute', value)}/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.ttsSpeed}
                                                content={<FieldSelector name='ttsSpeed' options={speedOptions} containerClassName='w-full sm:w-1/2'/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.ttsLocale}
                                                content={<FieldSelector name='ttsLocale' options={LocaleOptions} containerClassName='w-full sm:w-1/2'/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.conversation_level}
                                                content={<FieldSelector name='level' options={levelOptions} containerClassName='w-full sm:w-1/2'/>}
                                            />
                                            <ExplorerTextField
                                                label={trans.views.dashboard.sub_group_detail.learning_setting.learningTime}
                                                content={<FieldSelector name='learningTime' options={learningTimeOptions} containerClassName='w-full sm:w-1/2'/>}
                                            />
                                        </ExplorerHeader>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                    
                    <LearningStatistics subGroup={subGroup}/>
                </div>
            </div>
            
            <BuilderSubGroupMember open={openMemberUpdater} onClose={() => setOpenMemberUpdater(false)} subGroup={subGroup}/>
        </React.Fragment>
    )
}

export default ViewSubGroupDetail