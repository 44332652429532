import { RadioGroup } from '@headlessui/react'
import React from 'react'

import { classesOf } from '../../libs/utils/classes-of'
import { RadioOption } from './radio-option'


const RadioOptionButton = <TValue, >(option: RadioOption<TValue>) => {
    return (
        <RadioGroup.Option value={option.value} className={classesOf('relative flex items-center cursor-pointer focus:outline-none')}>
            {({ active, checked }) => (
                <>
                    <span className={classesOf(checked ? 'bg-orange-500 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-orange-500' : '',
                        'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center')} aria-hidden='true'>
                        <span className='rounded-full bg-white w-1.5 h-1.5'/>
                    </span>
                    <span className='ml-3 flex flex-col'>
                        <RadioGroup.Label as='span' className={classesOf(checked ? 'text-orange-500' : 'text-gray-900', 'block font-medium')}>
                            {option.label}
                        </RadioGroup.Label>
                    </span>
                </>
            )}
        </RadioGroup.Option>
    )
}

export default RadioOptionButton
