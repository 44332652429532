import { TabPathItem, TabProps } from './index'


const TabPath = ({
    title,
    items,
    actions
}: TabProps) => {
    return (
        <div className='relative border-b border-gray-200'>
            <div className='flex items-center justify-between'>
                {title ? <h3 className='text-xl font-semibold leading-8 text-gray-900'>{title}</h3> : null}
                <div className='flex md:absolute md:right-0 md:top-0'>
                    {actions}
                </div>
            </div>
            
            <div className='mt-4'>
                <nav className='flex space-x-10 -mb-px -ml-1 overflow-auto'>
                    {items.map((item) => (
                        <TabPathItem key={item.name} name={item.name} to={item.to}/>
                    ))}
                </nav>
            </div>
        </div>
    )
}

export default TabPath
