import React from 'react'

import { PromptUsage } from '../../../libs/model/content'


interface ThreadRolePlayProps {
    chat: PromptUsage
}

const ThreadRolePlay = ({chat}: ThreadRolePlayProps) => {
    return (
        <div>
            {chat.speaker === 'user' ?
             <div className={`flex w-full justify-end`}>
                 <div className={`flex max-w-[60%] items-start`}>
                     <div className={`rounded-md p-3 ring-1 ring-inset bg-gray-100 ring-gray-200`}>
                         <div className='flex items-center justify-between gap-x-6 mb-1 text-gray-500'>
                             <div className='text-xs'>{chat.speaker}</div>
                         </div>
                         <p className='text-sm whitespace-pre-wrap'>
                             {chat.content}
                         </p>
                     </div>
                 </div>
             </div> :
             <div className={`flex w-full justify-start`}>
                 <div className={`flex gap-x-4 max-w-[60%] items-start`}>
                     <img src='/assets/icon/img_people_01.png' className='h-6 shrink-0 rounded-full'/>
                     <div className={`rounded-md p-3 ring-1 ring-inset bg-orange-100 ring-orange-200 text-left`}>
                         <div className='flex items-center justify-between gap-x-6 mb-1 text-gray-500'>
                             <div className='text-xs'>{chat.speaker}</div>
                         </div>
                         <p className='text-sm whitespace-pre-wrap'>
                             {chat.content}
                         </p>
                     </div>
                 </div>
             </div>
            }
        </div>
    )
}

export default ThreadRolePlay