import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment } from 'react'

import LogoHorizontal from '../logo/logo-horizontal'
import { Section, SidebarItem } from './index'


interface SidebarMobileProps {
    sections: Section[];
    open: boolean
    onClose: () => void
}

const SidebarMobile = ({ sections, open, onClose }: SidebarMobileProps) => {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-50 lg:hidden' onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter='transition-opacity ease-linear duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='transition-opacity ease-linear duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-900/80'/>
                </Transition.Child>
                
                <div className='fixed inset-0 flex'>
                    <Transition.Child
                        as={Fragment}
                        enter='transition ease-in-out duration-300 transform'
                        enterFrom='-translate-x-full'
                        enterTo='translate-x-0'
                        leave='transition ease-in-out duration-300 transform'
                        leaveFrom='translate-x-0'
                        leaveTo='-translate-x-full'
                    >
                        <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                            <Transition.Child as={Fragment} enter='ease-in-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in-out duration-300' leaveFrom='opacity-100' leaveTo='opacity-0'>
                                <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                                    <button type='button' className='-m-2.5 p-2.5 focus:outline-none' onClick={onClose}>
                                        <XMarkIcon className='h-6 w-6 text-white'/>
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className='flex grow flex-col gap-y-5 bg-white p-8 overflow-y-scroll'>
                                <LogoHorizontal to='/projects'/>
                                
                                <nav className='flex flex-1 flex-col mt-2'>
                                    <ul className='flex flex-1 flex-col gap-y-7'>
                                        {sections.map((section, index) => (
                                            <li key={index}>
                                                {section.category && (
                                                    <div className='text-xs font-semibold text-gray-400 mb-5'>{section.category}</div>
                                                )}
                                                
                                                <ul className='-mx-2 space-y-1' onClick={onClose}>
                                                    {section.items?.map(item => (
                                                        <li key={item.name}>
                                                            <SidebarItem {...item} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default SidebarMobile
