import { Bars3Icon } from '@heroicons/react/24/outline'
import React from 'react'

import { useActiveAccount } from '../../hooks/use-account'
import { classesOf } from '../../libs/utils/classes-of'
import LogoHorizontal from '../logo/logo-horizontal'
import AccountPopover from '../popover/account-popover'


interface HeaderDashboardProps {
    handleSidebarOpen?: () => void
}

const HeaderDashboard = ({ handleSidebarOpen }: HeaderDashboardProps) => {
    const { isAdmin } = useActiveAccount()
    
    return (
        <header className='sticky top-0 lg:mx-auto z-20'>
            <div className={classesOf(
                'w-full h-16',
                'flex items-center',
                'gap-4 lg:gap-6',
                'px-4 lg:px-6',
                'border-b',
                'bg-white',
                'shadow-sm lg:shadow-none'
            )}>
                {isAdmin ? null :
                 <div className='flex py-1.5 shrink-0 items-center'>
                     <LogoHorizontal to='/dashboard'/>
                 </div>
                }
                <button type='button' className='-m-2.5 p-2.5 text-gray-700 lg:hidden' onClick={handleSidebarOpen}>
                    <Bars3Icon className='h-6 w-6' aria-hidden='true'/>
                </button>
                <div className='h-6 w-px bg-gray-200 lg:hidden' aria-hidden='true'/>
                
                <div className='flex items-center justify-end grow gap-x-4'>
                    {/*<LanguagePopover/>*/}
                    <AccountPopover/>
                </div>
            </div>
        </header>
    )
}

export default HeaderDashboard
