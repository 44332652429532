import React from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import { CheckboxOption } from './index'


interface CheckboxOptionCardProps<TValue> {
    option: CheckboxOption<TValue>
    onChange: () => void
    checked: boolean
}

const CheckboxOptionCard = <TValue, >({ option, onChange, checked }: CheckboxOptionCardProps<TValue>) => {
    return (
        <li>
            <input type='checkbox' id={option.id} checked={checked} onChange={onChange} className='hidden peer'/>
            <label htmlFor={option.id}
                   className={classesOf(
                       'w-full',
                       'inline-flex items-center justify-between',
                       'p-3',
                       'border border-gray-300',
                       'peer-checked:border-2', palette.checkbox.option_card.active_border,
                       'rounded-lg',
                       'cursor-pointer'
                   )}
            >
                <div className='flex items-center gap-2'>
                    {option.leading}
                    <p className='text-gray-900 font-medium break-all'>{option.label}</p>
                    <p className='text-gray-500 truncate'>{option.description}</p>
                </div>
                {option.trailing}
            </label>
        </li>
    )
}

export default CheckboxOptionCard
