import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useState } from 'react'

import { BuilderMember } from '../../../components/builder/member'
import { ButtonOutline, ButtonSolid } from '../../../components/button'
import { DestructiveModal } from '../../../components/modal'
import TableMemberLearner from '../../../components/table/table-member-learner'
import { useToast } from '../../../hooks/use-toast'
import { useDeleteAllUsersMutation, useDeleteUsersMutation, useUserListQuery } from '../../../hooks/use-users'
import { Account } from '../../../libs/model/account'
import { trans } from '../../../locales/ko'


interface IFormUsersPerPage {
    usersPerPage: number
}

const ViewMember = () => {
    const [openMemberBuilder, setOpenMemberBuilder] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false)
    const [selectedPeople, setSelectedPeople] = useState<Account[]>([])
    const [userList, setUserList] = useState<Account[]>([])
    const [usersPerPage, setUsersPerPage] = useState(10)
    const { data, isLoading } = useUserListQuery(0, usersPerPage)
    
    const { showToast } = useToast()
    
    const { mutateAsync: deleteAllUsers, isPending: deleteAllPending } = useDeleteAllUsersMutation()
    const { mutateAsync: deleteUsers, isPending: deleteUserPending } = useDeleteUsersMutation()
    const handleDeleteMember = useCallback(async () => {
        if (deleteUserPending) return
        const userIds = selectedPeople.map(each => each.id)
        
        try {
            await deleteUsers({ userIds })
            setSelectedPeople([])
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        } finally {
            setOpenDeleteModal(false)
        }
    }, [deleteUsers, deleteUserPending, selectedPeople, showToast])
    
    const handleDeleteAllMembers = useCallback(async () => {
        if (deleteAllPending) return
        try {
            await deleteAllUsers()
            setSelectedPeople([])
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        } finally {
            setOpenDeleteAllModal(false)
        }
    }, [deleteAllPending, deleteAllUsers, showToast])
    
    return (
        <React.Fragment>
            <div className='px-4 sm:px-6 lg:px-8'>
                <div className='sm:flex sm:items-center'>
                    <div className='sm:flex-auto'>
                        <h1 className='text-2xl font-semibold leading-6 text-gray-900'>{trans.views.dashboard.member.it}</h1>
                    </div>
                    <div className='mt-4 sm:ml-16 sm:mt-0 flex items-center gap-3'>
                        <div className='flex flex-row items-center gap-x-2'>
                            <label htmlFor='usersPerPage' className='text-sm font-semibold leading-6 text-gray-800'>{trans.views.dashboard.member.users_per_page}</label>
                            <select
                                id='usersPerPage'
                                value={usersPerPage}
                                onChange={(e) => {
                                    setUsersPerPage(Number(e.target.value))
                                    setUserList(data?.result ?? [])
                                    setSelectedPeople([])
                                }}
                                className='p-2 border border-gray-300 rounded-md w-20 focus:ring-orange-600'
                            >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <ButtonSolid onClick={() => {
                            setOpenMemberBuilder(true)
                            setSelectedPeople([])
                        }}>
                            {trans.actions.add}
                        </ButtonSolid>
                        <ButtonOutline variant={isEmpty(selectedPeople) ? 'disabled' : 'alert'} onClick={() => {
                            if (isEmpty(selectedPeople)) return showToast({ title: trans.views.dashboard.member.error.empty_selected_member, type: 'error' })
                            setOpenDeleteModal(true)
                        }}>
                            {isEmpty(selectedPeople) ? `${trans.views.dashboard.member.delete}` :
                             `${selectedPeople.length}명 ${trans.views.dashboard.member.delete}`}
                        </ButtonOutline>
                        <ButtonOutline variant={isEmpty(userList) ? 'disabled' : 'alert'} onClick={() => {
                            setOpenDeleteAllModal(true)
                        }}>
                            {`${trans.views.dashboard.member.delete_all}`}
                        </ButtonOutline>
                    </div>
                </div>
                <TableMemberLearner userList={userList} setUserList={setUserList} selectedPeople={selectedPeople} setSelectedPeople={setSelectedPeople} itemsPerPage={usersPerPage}/>
            </div>
            
            <BuilderMember open={openMemberBuilder} onClose={() => setOpenMemberBuilder(false)}/>
            <DestructiveModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onConfirm={handleDeleteMember}
                title={trans.views.dashboard.member.delete_modal.title}
                description={trans.views.dashboard.member.delete_modal.description}
                confirmCode={trans.actions.delete}
                loading={deleteUserPending}
            />
            <DestructiveModal
                open={openDeleteAllModal}
                onClose={() => setOpenDeleteAllModal(false)}
                onConfirm={handleDeleteAllMembers}
                title={trans.views.dashboard.member.delete_all_modal.title}
                description={trans.views.dashboard.member.delete_all_modal.description}
                confirmCode={trans.actions.delete}
                loading={deleteAllPending}
            />
        </React.Fragment>
    )
}

export default ViewMember