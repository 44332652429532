import React from 'react'
import { Outlet } from 'react-router-dom'


const LayoutAuth: React.FC = () => {
    return (
        <div className='flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
            <Outlet/>
        </div>
    )
}

export default LayoutAuth
