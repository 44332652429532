export enum StudyGroupState {
    NONE, NO_MEMBER, STUDY_GROUP_MEMBER, STUDY_GROUP_ADMIN, STUDY_GROUP_MEMBER_ENDED, STUDY_GROUP_ADMIN_ENDED,
}

export enum AccountState {
    NONE,
    INVITED,
    JOINED,
    REJECTED
}

export interface Account {
    id: string
    
    realName: string
    nickName: string
    
    email: string
    verifiedEmail: string
    
    state: AccountState
    studyGroupState?: StudyGroupState
    isStudyGroupAdmin: boolean
    
    studyGroupId: string
    subGroupIds: string[]
    
    autoHintRecommend: boolean
    hintPronunciation: boolean
    ttsSpeed: number
    ttsMute: boolean
    ttsLocale: string
    level: number
    learningTime: number
    dailyStudyTime: number
    installTime: number
    onboardingChatEnabled: boolean
    autoRecord: boolean
    totalLearningCount: number
    totalSentences: number
    totalTimeInSecond: number
    
    gender: string
    platform: string
    joinTimestamp: number
    timeZoneName: string
    countryCode: string
    languageCode: string
    appVersion: string
    deviceOS: string
    deviceId: string
    premiumEndTime: number
    premiumId: string
    logout: boolean
    lastAppOpen: number
}

