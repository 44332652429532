import { Popover, Transition } from '@headlessui/react'
import { PencilIcon } from '@heroicons/react/24/solid'
import React, { Fragment, useEffect, useRef, useState } from 'react'

import useWindowSize from '../../hooks/use-window-size'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import { ButtonText } from '../button'
import { Copy } from '../copy'
import { PopoverClose } from '../popover'


interface ExplorerTextFieldProps {
    label?: string
    content?: string | React.ReactNode
    editor?: (close: PopoverClose) => React.ReactNode
    editorComponent?: React.ReactNode
    copy?: boolean
    trailing?: string | React.ReactNode
    icon?: React.ElementType
    containerClassName?: string
    contentClassName?: string
    labelClassName?: string
    hasDivider?: boolean
}

const ExplorerTextField = ({
    label,
    content,
    editor,
    editorComponent,
    copy,
    trailing,
    icon: IconComponent = PencilIcon,
    containerClassName = '',
    contentClassName = '',
    labelClassName = '',
    hasDivider = true
}: ExplorerTextFieldProps) => {
    const { width } = useWindowSize()
    const contentRef = useRef<HTMLDivElement>(null)
    const [contentWidth, setContentWidth] = useState<number | undefined>(undefined)
    
    useEffect(() => {
        setContentWidth(contentRef.current?.clientWidth)
    }, [width, contentRef])
    
    return (
        <div className={classesOf('py-3 flex flex-wrap gap-1 sm:gap-0', containerClassName, hasDivider ? 'border-b border-gray-100' : '')}>
            <span className={classesOf('text-gray-900 font-medium sm:w-1/4 pr-6 truncate',labelClassName)}>{label}</span>
            
            <div className='flex justify-between gap-x-6 flex-auto w-full sm:w-3/4' ref={contentRef}>
                <React.Fragment>
                    {typeof content === 'string' ?
                     <div className='flex gap-1 min-w-0 break-all'>
                         {content}
                         {copy && <Copy text={content}/>}
                     </div> : content
                    }
                    
                    {trailing ? <React.Fragment>{trailing}</React.Fragment> : null}
                    
                    {editorComponent ? editorComponent : (
                        <React.Fragment>
                            {editor && (
                                <React.Fragment>
                                    <Popover className='relative' as='div'>
                                        <Popover.Button as='div'>
                                            <ButtonText>{trans.actions.edit}</ButtonText>
                                        </Popover.Button>
                                        
                                        <Transition
                                            as={Fragment}
                                            enter='transition ease-out duration-200'
                                            enterFrom='opacity-0 translate-y-1'
                                            enterTo='opacity-100 translate-y-0'
                                            leave='transition ease-in duration-150'
                                            leaveFrom='opacity-100 translate-y-0'
                                            leaveTo='opacity-0 translate-y-1'
                                        >
                                            <Popover.Panel className={classesOf(
                                                'absolute',
                                                '-top-2 -right-2 z-10',
                                                'p-3',
                                                'bg-white border shadow-md rounded-lg'
                                            )} style={{ width: `calc(${contentWidth}px + 1rem)` }}>
                                                {({ close }) => (
                                                    <React.Fragment>
                                                        {editor(close)}
                                                    </React.Fragment>
                                                )}
                                            </Popover.Panel>
                                        </Transition>
                                    </Popover>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            </div>
        </div>
    )
}

export default ExplorerTextField
