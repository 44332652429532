import { Listbox } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import React from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import { SelectorOption } from './selector-option'


const SelectorOptionText = <TValue, >(option: SelectorOption<TValue>) => {
    return (
        <Listbox.Option
            value={option.value}
            className={({ active }) =>
                classesOf(
                    'relative z-30',
                    'py-2 px-3',
                    'cursor-pointer',
                    'select-none',
                    active ? palette.selector.option_text.active : '',
                    active ? palette.selector.option_text.active_text : 'text-gray-900'
                )
            }
        >
            {({ selected }) => (
                <div className='grid grid-cols-6'>
                    <span className={classesOf(
                        'block truncate col-span-2',
                        selected ? 'font-medium' : 'font-normal'
                    )}>{option?.label}</span>
                    
                    {selected ? (
                        <span className={classesOf(
                            'absolute inset-y-0 right-0 flex items-center pr-3',
                            palette.selector.option_text.selected_text
                        )}>
                            <CheckIcon className='h-5 w-5' aria-hidden='true'/>
                        </span>
                    ) : null}
                </div>
            )}
        </Listbox.Option>
    )
}

export default SelectorOptionText
