import React from 'react'

import { useUserQuery } from '../../hooks/use-users'
import { ChatContent } from '../../libs/model/chat-content'
import { differenceFromNow } from '../../libs/utils/formatter-date'
import { trans } from '../../locales/ko'


interface ThreadListItemProps {
    content: ChatContent
}

export const ThreadListItem = ({
    content,
}: ThreadListItemProps) => {
    const { data: user } = useUserQuery(content.userId)
    
    return (
        <div>
            {content.isMine ?
             <div className={`flex w-full justify-end px-4 sm:px-6`}>
                 <div className={`flex max-w-[60%] items-start`}>
                     <div className={`rounded-md p-3 ring-1 ring-inset bg-gray-100 ring-gray-200`}>
                         <div className='flex items-center justify-between gap-x-6 mb-1 text-gray-500'>
                             <div className='text-xs'>{user?.realName}</div>
                             <time className='block text-xs'>
                                 {differenceFromNow(content.chatTime, trans.keywords.just_before)}
                             </time>
                         </div>
                         <p className='text-sm whitespace-pre-wrap'>
                             {content.content}
                         </p>
                     </div>
                 </div>
             </div> :
             <div className={`flex w-full justify-start px-4 sm:px-6`}>
                 <div className={`flex gap-x-4 max-w-[60%] items-start`}>
                     <img src='/assets/icon/img_people_01.png' className='h-6 shrink-0 rounded-full' alt=''/>
                     <div className={`rounded-md p-3 ring-1 ring-inset bg-orange-100 ring-orange-200 text-left`}>
                         <time className='block text-xs text-gray-500 mb-1'>
                             {differenceFromNow(content.chatTime, trans.keywords.just_before)}
                         </time>
                         <p className='text-sm whitespace-pre-wrap'>
                             {content.content}
                         </p>
                     </div>
                 </div>
             </div>
            }
        </div>
    )
}
