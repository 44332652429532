import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { Form, Formik, FormikProps } from 'formik'
import React, { useCallback, useMemo, useRef } from 'react'
import * as Yup from 'yup'

import { useAddUsersMutation } from '../../../hooks/use-member'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { useToast } from '../../../hooks/use-toast'
import { trans } from '../../../locales/ko'
import FieldTextareaBulkData from '../../field/field-textarea-bulkData'
import { Slide, SlideOver } from '../../slide'


interface IFormBuilderMember {
    bulkData: string
}

interface BuilderMemberProps {
    open: boolean
    onClose: () => void
}

const BuilderMember = ({ open, onClose }: BuilderMemberProps) => {
    const { showToast } = useToast()
    const { studyGroupId } = useActiveStudyGroup()
    const { mutateAsync: addUsers, isPending } = useAddUsersMutation()
    
    const validateBulkData = useCallback((bulkData: string) => {
        if (!bulkData) return { isValid: false, errorMessage: trans.components.builder.member.error.bulk_data_required }
        
        const memberList = bulkData.trim().split('\n')
        const emailSet = new Set()
        
        let isValid = true
        let errorMessage = ''
        
        for (let member of memberList) {
            const parts = member.split('|')
            if (parts.length !== 3) {
                isValid = false
                errorMessage = trans.components.builder.member.error.bulk_data_missing_field
                break
            }
            
            const email = parts[1].trim()
            if (emailSet.has(email)) {
                isValid = false
                errorMessage = `${trans.components.builder.member.error.bulk_data_email_duplicate} ${email}`
                break
            }
            emailSet.add(email)
        }
        
        return { isValid, errorMessage }
    }, [])
    
    const parseBulkData = useCallback((bulkData: string) => {
        return bulkData.trim().split('\n').map((line) => {
            const parts = line.split('|')
            const realName = parts[0]
            const email = parts[1]
            const subGroupNames = parts[2] ? parts[2].split(',').map(name => name.trim()) : []
            return { realName, email, subGroupNames }
        })
    }, [])
    
    const formRef = useRef<FormikProps<IFormBuilderMember> | null>(null)
    
    const initialValues: IFormBuilderMember = { bulkData: '' }
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            bulkData: Yup.string()
        })
    }, [])
    
    const handleConfirm = useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }, [])
    
    const onSubmit = useCallback(async (values: IFormBuilderMember) => {
        if (isPending) return
        
        const { isValid, errorMessage } = validateBulkData(values.bulkData)
        if (!isValid) {
            showToast({ title: errorMessage, type: 'error' })
            return
        }
        
        const parsedData = parseBulkData(values.bulkData)
        try {
            await addUsers({
                studyGroupId,
                bulkData: parsedData
            })
            onClose()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [isPending, validateBulkData, parseBulkData, onClose, showToast, addUsers, studyGroupId])
    
    return (
        <SlideOver
            open={open}
            onClose={onClose}
            title={trans.components.builder.member.title}
            confirm={trans.actions.create}
            handleConfirm={handleConfirm}
            slideWidth='wide'
            isLoading={isPending}
        >
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={formRef}>
                {({ errors, touched, values, dirty }) => {
                    return (
                        <Slide.Content>
                            <Form>
                                <div className='space-y-6 pb-5'>
                                    <h1 className='font-semibold text-lg'>{trans.components.builder.member.instruction}</h1>
                                    <div className='grid grid-cols-7 gap-2'>
                                        <div className='col-span-3 flex justify-center items-center'>
                                            <img src='/assets/member_copy.png' alt='member_copy_excel'/>
                                        </div>
                                        <div className='col-span-1 flex items-center justify-center'>
                                            <ArrowLongRightIcon className='w-16 h-auto stroke-2 text-orange-500 animate-pulse'/>
                                        </div>
                                        <div className='col-span-3'>
                                            <FieldTextareaBulkData
                                                id='bulkData'
                                                name='bulkData'
                                                className='font-mono'
                                                placeholder={trans.components.builder.member.placeholder}
                                                cols={30}
                                                rows={20}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Slide.Content>
                    )
                }}
            </Formik>
        </SlideOver>
    )
}

export default BuilderMember