import { useCallback } from 'react'


const useCopyToClipboard = () => {
    return useCallback((text: string): Promise<void> => {
        return navigator.clipboard.writeText(text.trim())
    }, [])
}

export default useCopyToClipboard
