import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { useExportLearningHistoryRowsMutation } from '../../hooks/use-learning-history'
import { useToast } from '../../hooks/use-toast'
import { useUserQueries } from '../../hooks/use-users'
import { LearningHistoryData, LearningHistoryPerDay } from '../../libs/model/learning-history'
import { exportToExcel } from '../../libs/utils/exporter-xlsx'
import { msToHoursMinutesAndSeconds, msToMinutes } from '../../libs/utils/fomatter-time'
import { trans } from '../../locales/ko'
import { ButtonSolid } from '../button'


interface TableLearningHistoryGroupAverageProps {
    members: string[]
    learningHistoryByWeek: LearningHistoryPerDay[][]
    subGroupName: string
    reportDate: string
}

const TableLearningHistoryGroupAverage = ({ members, learningHistoryByWeek, subGroupName, reportDate }: TableLearningHistoryGroupAverageProps) => {
    const { data: memberList } = useUserQueries(members)
    const realNameList = useMemo(() => memberList.map(each => each.realName || each.nickName), [memberList])
    
    const getActiveLearningHistoryByUser = useCallback((index: number) => {
        return learningHistoryByWeek?.[index].flatMap((each) => {
            return (
                Object.entries(each).flatMap(([date, learningHistoryData]: [string, Omit<LearningHistoryData, 'date'>[]]) => {
                    return learningHistoryData.map(data => ({ ...data, date }))
                })
            )
        }) ?? []
    }, [learningHistoryByWeek])
    
    const getAverages = useCallback((activeLearningHistory: LearningHistoryData[]) => {
        if (isEmpty(activeLearningHistory)) return {}
        const totals = activeLearningHistory?.reduce((acc, curr) => {
            acc.score += curr.score
            acc.timeInMs += curr.timeInMs
            acc.accuracyPoint += curr.accuracyPoint
            acc.fluencyPoint += curr.fluencyPoint
            acc.missionPoint += curr.missionPoint
            acc.assistantPoint += curr.assistantPoint
            acc.levelPoint += curr.levelPoint
            acc.count += 1
            return acc
        }, { score: 0, timeInMs: 0, accuracyPoint: 0, fluencyPoint: 0, missionPoint: 0, assistantPoint: 0, levelPoint: 0, count: 0 })
        
        return {
            totalTimeInMs: msToMinutes(totals.timeInMs),
            timeInMs: msToHoursMinutesAndSeconds(totals.timeInMs / totals.count),
            accuracyPoint: (totals.accuracyPoint / totals.count).toFixed(1),
            fluencyPoint: (totals.fluencyPoint / totals.count).toFixed(1),
            missionPoint: (totals.missionPoint / totals.count).toFixed(1),
            assistantPoint: (totals.assistantPoint / totals.count).toFixed(1),
            levelPoint: (totals.levelPoint / totals.count),
            score: (totals.score / totals.count).toFixed(1)
        }
    }, [])
    
    const [currentPage, setCurrentPage] = useState(0)
    const itemsPerPage = 10
    const currentList = useMemo(() => {
        const start = currentPage * itemsPerPage
        const end = start + itemsPerPage
        return realNameList.slice(start, end)
    }, [currentPage, realNameList])
    
    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected)
    }
    
    const { mutateAsync, isPending } = useExportLearningHistoryRowsMutation()
    const [currentExportItem, setCurrentExportItem] = useState('')
    const { showToast } = useToast()
    
    const handleExport = useCallback(async (type: 'xlsx' | 'csv') => {
        if (isPending) return
        setCurrentExportItem(type)
        try {
            const filename = `칫챗 대시보드_${subGroupName}_${reportDate}`
            const result = await mutateAsync({
                bulkData:
                    memberList.map((each, index) => {
                        return {
                            realName: realNameList[index],
                            verifiedEmail: each.verifiedEmail,
                            userId: each.id
                        }
                    }), monthString: reportDate, gmtDiff: 9
            })
            const fields = [
                trans.views.dashboard.member_details.name,
                trans.views.dashboard.member_details.email,
                trans.views.dashboard.member_details.learning_statistics.month_study_time_minute,
                trans.views.dashboard.member_details.learning_statistics.month_study_count,
                trans.views.dashboard.member_details.learning_statistics.accuracyPoint,
                trans.views.dashboard.member_details.learning_statistics.fluencyPoint,
                trans.views.dashboard.member_details.learning_statistics.missionPoint,
                trans.views.dashboard.member_details.learning_statistics.assistantPoint,
                trans.views.dashboard.member_details.learning_statistics.levelPoint,
                trans.views.dashboard.member_details.learning_statistics.joined
            ]
            const points = result?.map(each => {
                return each.map((e: any) => e.toString())
            }) ?? []
            exportToExcel(fields, points, filename, type)
        } catch (e) {
            showToast({ title: trans.views.dashboard.member_details.learning_statistics.failed, type: 'error' })
        } finally {
            setCurrentExportItem('')
        }
        
    }, [showToast, isPending, mutateAsync, memberList, realNameList, reportDate, subGroupName])
    
    return (
        <React.Fragment>
            <div className='flex justify-end items-center gap-3 mb-3'>
                <ButtonSolid loading={isPending && currentExportItem === 'csv'} onClick={() => handleExport('csv')}>
                    <ArrowDownTrayIcon className='w-auto h-4 mr-1'/> CSV
                </ButtonSolid>
                <ButtonSolid loading={isPending && currentExportItem === 'xlsx'} onClick={() => handleExport('xlsx')}>
                    <ArrowDownTrayIcon className='w-auto h-4 mr-1'/> Excel
                </ButtonSolid>
            </div>
            <div className='relative space-y-12'>
                <div className='rounded-md divide-y divide-gray-200 border border-gray-200'>
                    <div className='grid grid-cols-10 font-semibold text-center divide-x divide-gray-200'>
                        <p className='px-6 py-4'>{trans.views.dashboard.member_details.name}</p>
                        <p className='px-6 py-4 col-span-2'>{trans.views.dashboard.member_details.learning_statistics.month_study_time_minute}</p>
                        <p className='px-6 py-4 col-span-2'>{trans.views.dashboard.member_details.learning_statistics.month_study_count}</p>
                        <p className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.accuracyPoint}</p>
                        <p className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.fluencyPoint}</p>
                        <p className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.missionPoint}</p>
                        <p className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.assistantPoint}</p>
                        <p className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.levelPoint}</p>
                    </div>
                    {currentList.map((user, index) => {
                        const activeLearningHistory = getActiveLearningHistoryByUser(currentPage * itemsPerPage + index)
                        const monthAverage = getAverages(activeLearningHistory)
                        return (
                            <div key={`${user}-${index}`} className='grid grid-cols-10 text-center font-semibold text-gray-500 divide-x divide-gray-200'>
                                <p className='text-gray-900 px-6 py-4 break-all flex items-center justify-center'>{realNameList[currentPage * itemsPerPage + index] ?? ''}</p>
                                <p className='px-6 py-4 col-span-2 break-all flex items-center justify-center'>{monthAverage.totalTimeInMs ? `${monthAverage.totalTimeInMs}분` : '-'}</p>
                                <p className='px-6 py-4 col-span-2 break-all flex items-center justify-center'>{!isEmpty(activeLearningHistory) ? activeLearningHistory.length : '-'}</p>
                                <p className='px-6 py-4 break-all flex items-center justify-center'>{monthAverage.accuracyPoint ?? '-'}</p>
                                <p className='px-6 py-4 break-all flex items-center justify-center'>{monthAverage.fluencyPoint ?? '-'}</p>
                                <p className='px-6 py-4 break-all flex items-center justify-center'>{monthAverage.missionPoint ?? '-'}</p>
                                <p className='px-6 py-4 break-all flex items-center justify-center'>{monthAverage.assistantPoint ?? '-'}</p>
                                <p className='px-6 py-4 break-all flex items-center justify-center'>{monthAverage.levelPoint ?? '-'}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='absolute left-1/2 transform -translate-x-1/2'>
                    <ReactPaginate
                        className='mt-8 flex items-center justify-center gap-10'
                        previousLabel={trans.actions.prev}
                        nextLabel={trans.actions.next}
                        breakLabel={'...'}
                        pageCount={Math.ceil(realNameList.length / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default TableLearningHistoryGroupAverage