import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import AuthGuard from '../components/guard/auth-guard'
import { SessionState } from '../contexts/session'
import LayoutAuth from '../layouts/auth'
import LayoutDashboard from '../layouts/dashboard/layout-dashboard'
import LayoutLanding from '../layouts/landing'
import { ViewSignIn } from '../views/auth'
import ViewEmailAuth from '../views/auth/view-email-auth'
import ViewKakaoAuth from '../views/auth/view-kakao-auth'
import { ViewContent, ViewContentDetail } from '../views/dashboard/content'
import { ViewMember, ViewMemberDetails } from '../views/dashboard/member'
import { ViewSetting } from '../views/dashboard/setting'
import ViewApplyOption from '../views/dashboard/setting/apply-option/view-apply-option'
import ViewLearningOption from '../views/dashboard/setting/learning-option/view-learning-option'
import ViewScoreStrategy from '../views/dashboard/setting/score-strategy/view-score-strategy'
import ViewScoreStrategyDetail from '../views/dashboard/setting/score-strategy/view-score-strategy-detail'
import { ViewSubGroup, ViewSubGroupDetail } from '../views/dashboard/sub-group'
import { ViewDashboard } from '../views/dashboard/view-dashboard'


const AdminRoutes = () => {
    return (
        <Routes>
            <Route element={<LayoutLanding/>}>
                <Route element={
                    <AuthGuard condition={SessionState.SignedOut}>
                        <LayoutAuth/>
                    </AuthGuard>
                }>
                    <Route path='/' element={<ViewSignIn/>}/>
                    <Route path='/sign-in' element={<ViewEmailAuth/>}/>
                    <Route path='/kakao-auth' element={<ViewKakaoAuth/>}/>
                </Route>
                <Route element={
                    <AuthGuard condition={SessionState.SignedIn}>
                        <Outlet/>
                    </AuthGuard>
                }>
                    <Route element={<LayoutDashboard/>}>
                        <Route path='/dashboard' element={<ViewDashboard/>}/>
                        <Route path='/group' element={<ViewSubGroup/>}/>
                        <Route path='/group/:id' element={<ViewSubGroupDetail/>}/>
                        <Route path='/member' element={<ViewMember/>}/>
                        <Route path='/member/:id' element={<ViewMemberDetails/>}/>
                        <Route path='/content' element={<ViewContent/>}/>
                        <Route path='/content/:id' element={<ViewContentDetail/>}/>
                        <Route path='/setting' element={<ViewSetting/>}>
                            <Route index element={<ViewScoreStrategy/>}/>
                            <Route path='learning-option' element={<ViewLearningOption/>}/>
                            <Route path='apply-option' element={<ViewApplyOption/>}/>
                        </Route>
                        <Route path='/setting/:id' element={<ViewScoreStrategyDetail/>}/>
                        <Route path='payout' element={<ViewDashboard/>}/>
                    </Route>
                    <Route path='*' element={<Navigate to='/'/>}/>
                </Route>
            </Route>
        </Routes>
    )
}

export default AdminRoutes