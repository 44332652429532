import { useCallback, useEffect, useState } from 'react'


const useWindowSize = () => {
    const isClient = typeof window === 'object'
    
    const getSize = useCallback(() => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        }
    }, [isClient])
    
    const [windowSize, setWindowSize] = useState(getSize)
    
    useEffect(() => {
        if (!isClient) {
            return
        }
        
        function handleResize() {
            setWindowSize(getSize())
        }
        
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [isClient, getSize])
    
    return windowSize
}
export default useWindowSize
