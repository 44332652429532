import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useState } from 'react'

import { BuilderSubGroup } from '../../../components/builder/group'
import { ButtonOutline, ButtonSolid } from '../../../components/button'
import { DestructiveModal } from '../../../components/modal'
import TableGroup from '../../../components/table/table-group'
import { useDeleteSubGroupsMutation } from '../../../hooks/use-group'
import { useToast } from '../../../hooks/use-toast'
import { SubGroup } from '../../../libs/model/study-group'
import { trans } from '../../../locales/ko'


const ViewSubGroup = () => {
    const [openGroupBuilder, setOpenGroupBuilder] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedSubGroups, setSelectedSubGroups] = useState<SubGroup[]>([])
    
    const { showToast } = useToast()
    const { mutateAsync: deleteSubGroups, isPending } = useDeleteSubGroupsMutation()
    
    const handleDeleteSubGroups = useCallback(async () => {
        if (isPending) return
        const subGroupIds = selectedSubGroups.map(each => each.id)
        
        try {
            await deleteSubGroups({ subGroupIds })
            setSelectedSubGroups([])
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        } finally {
            setOpenDeleteModal(false)
        }
    }, [deleteSubGroups, isPending, selectedSubGroups, showToast])
    
    return (
        <React.Fragment>
            <div className='px-4 sm:px-6 lg:px-8'>
                <div className='sm:flex sm:items-center'>
                    <div className='sm:flex-auto'>
                        <h1 className='text-2xl font-semibold leading-6 text-gray-900'>{trans.views.dashboard.group.it}</h1>
                    </div>
                    <div className='mt-4 sm:ml-16 sm:mt-0 flex items-center gap-3'>
                        <ButtonSolid onClick={() => setOpenGroupBuilder(true)}>
                            {trans.actions.add}
                        </ButtonSolid>
                        <ButtonOutline variant='alert' onClick={() => {
                            if (isEmpty(selectedSubGroups)) return showToast({ title: trans.views.dashboard.group.error.empty_selected_member, type: 'error' })
                            setOpenDeleteModal(true)
                        }}>
                            {isEmpty(selectedSubGroups) ? `${trans.views.dashboard.group.delete}` :
                             `${selectedSubGroups.length}개 ${trans.views.dashboard.group.delete}`}
                        </ButtonOutline>
                    </div>
                </div>
                <TableGroup selectedSubGroups={selectedSubGroups} setSelectedSubGroups={setSelectedSubGroups}/>
            </div>
            
            <BuilderSubGroup open={openGroupBuilder} onClose={() => setOpenGroupBuilder(false)}/>
            <DestructiveModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onConfirm={handleDeleteSubGroups}
                title={trans.views.dashboard.group.delete_modal.title}
                description={trans.views.dashboard.group.delete_modal.description}
                confirmCode={trans.actions.delete}
                loading={isPending}
            />
        </React.Fragment>
    )
}

export default ViewSubGroup