import { Form, Formik } from 'formik'
import React, { useCallback, useMemo } from 'react'

import { ButtonSolid } from '../../../../components/button'
import ExplorerHeader from '../../../../components/explorer/explorer-header'
import ExplorerTextField from '../../../../components/explorer/explorer-text-field'
import Toggle from '../../../../components/toggle/toggle'
import { useUpdateApplyOptionsMutation } from '../../../../hooks/use-setting'
import { useActiveStudyGroup } from '../../../../hooks/use-study-group'
import { useToast } from '../../../../hooks/use-toast'
import { classesOf } from '../../../../libs/utils/classes-of'
import { trans } from '../../../../locales/ko'


interface IFormApplyOption {
    applyLearningOptions: boolean
    applyScoreStrategy: boolean
    applyGroupContents: boolean
}

const ViewApplyOption = () => {
    const { showToast } = useToast()
    const { studyGroup } = useActiveStudyGroup()
    const { mutateAsync: updateApplyOptions, isPending } = useUpdateApplyOptionsMutation()
    
    const initialValues: IFormApplyOption = useMemo(() => {
        return {
            applyLearningOptions: studyGroup?.applyLearningOptions ?? false,
            applyScoreStrategy: studyGroup?.applyScoreStrategy ?? false,
            applyGroupContents: studyGroup?.applyGroupContents ?? false
        }
    }, [studyGroup?.applyGroupContents, studyGroup?.applyLearningOptions, studyGroup?.applyScoreStrategy])
    
    const onSubmit = useCallback(async (values: IFormApplyOption) => {
        if (isPending) return
        
        try {
            await updateApplyOptions({ options: { ...values } })
            showToast({ title: trans.views.dashboard.setting.apply_option.update_success, type: 'success' })
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [isPending, showToast, updateApplyOptions])
    
    return (
        <div className='mt-6'>
            <p className='font-semibold whitespace-pre-line'>{trans.views.dashboard.setting.apply_option.description}</p>
            <div className={classesOf('relative', 'mt-6 p-4 sm:p-0')}>
                <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                    {({ values, setFieldValue, dirty }) => {
                        return (
                            <Form>
                                <ExplorerHeader
                                    title={trans.views.dashboard.setting.apply_option.it}
                                    action={<ButtonSolid type='submit' variant={dirty ? 'default' : 'disabled'} loading={isPending}>{trans.actions.save}</ButtonSolid>}
                                >
                                    <ExplorerTextField
                                        label={trans.views.dashboard.setting.apply_option.learning_option}
                                        content={<Toggle enabled={values.applyLearningOptions} onChange={(value: boolean) => setFieldValue('applyLearningOptions', value)}/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.setting.apply_option.score_strategy}
                                        content={<Toggle enabled={values.applyScoreStrategy} onChange={(value: boolean) => setFieldValue('applyScoreStrategy', value)}/>}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.setting.apply_option.group_contents}
                                        content={<Toggle enabled={values.applyGroupContents} onChange={(value: boolean) => setFieldValue('applyGroupContents', value)}/>}
                                    />
                                </ExplorerHeader>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default ViewApplyOption