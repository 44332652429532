import React from 'react'

import { TabItem } from './index'


interface TabProps {
    title?: string
    items: { name: string; id: string }[]
    activeTab: string
    setActiveTab: React.Dispatch<React.SetStateAction<string>>
}

const TabSimple: React.FC<TabProps> = ({ title, items, activeTab, setActiveTab }) => {
    return (
        <div className='relative border-b border-gray-200'>
            <div className='flex items-center justify-between'>
                <h3 className='text-xl font-semibold'>{title}</h3>
            </div>
            
            <div className='mt-4'>
                <nav className='flex space-x-8 -mb-px -ml-1 overflow-auto'>
                    {items.map((item) => (
                        <TabItem
                            key={item.name}
                            name={item.name}
                            isActive={item.id === activeTab}
                            onTabSelect={() => setActiveTab(item.id)}
                        />
                    ))}
                </nav>
            </div>
        </div>
    )
}

export default TabSimple