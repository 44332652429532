import 'firebase/analytics'
import 'firebase/firestore'

import { User } from '@firebase/auth'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Fallback } from '../../components/fallback'
import useSession from '../../hooks/use-session'
import { useToast } from '../../hooks/use-toast'
import { DataConnection, getUser } from '../../libs/firestore/firestore-helper'
import { Account } from '../../libs/model/account'
import { trans } from '../../locales/ko'
import { firebaseAuth } from '../firebase/firebase-provider'
import { SessionState } from './session-state'


export interface AuthContextProps {
    firebaseAuthUser: User | null
    sessionState: SessionState
    activeUser: Account | null
}

export const AuthContext = React.createContext<AuthContextProps>({
    firebaseAuthUser: null, sessionState: SessionState.Unset, activeUser: null
})
const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate()
    const { showToast } = useToast()
    const { signOut } = useSession()
    
    const [firebaseAuthUser, setFirebaseAuthUser] = useState<User | null>(null)
    const [sessionState, setSessionState] = useState(SessionState.Unset)
    const [activeUser, setActiveUser] = useState<Account | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    
    const authorize = useCallback(async () => {
        firebaseAuth.onAuthStateChanged((fbUser: User | null) => {
            setFirebaseAuthUser(fbUser)
            if (fbUser !== null) {
                if (firebaseAuthUser === null) {
                    if (!fbUser.isAnonymous) {
                        setSessionState(SessionState.SignedIn)
                        if (!activeUser) {
                            getUser(fbUser.uid).then(result => {
                                if (result) {
                                    setActiveUser(result)
                                    setIsLoading(false)
                                }
                            }).catch((error) => {
                                navigate('/')
                                showToast({ title: trans.errors.not_member, type: 'error' })
                                signOut()
                            })
                        }
                    } else {
                        setSessionState(SessionState.SignedOut)
                        setActiveUser(null)
                        setFirebaseAuthUser(null)
                        setIsLoading(false)
                    }
                }
            } else {
                setSessionState(SessionState.SignedOut)
                setActiveUser(null)
                setFirebaseAuthUser(null)
                setIsLoading(false)
            }
        })
    }, [activeUser, firebaseAuthUser, navigate, showToast, signOut])
    
    useEffect(() => {
        authorize()
    }, [authorize])
    
    if (isLoading) return <Fallback/>
    return <AuthContext.Provider value={{ firebaseAuthUser, sessionState, activeUser }}>{children}</AuthContext.Provider>
}

export interface FirestoreContextProps {
    allUsers: DataConnection<Account> | null
}

export const FirestoreContext = React.createContext<FirestoreContextProps>({ allUsers: null })
const FirestoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const context = useContext(AuthContext)
    
    const cognitoUser = useMemo(() => context.firebaseAuthUser, [context])
    const sessionState = useMemo(() => context.sessionState, [context])
    const activeUser = useMemo(() => context.activeUser, [context])
    const [allUsers, setAllUsers] = useState<DataConnection<Account> | null>(null)
    
    const init = useCallback(async () => {
        if (cognitoUser?.uid) {
            let tempAllUser: DataConnection<Account> | null = null
            setAllUsers(tempAllUser)
        } else {
            setAllUsers(null)
        }
    }, [cognitoUser?.uid])
    
    useEffect(() => {
        init()
    }, [init, cognitoUser, sessionState, activeUser])
    
    return <FirestoreContext.Provider value={{ allUsers }}>{children}</FirestoreContext.Provider>
}

export { AuthProvider, FirestoreProvider }