import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'

import { changeSubGroupName, changeSubGroupOfUser, deleteSubGroups, deleteUsersFromSubGroup, updateLearningOptions } from '../libs/firestore/firestore-helper'
import { getStudyGroup } from '../libs/firestore/study-group-operation'
import { LearningOptions } from '../libs/model/study-group'
import { useActiveStudyGroup } from './use-study-group'


export const useStudyGroupQuery = (studyGroupId?: string) => {
    return useQuery({
        queryKey: ['studyGroup', studyGroupId],
        queryFn: async () => {
            return getStudyGroup(studyGroupId)
        },
        enabled: !isEmpty(studyGroupId)
    })
}

export const useDeleteSubGroupsMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ subGroupIds }: {
            subGroupIds: string[]
        }) => deleteSubGroups(studyGroupId, subGroupIds),
        onSuccess: (data, variables) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
        }
    })
}
export const useChangeSubGroupOfUserMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroup } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ userId, targetSubGroupIds }: {
            userId: string,
            targetSubGroupIds: string[]
        }) => changeSubGroupOfUser(({ userId, studyGroupId: studyGroup?.id, targetSubGroupIds })),
        onSuccess: (data, variables, context) => {
            
            queryClient.invalidateQueries({ queryKey: ['user', variables.userId] })
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroup?.id] })
        }
    })
}

export const useChangeSubGroupNameMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ subGroupId, name }: {
            subGroupId: string,
            name: string
        }) => changeSubGroupName({ studyGroupId: studyGroupId, subGroupId, name }),
        onSuccess: (data) => {
            if (!data) return
            
            queryClient.setQueryData(['studyGroup', studyGroupId], data)
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
        }
    })
}

export const useUpdateLearningOptionsMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ subGroupId, params }: {
            subGroupId?: string,
            params: Partial<LearningOptions>
        }) => updateLearningOptions({ studyGroupId: studyGroupId, subGroupId, params }),
        onSuccess: (data) => {
            if (!data) return
            
            queryClient.setQueryData(['studyGroup', studyGroupId], data)
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
        }
    })
}

export const useDeleteUsersFromSubGroupMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ subGroupId, userIds }: {
            subGroupId: string,
            userIds: string[]
        }) => deleteUsersFromSubGroup({ studyGroupId, subGroupId, userIds }),
        onSuccess: (data) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
            queryClient.invalidateQueries({ queryKey: ['users'] })
        }
    })
}