export const transErrors: Record<string, any> = {
    '50000': '서버에 문제가 발생했습니다. 잠시 후 다시 시도해 주세요.',
    '50100': '요청하신 그룹이 존재하지 않습니다.',
    '50200': '요청하신 서브 그룹이 존재하지 않습니다.',
    '50300': '사용자를 찾을 수 없습니다.',
    '50400': '프롬프트가 존재하지 않습니다.',
    '50500': '프롬프트 카테고리가 존재하지 않습니다.',
    '50600': '채점 기준이 존재하지 않습니다.',
    '50700': '멤버를 찾을 수 없습니다.',
    '50800': '이메일 인증이 완료되지 않았습니다.',
    '50900': '이미 사용 중인 이름입니다.',
    '51000': '이미 사용 중인 이메일입니다.',
    '51100': '초대 가능한 멤버 수를 초과했습니다.',
    '51200': 'GPT 요청 중 오류가 발생했습니다.',
    '51300': 'GPT 데이터 처리 중 오류가 발생했습니다.',
    '51400': '채점 기준이 비어 있습니다.',
    '60100': '잘못된 요청 데이터입니다.',
    '40100': '접근 권한이 없습니다.'
}