import { Icon } from '@iconify/react'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { useDeleteScoreStrategyMutation, useScoreStrategyListQuery, useUpdateScoreStrategyPriorityMutation } from '../../../hooks/use-score-strategy'
import { useToast } from '../../../hooks/use-toast'
import { ScoreStrategy, ScoreType } from '../../../libs/model/score-strategy'
import { classesOf } from '../../../libs/utils/classes-of'
import { trans } from '../../../locales/ko'
import { FallbackDashboard } from '../../fallback'
import { DestructiveModal } from '../../modal'
import { Slide, SlideOver } from '../../slide'


interface BuilderScoreStrategyUpdaterProps {
    open: boolean
    onClose: () => void
}

const BuilderScoreStrategyUpdater = ({ open, onClose }: BuilderScoreStrategyUpdaterProps) => {
    const { showToast } = useToast()
    
    const [selectedScoreStrategy, setSelectedScoreStrategy] = useState<ScoreStrategy[]>([])
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    
    const checkbox = useRef<HTMLInputElement>(null)
    
    const { data, isLoading } = useScoreStrategyListQuery()
    const { mutateAsync: updateScoreStrategyPriority, isPending: isUpdating } = useUpdateScoreStrategyPriorityMutation()
    const { mutateAsync: deleteScoreStrategy, isPending: isDeleting } = useDeleteScoreStrategyMutation()
    
    const scoreStrategyList = useMemo(() => data?.strategies ?? [], [data?.strategies])
    const priorityList = useMemo(() => data?.priority ?? [], [data?.priority])
    
    useEffect(() => {
        if (isEmpty(scoreStrategyList)) return
        
        setChecked(false)
        setIndeterminate(false)
        const allSelected = scoreStrategyList.every(group => selectedScoreStrategy.includes(group))
        const someSelected = scoreStrategyList.some(group => selectedScoreStrategy.includes(group))
        setChecked(allSelected)
        setIndeterminate(someSelected && !allSelected)
        
        if (checkbox.current) {
            checkbox.current.indeterminate = indeterminate
        }
    }, [scoreStrategyList, selectedScoreStrategy, indeterminate])
    
    const getScoreTypeName = useCallback((scoreType: ScoreType) => {
        switch (scoreType) {
            case ScoreType.STARS:
                return trans.views.dashboard.setting.score_strategy.score_type_stars
            case ScoreType.SATISFACTORY:
                return trans.views.dashboard.setting.score_strategy.score_type_satisfactory
            case ScoreType.EXCELLENT:
                return trans.views.dashboard.setting.score_strategy.score_type_excellent
            default:
                return trans.views.dashboard.setting.score_strategy.score_type_stars
        }
    }, [])
    
    const toggleAll = useCallback(() => {
        const allSelected = scoreStrategyList.every(group => selectedScoreStrategy.includes(group))
        
        if (allSelected) {
            setSelectedScoreStrategy(selectedScoreStrategy.filter(group => !scoreStrategyList.includes(group)))
        } else {
            const newSelections = [...selectedScoreStrategy, ...scoreStrategyList.filter(group => !selectedScoreStrategy.includes(group))]
            setSelectedScoreStrategy(newSelections)
        }
    }, [scoreStrategyList, selectedScoreStrategy, setSelectedScoreStrategy])
    
    const handleOnDragEnd = useCallback(async (result: DropResult) => {
        if (!result.destination || isUpdating) return
        if (result.source.index === result.destination.index) return
        
        const [removed] = priorityList.splice(result.source.index - 1, 1)
        priorityList.splice(result.destination.index - 1, 0, removed)
        
        scoreStrategyList.sort((a, b) => {
            if (a.id === 'default') return -1
            if (b.id === 'default') return 1
            
            const indexA = priorityList.indexOf(a.id)
            const indexB = priorityList.indexOf(b.id)
            
            return indexA - indexB
        })
        
        try {
            const response = await updateScoreStrategyPriority({ priority: priorityList })
            if (response) {
                showToast({ title: trans.views.dashboard.setting.score_strategy.success.priority_update, type: 'success' })
            }
        } catch (e: any) {
            showToast({ title: trans.views.dashboard.setting.score_strategy.error.priority_update, type: 'error' })
        }
    }, [isUpdating, priorityList, scoreStrategyList, showToast, updateScoreStrategyPriority])
    
    const handleDelete = useCallback(async () => {
        if (isDeleting) return
        const strategyIds = selectedScoreStrategy.map(each => each.id)
        
        try {
            await deleteScoreStrategy({ strategyIds })
            setOpenDeleteModal(false)
            setSelectedScoreStrategy([])
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [deleteScoreStrategy, isDeleting, selectedScoreStrategy, showToast])
    
    return (
        <React.Fragment>
            <SlideOver
                open={open}
                onClose={onClose}
                title={trans.components.builder.score_strategy_updater.title}
                confirm={trans.actions.delete}
                disableConfirm={isEmpty(selectedScoreStrategy)}
                handleConfirm={() => setOpenDeleteModal(true)}
                slideWidth='none'
            >
                <Slide.Content>
                    <div className='relative -mx-4 -my-2 sm:-mx-6 lg:-mx-8'>
                        <div className='relative inline-block w-full align-middle py-2 sm:px-2 lg:px-4'>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId='droppable'>
                                    {(provided, snapshot) => (
                                        <div className='min-w-full table-fixed divide-y divide-gray-300 mb-12' {...provided.droppableProps} ref={provided.innerRef}>
                                            <div>
                                                <div className='flex items-center'>
                                                    <div className='w-6 px-2 py-3.5 text-left text-sm font-semibold text-gray-900'/>
                                                    <div className='relative w-14 px-6'>
                                                        <input
                                                            type='checkbox'
                                                            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                                            ref={checkbox}
                                                            checked={checked}
                                                            onChange={toggleAll}
                                                        />
                                                    </div>
                                                    <div className='w-14 pr-3 py-4 text-left text-sm font-semibold text-gray-900'/>
                                                    <div className='w-full grid grid-cols-3'>
                                                        <div className='py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.setting.score_strategy.name}</div>
                                                        <div className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.setting.score_strategy.score_type}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='divide-y divide-gray-200 bg-white'>
                                                {scoreStrategyList?.map((scoreStrategy: ScoreStrategy) => {
                                                    if (scoreStrategy.id === 'default') return (
                                                        <div className='flex items-center' key={scoreStrategy.id}>
                                                            <div className='w-6 px-2'/>
                                                            <div className='relative w-14 px-6'/>
                                                            <div className='w-14 whitespace-nowrap pr-3 py-4 text-sm'>1</div>
                                                            <div className='w-full grid grid-cols-3'>
                                                                <div className='whitespace-nowrap truncate pr-3 py-4 text-sm'>{scoreStrategy.name}</div>
                                                                <div className='whitespace-nowrap truncate px-3 py-4 text-sm text-gray-500'>{getScoreTypeName(scoreStrategy.scoreType)}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {scoreStrategyList?.map((scoreStrategy: ScoreStrategy, index) => {
                                                    if (scoreStrategy.id !== 'default') return (
                                                        <Draggable key={scoreStrategy.id} draggableId={scoreStrategy.id} index={index}>
                                                            {provided => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                     className={classesOf(selectedScoreStrategy.includes(scoreStrategy) ? 'bg-gray-50' : undefined, 'flex items-center')}>
                                                                    <div {...provided.dragHandleProps} className='w-6 px-2'>
                                                                        <Icon icon='ic:outline-drag-indicator' className='w-5 h-5 text-gray-400 cursor-pointer'/>
                                                                    </div>
                                                                    <div className='relative w-14 px-6'>
                                                                        {selectedScoreStrategy.includes(scoreStrategy) && <div className='absolute inset-y-0 left-0 w-0.5 bg-orange-500'/>}
                                                                        <input
                                                                            type='checkbox'
                                                                            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                                                            value={scoreStrategy.id}
                                                                            checked={selectedScoreStrategy.includes(scoreStrategy)}
                                                                            onChange={(e) => setSelectedScoreStrategy(e.target.checked ? [...selectedScoreStrategy, scoreStrategy] : selectedScoreStrategy.filter((each) => each !== scoreStrategy))}
                                                                        />
                                                                    </div>
                                                                    <div className='w-14 break-words pr-3 py-4 text-sm'>{index + 1}</div>
                                                                    <div className='w-full grid grid-cols-3'>
                                                                        <div className='whitespace-nowrap truncate pr-3 py-4 text-sm'>{scoreStrategy.name}</div>
                                                                        <div className='whitespace-nowrap truncate px-3 py-4 text-sm text-gray-500'>{getScoreTypeName(scoreStrategy.scoreType)}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                            </div>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        {isUpdating && <FallbackDashboard className='absolute top-1/2 transform -translate-y-1/2'/>}
                    </div>
                </Slide.Content>
                
                <DestructiveModal
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onConfirm={handleDelete}
                    confirm={trans.actions.delete}
                    title={trans.views.dashboard.setting.score_strategy.delete_modal.title}
                    description={trans.views.dashboard.setting.score_strategy.delete_modal.description}
                    confirmCode={trans.actions.delete}
                    loading={isDeleting}
                />
            </SlideOver>
        </React.Fragment>
    )
}

export default BuilderScoreStrategyUpdater