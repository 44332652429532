import { Listbox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import React from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import { SelectorOption } from './selector-option'


interface DropDownTriggerProps<TValue> {
    selected: SelectorOption<TValue>
}

const SelectorButtonText = <TValue, >({ selected }: DropDownTriggerProps<TValue>) => {
    return (
        <Listbox.Button className={classesOf(
            'relative w-full grid grid-cols-6',
            'border rounded-md cursor-pointer',
            'px-3 py-2.5',
            'text-left',
            palette.selector.trigger.border,
            palette.selector.trigger.background
        )}>
            <span className='block truncate col-span-2'>{selected?.label ?? ''}</span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronUpDownIcon className='w-5 h-5 text-gray-400' aria-hidden='true'/>
            </span>
        </Listbox.Button>
    )
}

export default SelectorButtonText
