import React from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


const SelectorLoaderText = () => {
    return (
        <div className={classesOf(
            'relative w-full flex items-center gap-3',
            'border rounded-md cursor-pointer',
            'px-3 py-2.5',
            'text-sm text-left font-semibold',
            palette.selector.button.border,
            palette.selector.button.background
        )}>
            <div className='w-full flex items-center justify-between gap-x-3'>
                <div className={classesOf(
                    'h-6 w-full rounded-md',
                    'animate-pulse bg-slate-200'
                )}/>
            </div>
        </div>
    )
}

export default SelectorLoaderText
