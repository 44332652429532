import React from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


interface TabItemProps {
    name: string;
    onTabSelect: () => void
    isActive: boolean
}

const TabItem: React.FC<TabItemProps> = ({ name, onTabSelect, isActive }) => {
    return (
        <div
            className={classesOf(
                isActive ? 'border-orange-500' : 'border-transparent hover:border-gray-300',
                isActive ? palette.tab.item.text_current : 'text-gray-500 hover:text-gray-700',
                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                'cursor-pointer',
                'select-none'
            )}
            onClick={onTabSelect}
        >
            {name}
        </div>
    )
}

export default TabItem