import { differenceInSeconds, format, formatDistanceToNow } from 'date-fns'
import { ko } from 'date-fns/locale'


export const differenceFromNow = (timestamp: number, justBefore: string) => {
    const diff = differenceInSeconds(new Date(), timestamp)
    
    if (diff < 60) {
        return justBefore
    } else if (diff < 60 * 60 * 24 * 3) {
        return formatDistanceToNow(timestamp, { addSuffix: true })
    }
    return format(timestamp, 'PPP p', { locale: ko })
}
