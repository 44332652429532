import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import ChitchatDashboardApp from './chitchat-dashboard-app'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

// <React.StrictMode>
// </React.StrictMode>
root.render(
    <ChitchatDashboardApp/>
)
