import { Switch } from '@headlessui/react'
import React from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


interface ToggleProps {
    enabled: boolean
    onChange?: any
    className?: string
}

const Toggle = ({ enabled, onChange, className = '' }: ToggleProps) => {
    return (
        <Switch
            checked={enabled}
            onChange={onChange}
            className={classesOf(
                enabled ? palette.toggle.active_background : palette.toggle.inactive_background,
                'relative w-11 h-6 inline-flex flex-shrink-0',
                'border-2', palette.toggle.border,
                'rounded-full',
                'transition-colors duration-200 ease-in-out',
                'focus:outline-none focus:ring-2 focus:ring-offset-2', palette.toggle.focus_ring,
                'cursor-pointer',
                className
            )}
        >
            <span
                aria-hidden='true'
                className={classesOf(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'inline-block h-5 w-5',
                    'bg-white shadow ring-0',
                    'rounded-full',
                    'transform transition duration-200 ease-in-out',
                    'pointer-events-none'
                )}
            />
        </Switch>
    )
}

export default Toggle