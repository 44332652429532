import { ChevronRightIcon } from '@heroicons/react/20/solid'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { BuilderGroupUpdater } from '../../../../components/builder/score-strategy'
import { ButtonText } from '../../../../components/button'
import { ExplorerEditorAccuracyCut, ExplorerEditorAssistantCut, ExplorerEditorFluencyCut, ExplorerEditorLevelCut, ExplorerEditorMissionCut, ExplorerEditorScoreCutArray, ExplorerEditorScoreType } from '../../../../components/explorer/editor/explorer-editor-score-strategy'
import ExplorerHeader from '../../../../components/explorer/explorer-header'
import ExplorerTextField from '../../../../components/explorer/explorer-text-field'
import { FallbackDashboard } from '../../../../components/fallback'
import { useScoreStrategyListQuery } from '../../../../hooks/use-score-strategy'
import { useActiveStudyGroup } from '../../../../hooks/use-study-group'
import { ScoreType } from '../../../../libs/model/score-strategy'
import { classesOf } from '../../../../libs/utils/classes-of'
import { trans } from '../../../../locales/ko'


const ViewScoreStrategyDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    
    const [openGroupUpdater, setOpenGroupUpdater] = useState(false)
    
    const { subGroups } = useActiveStudyGroup()
    const { data: scoreStrategyList, isLoading } = useScoreStrategyListQuery()
    const scoreStrategy = useMemo(() => scoreStrategyList?.strategies?.find(each => each.id === id), [id, scoreStrategyList?.strategies])
    
    const scoreTypeName = useMemo(() => {
        switch (scoreStrategy?.scoreType) {
            case ScoreType.STARS:
                return trans.views.dashboard.setting.score_strategy.score_type_stars
            case ScoreType.SATISFACTORY:
                return trans.views.dashboard.setting.score_strategy.score_type_satisfactory
            case ScoreType.EXCELLENT:
                return trans.views.dashboard.setting.score_strategy.score_type_excellent
            default:
                return trans.views.dashboard.setting.score_strategy.score_type_stars
        }
    }, [scoreStrategy?.scoreType])
    
    const TotalView = useMemo(() => {
        switch (scoreStrategy?.scoreType) {
            case ScoreType.STARS:
                return (
                    <div className='space-y-2'>
                        <p>별점 5점 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[0]}점</span> 이상</p>
                        <p>별점 4점 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[1]}점</span> 이상</p>
                        <p>별점 3점 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[2]}점</span> 이상</p>
                        <p>별점 2점 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[3]}점</span> 이상</p>
                        <p>별점 1점 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[4]}점</span> 이상</p>
                    </div>
                )
            case ScoreType.SATISFACTORY:
                return <p>Satisfactory 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[0]}점</span> 이상</p>
            case ScoreType.EXCELLENT:
                return (
                    <div className='space-y-2'>
                        <p>Excellent 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[0]}점</span> 이상</p>
                        <p>Very good 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[1]}점</span> 이상</p>
                        <p>Good 기준 <span className='text-orange-500 font-semibold'>{scoreStrategy.scoreCutArray[2]}점</span> 이상</p>
                    </div>
                )
            default:
                return null
        }
    }, [scoreStrategy?.scoreCutArray, scoreStrategy?.scoreType])
    
    if (isLoading) return <FallbackDashboard/>
    if (!scoreStrategy) return null
    return (
        <React.Fragment>
            <div className='space-y-6 px-4 sm:px-6 lg:px-8'>
                <div className='w-full sm:w-3/4 text-2xl font-semibold flex items-center gap-2'>
                    <p className='shrink-0 hover:font-bold cursor-pointer' onClick={() => navigate('/setting')}>{trans.views.dashboard.setting_detail.it}</p>
                    <ChevronRightIcon className='shrink-0 w-7 h-auto'/>
                    <p className='truncate'>{scoreStrategy?.name}</p>
                </div>
                
                <div className={classesOf('relative', 'p-4 sm:p-0')}>
                    <div className='w-full sm:w-3/4'>
                        <ExplorerHeader title={trans.views.dashboard.setting_detail.score_type}>
                            <ExplorerTextField label={trans.views.dashboard.setting_detail.score_type_method} content={scoreTypeName}
                                               editor={(close) => <ExplorerEditorScoreType scoreStrategy={scoreStrategy} close={close}/>}
                            />
                        </ExplorerHeader>
                        
                        <ExplorerHeader title={trans.views.dashboard.setting_detail.grading_items}>
                            <ExplorerTextField label={trans.views.dashboard.setting_detail.accuracy}
                                               content={<p>정확한 문장 비율 <span className='text-orange-500 font-semibold'>{scoreStrategy.accuracyCut}%</span> 이상, 만점 20점</p>}
                                               editor={(close) => <ExplorerEditorAccuracyCut scoreStrategy={scoreStrategy} close={close}/>}/>
                            <ExplorerTextField label={trans.views.dashboard.setting_detail.fluency}
                                               content={<p>중복 제외한 분당 단어 개수 <span className='text-orange-500 font-semibold'>{scoreStrategy.fluencyCut}개</span> 이상, 만점 20점</p>}
                                               editor={(close) => <ExplorerEditorFluencyCut scoreStrategy={scoreStrategy} close={close}/>}/>
                            <ExplorerTextField label={trans.views.dashboard.setting_detail.mission}
                                               content={<p>미션 완료율 <span className='text-orange-500 font-semibold'>{scoreStrategy.missionCut}%</span> 이상, 만점 20점</p>}
                                               editor={(close) => <ExplorerEditorMissionCut scoreStrategy={scoreStrategy} close={close}/>}/>
                            <ExplorerTextField label={trans.views.dashboard.setting_detail.assistant}
                                               content={<p>학습 도움 기능 사용 <span className='text-orange-500 font-semibold'>{scoreStrategy.assistantCut}개</span> 이하, 만점 20점</p>}
                                               editor={(close) => <ExplorerEditorAssistantCut scoreStrategy={scoreStrategy} close={close}/>}/>
                            <ExplorerTextField label={trans.views.dashboard.setting_detail.level}
                                               content={<p>AI 레벨 <span className='text-orange-500 font-semibold'>{scoreStrategy.levelCut}</span> 이상, 만점 20점</p>}
                                               editor={(close) => <ExplorerEditorLevelCut scoreStrategy={scoreStrategy} close={close}/>}/>
                        </ExplorerHeader>
                        
                        {id === 'default' ? null :
                         <ExplorerHeader title={trans.views.dashboard.setting_detail.group}>
                             <div className='flex items-center justify-between py-3 gap-3'>
                                 <span className='break-all'>{subGroups.filter(each => scoreStrategy.subGroupIds?.includes(each.id)).map(each => each.name).join(', ')}</span>
                                 <ButtonText onClick={() => setOpenGroupUpdater(true)}>{trans.views.dashboard.setting_detail.group_edit}</ButtonText>
                             </div>
                         </ExplorerHeader>
                        }
                        
                        <ExplorerHeader title={trans.views.dashboard.setting_detail.total}>
                            <ExplorerTextField label={trans.views.dashboard.setting_detail.total_method}
                                               content={TotalView}
                                               editor={(close) => <ExplorerEditorScoreCutArray scoreStrategy={scoreStrategy} close={close}/>}/>
                        </ExplorerHeader>
                    </div>
                </div>
            </div>
            
            <BuilderGroupUpdater open={openGroupUpdater} onClose={() => setOpenGroupUpdater(false)} scoreStrategy={scoreStrategy}/>
        </React.Fragment>
    )
}

export default ViewScoreStrategyDetail