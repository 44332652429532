import { ErrorMessage, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import React, { useCallback, useMemo } from 'react'
import * as Yup from 'yup'

import { useUpdateScoreStrategyMutation } from '../../../hooks/use-score-strategy'
import { useToast } from '../../../hooks/use-toast'
import { ScoreStrategy, ScoreType } from '../../../libs/model/score-strategy'
import { palette } from '../../../libs/palette'
import { classesOf } from '../../../libs/utils/classes-of'
import { trans } from '../../../locales/ko'
import { ButtonSolid, ButtonText } from '../../button'
import { FieldNumberSchemeInlined, FieldRadio, FieldSelector } from '../../field'
import FieldInlineText from '../../field/field-inline-text'
import { PopoverClose } from '../../popover'
import { RadioOptionButton } from '../../radio'


export interface ExplorerEditor<TValue> {
    close: PopoverClose
    afterSubmit?: (value: TValue | null) => void
}

interface IFormUpdateScoreType {
    scoreType: ScoreType
}

export const ExplorerEditorScoreType = ({ close, afterSubmit, scoreStrategy }: ExplorerEditor<ScoreStrategy> & { scoreStrategy: ScoreStrategy }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateScoreStrategy, isPending } = useUpdateScoreStrategyMutation()
    
    const initialValues: IFormUpdateScoreType = useMemo(() => {
        return { scoreType: scoreStrategy.scoreType }
    }, [scoreStrategy.scoreType])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            scoreType: Yup.number()
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateScoreType) => {
        if (isPending) return
        
        const scoreCutArray = () => {
            switch (values.scoreType) {
                case ScoreType.STARS:
                    return [80, 70, 60, 50, 40]
                case ScoreType.SATISFACTORY:
                    return [80]
                case ScoreType.EXCELLENT:
                    return [80, 70, 60]
                default:
                    return []
            }
        }
        
        try {
            const response = await updateScoreStrategy({ strategyId: scoreStrategy.id, strategy: { ...scoreStrategy, ...values, scoreCutArray: scoreCutArray() } })
            afterSubmit?.call(this, response)
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [afterSubmit, close, isPending, scoreStrategy, showToast, updateScoreStrategy])
    
    const scoreTypeOptions = useMemo(() => [
        { value: ScoreType.STARS, label: trans.components.builder.score_strategy.score_type_stars },
        { value: ScoreType.SATISFACTORY, label: trans.components.builder.score_strategy.score_type_satisfactory },
        { value: ScoreType.EXCELLENT, label: trans.components.builder.score_strategy.score_type_excellent }
    ], [])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='scoreType' className='block text-gray-500 ml-1'>{trans.components.builder.score_strategy.score_type}</label>
                        <FieldRadio
                            name='scoreType'
                            label={trans.components.builder.score_strategy.score_type}
                            options={scoreTypeOptions}
                            as={RadioOptionButton}
                            className='space-y-4 mt-5 ml-1'
                        />
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                        </div>
                    
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateAccuracyCut {
    accuracyCut: string
}

export const ExplorerEditorAccuracyCut = ({ close, afterSubmit, scoreStrategy }: ExplorerEditor<ScoreStrategy> & { scoreStrategy: ScoreStrategy }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateScoreStrategy, isPending } = useUpdateScoreStrategyMutation()
    
    const initialValues: IFormUpdateAccuracyCut = useMemo(() => {
        return { accuracyCut: String(scoreStrategy.accuracyCut) }
    }, [scoreStrategy.accuracyCut])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            accuracyCut: Yup.number().integer(trans.components.builder.score_strategy.error.accuracyCut_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.accuracyCut_integer
            }).required(trans.components.builder.score_strategy.error.accuracyCut_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateAccuracyCut) => {
        if (isPending) return
        
        try {
            const response = await updateScoreStrategy({ strategyId: scoreStrategy.id, strategy: { ...scoreStrategy, accuracyCut: parseInt(values.accuracyCut) } })
            afterSubmit?.call(this, response)
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [afterSubmit, close, isPending, scoreStrategy, showToast, updateScoreStrategy])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='accuracyCut' className='block text-gray-500 ml-1'> {trans.components.builder.score_strategy.accuracy}</label>
                        <FieldNumberSchemeInlined
                            id='accuracyCut' name='accuracyCut'
                            scheme=''
                            host={'%'}
                            error={errors.accuracyCut && touched.accuracyCut}
                            placeholder='0'
                        />
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateFluencyCut {
    fluencyCut: string
}

export const ExplorerEditorFluencyCut = ({ close, afterSubmit, scoreStrategy }: ExplorerEditor<ScoreStrategy> & { scoreStrategy: ScoreStrategy }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateScoreStrategy, isPending } = useUpdateScoreStrategyMutation()
    
    const initialValues: IFormUpdateFluencyCut = useMemo(() => {
        return { fluencyCut: String(scoreStrategy.fluencyCut) }
    }, [scoreStrategy.fluencyCut])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            fluencyCut: Yup.number().integer(trans.views.dashboard.setting_detail.error.fluencyCut_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 200
                },
                message: trans.views.dashboard.setting_detail.error.fluencyCut_integer
            }).required(trans.components.builder.score_strategy.error.fluencyCut_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateFluencyCut) => {
        if (isPending) return
        
        try {
            const response = await updateScoreStrategy({ strategyId: scoreStrategy.id, strategy: { ...scoreStrategy, fluencyCut: parseInt(values.fluencyCut) } })
            afterSubmit?.call(this, response)
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [afterSubmit, close, isPending, scoreStrategy, showToast, updateScoreStrategy])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='fluencyCut' className='block text-gray-500 ml-1'>{trans.components.builder.score_strategy.fluency}</label>
                        <FieldNumberSchemeInlined
                            id='fluencyCut' name='fluencyCut'
                            scheme=''
                            host={'개'}
                            error={errors.fluencyCut && touched.fluencyCut}
                            placeholder='0'
                        />
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateMissionCut {
    missionCut: string
}

export const ExplorerEditorMissionCut = ({ close, afterSubmit, scoreStrategy }: ExplorerEditor<ScoreStrategy> & { scoreStrategy: ScoreStrategy }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateScoreStrategy, isPending } = useUpdateScoreStrategyMutation()
    
    const initialValues: IFormUpdateMissionCut = useMemo(() => {
        return { missionCut: String(scoreStrategy.missionCut) }
    }, [scoreStrategy.missionCut])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            missionCut: Yup.number().integer(trans.components.builder.score_strategy.error.missionCut_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.missionCut_integer
            }).required(trans.components.builder.score_strategy.error.missionCut_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateMissionCut) => {
        if (isPending) return
        
        try {
            const response = await updateScoreStrategy({ strategyId: scoreStrategy.id, strategy: { ...scoreStrategy, missionCut: parseInt(values.missionCut) } })
            afterSubmit?.call(this, response)
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [afterSubmit, close, isPending, scoreStrategy, showToast, updateScoreStrategy])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='missionCut' className='block text-gray-500 ml-1'>{trans.components.builder.score_strategy.mission}</label>
                        <FieldNumberSchemeInlined
                            id='missionCut' name='missionCut'
                            scheme=''
                            host={'%'}
                            error={errors.missionCut && touched.missionCut}
                            placeholder='0'
                        />
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateAssistantCut {
    assistantCut: string
}

export const ExplorerEditorAssistantCut = ({ close, afterSubmit, scoreStrategy }: ExplorerEditor<ScoreStrategy> & { scoreStrategy: ScoreStrategy }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateScoreStrategy, isPending } = useUpdateScoreStrategyMutation()
    
    const assistantCutOptions = useMemo(() => ['0', '1', '2', '3', '4'].map(each => {
        return { label: each, value: each }
    }), [])
    
    const initialValues: IFormUpdateAssistantCut = useMemo(() => {
        return { assistantCut: String(scoreStrategy.assistantCut) }
    }, [scoreStrategy.assistantCut])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            assistantCut: Yup.number()
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateAssistantCut) => {
        if (isPending) return
        
        try {
            const response = await updateScoreStrategy({ strategyId: scoreStrategy.id, strategy: { ...scoreStrategy, assistantCut: parseInt(values.assistantCut) } })
            afterSubmit?.call(this, response)
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [afterSubmit, close, isPending, scoreStrategy, showToast, updateScoreStrategy])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='assistantCut' className='block text-gray-500 ml-1'>{trans.components.builder.score_strategy.assistant}</label>
                        <FieldSelector name='assistantCut' options={assistantCutOptions}/>
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateLevelCut {
    levelCut: string
}

export const ExplorerEditorLevelCut = ({ close, afterSubmit, scoreStrategy }: ExplorerEditor<ScoreStrategy> & { scoreStrategy: ScoreStrategy }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateScoreStrategy, isPending } = useUpdateScoreStrategyMutation()
    
    const levelCutOptions = useMemo(() => ['1', '2', '3', '4', '5', '6'].map(each => {
        return { label: each, value: each }
    }), [])
    
    const initialValues: IFormUpdateLevelCut = useMemo(() => {
        return { levelCut: String(scoreStrategy.levelCut) }
    }, [scoreStrategy.levelCut])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            levelCut: Yup.number()
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateLevelCut) => {
        if (isPending) return
        
        try {
            const response = await updateScoreStrategy({ strategyId: scoreStrategy.id, strategy: { ...scoreStrategy, levelCut: parseInt(values.levelCut) } })
            afterSubmit?.call(this, response)
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [afterSubmit, close, isPending, scoreStrategy, showToast, updateScoreStrategy])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='levelCut' className='block text-gray-500 ml-1'>{trans.components.builder.score_strategy.level}</label>
                        <FieldSelector name='levelCut' options={levelCutOptions}/>
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateScoreCutArray {
    scoreCut_first: string
    scoreCut_second: string
    scoreCut_third: string
    scoreCut_fourth: string
    scoreCut_fifth: string
}

export const ExplorerEditorScoreCutArray = ({ close, afterSubmit, scoreStrategy }: ExplorerEditor<ScoreStrategy> & { scoreStrategy: ScoreStrategy }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateScoreStrategy, isPending } = useUpdateScoreStrategyMutation()
    
    const initialValues: IFormUpdateScoreCutArray = useMemo(() => {
        return {
            scoreCut_first: scoreStrategy.scoreCutArray[0] ? String(scoreStrategy.scoreCutArray[0]) : '0',
            scoreCut_second: scoreStrategy.scoreCutArray[1] ? String(scoreStrategy.scoreCutArray[1]) : '0',
            scoreCut_third: scoreStrategy.scoreCutArray[2] ? String(scoreStrategy.scoreCutArray[2]) : '0',
            scoreCut_fourth: scoreStrategy.scoreCutArray[3] ? String(scoreStrategy.scoreCutArray[3]) : '0',
            scoreCut_fifth: scoreStrategy.scoreCutArray[4] ? String(scoreStrategy.scoreCutArray[4]) : '0'
        }
    }, [scoreStrategy.scoreCutArray])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            scoreCut_first: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer,
                test: (value: any, context) => {
                    return value >= 0 && value <= 100
                }
            }).test({
                message: trans.components.builder.score_strategy.error.scoreCut_descending_error,
                test: (value: any, context) => {
                    return context.parent.scoreCut_second <= value
                }
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required),
            scoreCut_second: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer
            }).test({
                message: trans.components.builder.score_strategy.error.scoreCut_descending_error,
                test: (value: any, context) => {
                    return context.parent.scoreCut_third <= value
                }
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required),
            scoreCut_third: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer
            }).test({
                message: trans.components.builder.score_strategy.error.scoreCut_descending_error,
                test: (value: any, context) => {
                    return context.parent.scoreCut_fourth <= value
                }
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required),
            scoreCut_fourth: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer
            }).test({
                message: trans.components.builder.score_strategy.error.scoreCut_descending_error,
                test: (value: any, context) => {
                    return context.parent.scoreCut_fifth <= value
                }
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required),
            scoreCut_fifth: Yup.number().integer(trans.components.builder.score_strategy.error.scoreCutArray_integer).test({
                test: (value: any) => {
                    return value >= 0 && value <= 100
                },
                message: trans.components.builder.score_strategy.error.scoreCutArray_integer
            }).required(trans.components.builder.score_strategy.error.scoreCutArray_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateScoreCutArray) => {
        if (isPending) return
        
        const scoreCutArray = () => {
            switch (scoreStrategy.scoreType) {
                case ScoreType.STARS:
                    return [parseInt(values.scoreCut_first), parseInt(values.scoreCut_second), parseInt(values.scoreCut_third), parseInt(values.scoreCut_fourth), parseInt(values.scoreCut_fifth)]
                case ScoreType.SATISFACTORY:
                    return [parseInt(values.scoreCut_first)]
                case ScoreType.EXCELLENT:
                    return [parseInt(values.scoreCut_first), parseInt(values.scoreCut_second), parseInt(values.scoreCut_third)]
                default:
                    return []
            }
        }
        
        try {
            const response = await updateScoreStrategy({ strategyId: scoreStrategy.id, strategy: { ...scoreStrategy, scoreCutArray: scoreCutArray() } })
            afterSubmit?.call(this, response)
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [afterSubmit, close, isPending, scoreStrategy, showToast, updateScoreStrategy])
    
    const getTotalView = useCallback(({ scoreType, errors, touched }: { scoreType: ScoreType, errors: FormikErrors<IFormUpdateScoreCutArray>, touched: FormikTouched<IFormUpdateScoreCutArray> }) => {
        switch (scoreType) {
            case ScoreType.STARS:
                return (
                    <React.Fragment>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 5점 기준</span>
                            <FieldInlineText
                                id='scoreCut_first' name='scoreCut_first'
                                error={errors.scoreCut_first && touched.scoreCut_first}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_first' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 4점 기준</span>
                            <FieldInlineText
                                id='scoreCut_second' name='scoreCut_second'
                                error={errors.scoreCut_second && touched.scoreCut_second}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_second' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 3점 기준</span>
                            <FieldInlineText
                                id='scoreCut_third' name='scoreCut_third'
                                error={errors.scoreCut_third && touched.scoreCut_third}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_third' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 2점 기준</span>
                            <FieldInlineText
                                id='scoreCut_fourth' name='scoreCut_fourth'
                                error={errors.scoreCut_fourth && touched.scoreCut_fourth}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_fourth' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>별점 1점 기준</span>
                            <FieldInlineText
                                id='scoreCut_fifth' name='scoreCut_fifth'
                                error={errors.scoreCut_fifth && touched.scoreCut_fifth}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_fifth' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                    </React.Fragment>
                )
            case ScoreType.SATISFACTORY:
                return (
                    <React.Fragment>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>Satisfactory 기준</span>
                            <FieldInlineText
                                id='scoreCut_first' name='scoreCut_first'
                                error={errors.scoreCut_first && touched.scoreCut_first}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_first' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                    </React.Fragment>
                )
            case ScoreType.EXCELLENT:
                return (
                    <React.Fragment>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>Excellent 기준</span>
                            <FieldInlineText
                                id='scoreCut_first' name='scoreCut_first'
                                error={errors.scoreCut_first && touched.scoreCut_first}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_first' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>Very good 기준</span>
                            <FieldInlineText
                                id='scoreCut_second' name='scoreCut_second'
                                error={errors.scoreCut_second && touched.scoreCut_second}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_second' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                        <div className='flex items-center text-sm leading-7 text-gray-900 mb-1'>
                            <span>Good 기준</span>
                            <FieldInlineText
                                id='scoreCut_third' name='scoreCut_third'
                                error={errors.scoreCut_third && touched.scoreCut_third}
                                placeholder='0'
                                containerClassName='max-w-[50px]'
                            />
                            <span>점 이상</span>
                        </div>
                        <ErrorMessage name='scoreCut_third' component='p' className={classesOf('my-2 text-sm text-left', palette.field.text_error)}/>
                    </React.Fragment>
                )
            default:
                return null
        }
    }, [])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => {
                return (
                    <Form>
                        <div className='space-y-2'>
                            <label htmlFor='scoreCutArray' className='block text-gray-500 ml-1'>{trans.views.dashboard.setting_detail.total}</label>
                            {getTotalView({ scoreType: scoreStrategy.scoreType, errors, touched })}
                            <div className='flex items-center justify-end space-x-4'>
                                <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                                <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}