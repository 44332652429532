import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'

import { deleteAllUsers, deleteUsers, getUser, getUsersOfGroup, updateUser } from '../libs/firestore/firestore-helper'
import { Account } from '../libs/model/account'
import { useActiveStudyGroup } from './use-study-group'


export const useUserListQuery = (currentPage: number, countPerPage : number) => {
    const { studyGroupId } = useActiveStudyGroup()
    
    return useQuery({
        queryKey: ['users', studyGroupId, currentPage, countPerPage],
        queryFn: () => {
            return getUsersOfGroup({ studyGroupId: studyGroupId, page: currentPage, limit:countPerPage})
        },
        enabled: !isEmpty(studyGroupId)
    })
}

export const useUserQuery = (userId?: string) => {
    return useQuery({
        queryKey: ['user', userId],
        queryFn: () => {
            return getUser(userId)
        },
        enabled: !isEmpty(userId)
    })
}

export const useUserQueries = (userIds: string[]) => {
    return useQueries({
        queries: userIds?.map((id) => {
            return {
                queryKey: ['user', id],
                queryFn: () => getUser(id)
            }
        }),
        combine: (results) => {
            return ({
                data: results.map(result => result.data).filter(each => !!each),
                isLoading: results.some(result => result.isLoading)
            }) as {
                data: Account[],
                isLoading: boolean
            }
        }
    })
}

export const useUpdateUserMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ userId, ...params }: Partial<Account> & { userId: string }) => updateUser({ userId, params }),
        onSuccess: (data, variables, context) => {
            
            queryClient.setQueryData(['user', variables.userId], data)
            queryClient.invalidateQueries({ queryKey: ['users', studyGroupId] })
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
        }
    })
}

export const useDeleteUsersMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ userIds }: { userIds: string[] }) => deleteUsers({ studyGroupId: studyGroupId, userIds }),
        onSuccess: (data, variables) => {
            if (!data) return
            
            for (const userId of variables.userIds) {
                queryClient.resetQueries({ queryKey: ['user', userId] })
            }
            queryClient.invalidateQueries({ queryKey: ['users', studyGroupId] })
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
        }
    })
}

export const useDeleteAllUsersMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: () => deleteAllUsers({ studyGroupId }),
        onSuccess: (data, variables) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['users', studyGroupId] })
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
        }
    })
}