import React, { useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'

import HeaderDashboard from '../../components/header/header-dashboard'
import { Section, Sidebar } from '../../components/sidebar'
import SidebarMobile from '../../components/sidebar/sidebar-mobile'
import { useActiveAccount } from '../../hooks/use-account'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'


const LayoutDashboard: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { isAdmin } = useActiveAccount()
    
    const sections: Section[] = useMemo(() => {
        return [
            { category: trans.components.sidebar.items.admin.overview, to: '/dashboard' },
            { category: trans.components.sidebar.items.admin.group, to: '/group' },
            { category: trans.components.sidebar.items.admin.member, to: '/member' },
            { category: trans.components.sidebar.items.admin.contents, to: '/content' },
            { category: trans.components.sidebar.items.admin.settings, to: '/setting' }
        ]
    }, [])
    
    return (
        <React.Fragment>
            {isAdmin
             ? <React.Fragment>
                 <Sidebar sections={sections}/>
                 <SidebarMobile sections={sections} open={sidebarOpen} onClose={() => setSidebarOpen(false)}/>
             </React.Fragment> :
             null
            }
            
            {isAdmin ?
             <div className='lg:pl-72'>
                 <HeaderDashboard/>
                 <div className={classesOf('mx-auto pt-10 max-w-7xl')}>
                     <Outlet/>
                 </div>
             </div> :
             <div>
                 <HeaderDashboard/>
                 <div className={classesOf('mx-auto pt-10')}>
                     <Outlet/>
                 </div>
             </div>
            }
        </React.Fragment>
    )
}

export default LayoutDashboard
