import React from 'react'

interface EmptyStateTableProps {
    content: string
}

const EmptyStateTable = ({content}: EmptyStateTableProps) => {
    return (
        <div className='grid w-full h-[500px]'>
            <div className='flex flex-col items-center justify-center gap-4'>
                <img src='/assets/empty_state.png' className='h-24'/>
                <p className='text-gray-300 text-xl'>{content}</p>
            </div>
        </div>
    )
}

export default EmptyStateTable