import { Form, Formik } from 'formik'
import React, { useCallback, useMemo } from 'react'
import * as Yup from 'yup'

import { useCheckValidNameMutation, useUpdateCustomPromptMutation } from '../../../hooks/use-content'
import { useToast } from '../../../hooks/use-toast'
import { CustomPrompt } from '../../../libs/model/content'
import { trans } from '../../../locales/ko'
import { ButtonSolid, ButtonText } from '../../button'
import { FieldText } from '../../field'
import { PopoverClose } from '../../popover'


export interface ExplorerEditor<TValue> {
    close: PopoverClose
    afterSubmit?: (value: TValue | null) => void
}

interface IFormUpdateContentName {
    name: string
}

export const ExplorerEditorContentName = ({ close, afterSubmit, content, categoryName }: ExplorerEditor<CustomPrompt> & { content: CustomPrompt, categoryName: string }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateCustomPrompt, isPending } = useUpdateCustomPromptMutation()
    const { mutateAsync: checkValidName, isPending: isChecking } = useCheckValidNameMutation()
    
    const initialValues: IFormUpdateContentName = useMemo(() => {
        return { name: content.name }
    }, [content.name])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            name: Yup.string().required(trans.views.dashboard.content_detail.error.name_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateContentName) => {
        if (isPending || isChecking || !categoryName || !content) return
        const response = await checkValidName({ name: values.name })
        if (response === false) return showToast({ title: trans.components.builder.content.error.name_duplicate, type: 'error' })
        
        try {
            await updateCustomPrompt({ promptId: content.id, promptUpdateRequest: { name: values.name, categoryName, studyGroupId: content.studyGroupId, subGroupIds: content.subGroupIds } })
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [categoryName, checkValidName, close, content, isChecking, isPending, showToast, updateCustomPrompt])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='name' className='block text-gray-500 ml-1'>{trans.views.dashboard.content_detail.name}</label>
                        <FieldText
                            id='name' name='name'
                            error={errors.name && touched.name}
                            placeholder={trans.views.dashboard.content_detail.name}
                        />
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending || isChecking}>{trans.actions.update}</ButtonSolid>
                        </div>
                    
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateCategoryName {
    categoryName: string
}

export const ExplorerEditorCategoryName = ({ close, afterSubmit, content, categoryName }: ExplorerEditor<CustomPrompt> & { content: CustomPrompt, categoryName: string }) => {
    const { showToast } = useToast()
    const { mutateAsync: updateCustomPrompt, isPending } = useUpdateCustomPromptMutation()
    
    const initialValues: IFormUpdateCategoryName = useMemo(() => {
        return { categoryName: categoryName }
    }, [categoryName])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            categoryName: Yup.string().required(trans.views.dashboard.content_detail.error.category_name_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateCategoryName) => {
        if (isPending || !categoryName || !content) return
        
        try {
            await updateCustomPrompt({ promptId: content.id, promptUpdateRequest: { name: content.name, categoryName: values.categoryName, studyGroupId: content.studyGroupId, subGroupIds: content.subGroupIds } })
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [categoryName, close, content, isPending, showToast, updateCustomPrompt])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='categoryName' className='block text-gray-500 ml-1'>{trans.views.dashboard.content_detail.category}</label>
                        <FieldText
                            id='categoryName' name='categoryName'
                            error={errors.categoryName && touched.categoryName}
                            placeholder={trans.views.dashboard.content_detail.category}
                        />
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                        </div>
                    
                    </div>
                </Form>
            )}
        </Formik>
    )
}