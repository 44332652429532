import { signInWithCustomToken } from 'firebase/auth'
import React, { useEffect } from 'react'

import LogoChitChat from '../../components/logo/logo-chitchat'
import LogoChitChatText from '../../components/logo/logo-chitchat-text'
import { firebaseAuth } from '../../contexts/firebase/firebase-provider'
import { useToast } from '../../hooks/use-toast'
import { requestKakaoSignIn } from '../../libs/auth/firebase-auth-helper'
import { classesOf } from '../../libs/utils/classes-of'


const ViewKakaoAuth: React.FC = () => {
    const { showToast } = useToast()
    
    useEffect(() => {
        try {
            const code = new URL(window.location.href).searchParams.get('code')
            const redirectUri = `${location.origin}/kakao-auth`
            
            requestKakaoSignIn(code ?? '', redirectUri).then(value => {
                if (value != null) {
                    signInWithCustomToken(firebaseAuth, value).then(cred => {
                        console.log(`signInWithCustomToken:: uid=${cred.user.uid}`)
                    })
                }
            })
            
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [showToast])
    
    return (
        <div className={classesOf(
            'w-screen h-screen',
            'flex flex-col items-center justify-center',
            'overflow-hidden',
            'select-none'
        )}>
            <LogoChitChat className="h-8 w-auto animate-bounce"/>
            <LogoChitChatText className="mt-2 w-auto h-6"/>
        </div>
    )
}

export default ViewKakaoAuth
