import { useField, useFormikContext } from 'formik'
import React, { useCallback } from 'react'

import { Radio } from '../radio'
import { RadioOption } from '../radio/radio-option'


interface FieldRadioProps<TValue> {
    name: string
    label: string
    options: RadioOption<TValue>[]
    as?: React.ElementType
    groupClassName?: string
    className?: string
    onChange?: (value: TValue) => void
}

const FieldRadio = <TValue, >({ name, label, options, ...props }: FieldRadioProps<TValue>) => {
    const [field] = useField<TValue>(name)
    const { setFieldValue } = useFormikContext()
    
    const handleChange = useCallback(async (value: TValue) => {
        await setFieldValue(name, value)
    }, [name, setFieldValue])
    
    return (
        <Radio label={label} options={options}
               value={field.value} onChange={props.onChange ? props.onChange : handleChange}
               as={props.as}
               groupClassName={props.groupClassName}
               className={props.className}
        />
    )
}

export default FieldRadio
