import { RadioGroup } from '@headlessui/react'
import React from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import { RadioOption } from './radio-option'


const RadioOptionCard = <TValue, >(option: RadioOption<TValue>) => {
    return (
        <RadioGroup.Option
            value={option.value}
            className={({ active }) =>
                classesOf(
                    'bg-white',
                    active ? palette.radio.option_card.active_border : 'border-gray-300',
                    active ? palette.radio.option_card.active_ring : '',
                    active ? 'ring-2' : '',
                    'relative block sm:flex sm:justify-between cursor-pointer',
                    'rounded-lg border',
                    'px-6 py-4',
                    'shadow-sm',
                    'focus:outline-none'
                )
            }
        >
            {({ active, checked }) => (
                <React.Fragment>
                    <span className='flex items-center'>
                        <span className='flex flex-col text-sm'>
                            <RadioGroup.Label as='span' className='font-medium text-gray-900'>
                                {option.label}
                            </RadioGroup.Label>
                            <RadioGroup.Description as='span' className='text-gray-500'>
                                <span className='block sm:inline'>
                                    {option.description}
                                </span>
                            </RadioGroup.Description>
                        </span>
                    </span>
                    {option.trailing && (
                        <RadioGroup.Description
                            as='span'
                            className='mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right'
                        >
                            {option.trailing}
                        </RadioGroup.Description>
                    )}
                    <span
                        className={classesOf(
                            active ? 'border' : 'border-2',
                            checked ? palette.radio.option_card.active_border : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                        )}
                        aria-hidden='true'
                    />
                </React.Fragment>
            )}
        </RadioGroup.Option>
    )
}

export default RadioOptionCard
