import React from 'react'

import { useActiveAccount } from '../hooks/use-account'
import AdminRoutes from './admin-routes'
import MemberRoutes from './member-routes'


const DashboardRoutes = () => {
    const { isAdmin } = useActiveAccount()
    
    if (isAdmin) return <AdminRoutes/>
    return <MemberRoutes/>
}

export default DashboardRoutes