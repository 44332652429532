import { useQuery } from '@tanstack/react-query'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { checkValidName, createCustomPrompt, deleteCustomPrompt, getCustomPrompts, saveCustomPrompt, updateCustomPrompt } from '../libs/firestore/content-operation'
import { CustomPrompt, MissionExpressions, PromptUpdateRequest } from '../libs/model/content'
import { useActiveStudyGroup } from './use-study-group'


export const useCustomPromptListQuery = () => {
    const { studyGroupId } = useActiveStudyGroup()
    
    return useQuery({
        queryKey: ['content', studyGroupId],
        queryFn: () => {
            return getCustomPrompts({ studyGroupId })
        }
    })
}

export const useSaveCustomPromptMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ promptUpdateRequest, customPrompt }: { promptUpdateRequest: PromptUpdateRequest, customPrompt: CustomPrompt }) => saveCustomPrompt({ promptUpdateRequest, customPrompt }),
        onSuccess: (data, variables) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['content', studyGroupId] })
        }
    })
}

export const useCreateCustomPromptMutation = () => {
    return useMutation({
        mutationFn: (missionExpressions: MissionExpressions[]) => createCustomPrompt(missionExpressions)
    })
}

export const useUpdateCustomPromptMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ promptId, promptUpdateRequest }: { promptId: string, promptUpdateRequest: PromptUpdateRequest }) => updateCustomPrompt({ promptId, promptUpdateRequest }),
        onSuccess: (data, variables) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['content', studyGroupId] })
        }
    })
}

export const useDeleteCustomPromptMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: (promptIds: string[]) => deleteCustomPrompt(promptIds),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['content', studyGroupId] })
        }
    })
}

export const useCheckValidNameMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ name }: { name: string }) => checkValidName({ studyGroupId, name })
    })
}
