import { Account } from '../model/account'
import { ChatContent } from '../model/chat-content'
import { LearningHistoryByUser, LearningHistoryInput, LearningHistoryRowInput } from '../model/learning-history'
import { LearningOptions, StudyGroup } from '../model/study-group'
import { Survey } from '../model/survey'
import { instance } from './instance'


export interface DataConnection<T> {
    result: T[]
    hasNext: boolean
    total: number
}

export const getLearningHistoryOfGroupByMonth = async (studyGroupId: string, monthString: string, gmtDiff?: number, limit?: number): Promise<LearningHistoryByUser | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getLearningHistoryOfGroupByMonth',
        params: { studyGroupId, monthString, gmtDiff, limit }
    })
    return resp.data.result
}

export const getLearningHistoryRows = async ({ bulkData, monthString, gmtDiff }: LearningHistoryRowInput): Promise<any[] | null> => {
    const resp = await instance({
        method: 'POST',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getLearningHistoryRows',
        data: {
            bulkData, monthString, gmtDiff
        }
    })
    return resp.data.result
}

export const getLearningHistoryByMonth = async ({ userIds, monthString, gmtDiff, limit }: LearningHistoryInput): Promise<LearningHistoryByUser | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getLearningHistoryByMonth',
        params: { userIds, monthString, gmtDiff, limit }
    })
    return resp.data.result
}

export const getSurveyOfGroup = async (studyGroupId: string, startTimestamp?: number, endTimestamp?: number, limit?: number): Promise<Survey[] | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getSurveyOfGroup',
        params: { studyGroupId, startTimestamp, endTimestamp, limit }
    })
    return resp.data.result
}

export const getUser = async (userId?: string): Promise<Account | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getUser',
        params: { userId }
    })
    return resp.data.result as Account
}

export const getUsersOfGroup = async ({ studyGroupId, limit = 10, page = 0 }: { studyGroupId: string, limit?: number, page?: number }): Promise<DataConnection<Account> | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getUsersOfGroup',
        params: { studyGroupId, limit, page }
    })
    return resp.data.result
}

export const updateUser = async ({ userId, params }: { userId: string, params: Partial<Account> }) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-updateUser`,
        data: {
            userId, params
        }
    })
    return resp.data.result
}

export const deleteUsers = async ({ studyGroupId, userIds }: { studyGroupId: string, userIds: string[] }) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-deleteUsers`,
        data: {
            studyGroupId, userIds
        }
    })
    return resp.data.result
}

export const deleteAllUsers = async ({ studyGroupId }: { studyGroupId: string }) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-deleteAllUsers`,
        data: {
            studyGroupId
        }
    })
    return resp.data.result
}

// sub group 이름 수정
export const changeSubGroupName = async ({ studyGroupId, subGroupId, name }: { studyGroupId: string, subGroupId: string, name: string }): Promise<StudyGroup | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-changeSubGroupName`,
        data: {
            studyGroupId, subGroupId, name
        }
    })
    return resp.data.result
}

// sub group 학습 설정 수정
export const updateLearningOptions = async ({ studyGroupId, subGroupId, params }: { studyGroupId: string, subGroupId?: string, params: Partial<LearningOptions> }): Promise<StudyGroup | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-updateLearningOptions`,
        data: {
            studyGroupId, subGroupId, params
        }
    })
    return resp.data.result
}

// sub group 멤버 삭제
export const deleteUsersFromSubGroup = async ({ studyGroupId, subGroupId, userIds }: { studyGroupId: string, subGroupId: string, userIds: string[] }): Promise<{ studyGroup: StudyGroup, users: Account[] } | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-deleteUsersFromSubGroup`,
        data: {
            studyGroupId, subGroupId, userIds
        }
    })
    return resp.data.result
}

export const changeSubGroupOfUser = async ({ userId, studyGroupId, targetSubGroupIds }: { userId: string, studyGroupId?: string, targetSubGroupIds: string[] }) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-changeSubGroupOfUser`,
        data: {
            userId, studyGroupId, targetSubGroupIds
        }
    })
    return resp.data.result
}

export const createSubGroup = async (studyGroupId: string, subGroupName: string, memberIds: []) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-createSubGroup`,
        data: {
            studyGroupId, subGroupName, memberIds
        }
    })
    return resp.data.result
}

export const updateSubGroup = async (studyGroupId: string, subGroupName: string, newName?: string, addedMembers?: string[], deletedMembers?: string[]) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-updateSubGroup`,
        data: {
            studyGroupId, subGroupName, newName, addedMembers, deletedMembers
        }
    })
    return resp.data.result
}

export const deleteSubGroups = async (studyGroupId: string, subGroupIds: string[]) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-deleteSubGroups`,
        data: {
            studyGroupId, subGroupIds
        }
    })
    return resp.data.result
}

export const getChatContentsOfLearningHistory = async (learningId?: string): Promise<ChatContent[] | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getChatContentsOfLearningHistory',
        params: { learningId }
    })
    return resp.data.result as ChatContent[]
}

export const createOrUpdateAdminComment = async ({ learningId, comment }: { learningId: string, comment?: string }) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-createOrUpdateAdminComment`,
        data: {
            learningId, comment
        }
    })
    return resp.data.result
}

export const updateApplyOptions = async ({ studyGroupId, options }: {
    studyGroupId: string,
    options: {
        applyLearningOptions?: boolean,
        applyScoreStrategy?: boolean,
        applyGroupContents?: boolean,
    }
}) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-updateApplyOptions`,
        data: {
            studyGroupId, options
        }
    })
    return resp.data.result
}
