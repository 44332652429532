import React, { useCallback, useMemo } from 'react'
import { NavigateOptions } from 'react-router/dist/lib/context'
import { useNavigate } from 'react-router-dom'

import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import LogoChitChat from './logo-chitchat'


interface LogoVerticalProps {
    label?: string
    onClick?: () => void
    href?: string
    to?: string
    navigateOptions?: NavigateOptions
}

const LogoVertical = ({
    label,
    onClick, href, to, navigateOptions
}: LogoVerticalProps) => {
    const navigate = useNavigate()
    
    const shouldHandleClick = useMemo(() => {
        return !!onClick || !!href || !!to
    }, [onClick, href, to])
    
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick()
        } else if (href) {
            window.open(href, '_blank', 'noopener')
        } else if (to) {
            navigate(to, navigateOptions)
        }
    }, [onClick, href, to, navigateOptions, navigate])
    
    return (
        <React.Fragment>
            <LogoChitChat
                className={classesOf(
                    'mx-auto h-10 w-auto',
                    shouldHandleClick ? 'cursor-pointer' : ''
                )}
                onClick={shouldHandleClick ? handleClick : undefined}
            />
            
            <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                {label ?? trans.meta.adrop}
            </h2>
        </React.Fragment>
    )
}

export default LogoVertical
