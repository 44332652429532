import * as Yup from 'yup'
import YupPassword from 'yup-password'

import ViewSignIn from './view-sign-in'


YupPassword(Yup)

export {
    ViewSignIn
}
