import { StudyGroup } from '../model/study-group'
import { instance } from './instance'

// GET 스터디 그룹 데이터
export const getStudyGroup = async (studyGroupId?: string): Promise<StudyGroup | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getStudyGroup',
        params: { studyGroupId }
    })
    return resp.data.result as StudyGroup
}