import React from 'react'


const SkeletonThreadListItem = () => {
    return (
        <React.Fragment>
            <div className={`flex w-full justify-start px-4 sm:px-6 animate-pulse`}>
                <div className={`flex gap-x-4 max-w-[60%] items-start`}>
                    <div className='w-6 h-6 bg-slate-200 rounded-full shrink-0'/>
                    <div className={`w-96 h-28 rounded-md p-3 ring-1 ring-inset bg-slate-200 ring-slate-200`}/>
                </div>
            </div>
            <div className={`flex w-full justify-end px-4 sm:px-6 animate-pulse`}>
                <div className={`flex max-w-[60%] items-start`}>
                    <div className={`rounded-md p-3 ring-1 ring-inset bg-slate-200 ring-slate-200`}>
                        <div className={`w-80 h-28 rounded-md p-3 ring-1 ring-inset bg-slate-200 ring-slate-200`}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SkeletonThreadListItem