import { Form, Formik, FormikProps } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useRef } from 'react'
import * as Yup from 'yup'

import { useChangeSubGroupOfUserMutation } from '../../../hooks/use-group'
import { useToast } from '../../../hooks/use-toast'
import { SubGroup } from '../../../libs/model/study-group'
import { trans } from '../../../locales/ko'
import CheckboxOptionText from '../../checkbox/checkbox-option-text'
import EmptyStateTable from '../../empty-state/empty-state-table'
import { FieldCheckbox } from '../../field'
import { Slide, SlideOver } from '../../slide'


interface IFormBuilderGroupUpdater {
    targetSubGroupIds: string[]
}

interface BuilderGroupUpdaterProps {
    open: boolean
    onClose: () => void
    studyGroups: SubGroup[]
    userId: string
}

const BuilderGroupUpdater = ({ open, onClose, studyGroups, userId }: BuilderGroupUpdaterProps) => {
    const formRef = useRef<FormikProps<IFormBuilderGroupUpdater> | null>(null)
    const { showToast } = useToast()
    
    const studyGroupOptions = useMemo(() => {
        return studyGroups?.map(each => {
            return { value: each.id, id: each.id, label: each.name }
        })
    }, [studyGroups])
    const { mutateAsync: changeSubGroupOfUser, isPending } = useChangeSubGroupOfUserMutation()
    
    const initialValues: IFormBuilderGroupUpdater = { targetSubGroupIds: studyGroups?.filter(sub => sub.members.includes(userId)).map(each => each.id) ?? [] }
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            targetSubGroupIds: Yup.array()
        })
    }, [])
    const handleConfirm = useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }, [])
    
    const onSubmit = useCallback(async (values: IFormBuilderGroupUpdater) => {
        if (!userId || isPending) return
        try {
            await changeSubGroupOfUser({ userId: userId, targetSubGroupIds: values.targetSubGroupIds })
            onClose()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [changeSubGroupOfUser, isPending, onClose, showToast, userId])
    
    return (
        <SlideOver
            open={open}
            onClose={onClose}
            title={trans.views.dashboard.member_details.group.edit_title}
            confirm={trans.actions.save}
            handleConfirm={handleConfirm}
            disableConfirm={isEmpty(studyGroupOptions)}
            isLoading={isPending}
        >
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={formRef}>
                {({ errors, touched, values, dirty }) => {
                    return (
                        <Slide.Content>
                            <Form>
                                {isEmpty(studyGroupOptions) ? <EmptyStateTable content='그룹이 없습니다.'/> :
                                 <FieldCheckbox name='targetSubGroupIds' options={studyGroupOptions} as={CheckboxOptionText} className='space-y-3'/>}
                            </Form>
                        </Slide.Content>
                    )
                }}
            </Formik>
        </SlideOver>
    )
}

export default BuilderGroupUpdater