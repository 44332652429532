import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


export interface TabItemProps {
    name: string
    to: string
}

const TabItem = ({
    name, to
}: TabItemProps) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    
    const current = useMemo(() => {
        return pathname === to
    }, [pathname, to])
    
    const handleClick = useCallback(() => {
        navigate(to)
    }, [navigate, to])
    
    return (
        <div className={classesOf(
            current ? 'border-orange-500' : 'border-transparent hover:border-gray-300',
            current ? palette.tab.item.text_current : 'text-gray-500 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
            'cursor-pointer',
            'select-none'
        )} onClick={handleClick}>
            {name}
        </div>
    )
}

export default TabItem
