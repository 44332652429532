import { CustomPrompt, MissionExpressions, PromptCategory, PromptUpdateRequest } from '../model/content'
import { instance } from './instance'


export const getCustomPrompts = async ({ studyGroupId }: { studyGroupId: string, }): Promise<{ result: CustomPrompt[], hasNext: boolean, total: number, categories: PromptCategory[] } | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getCustomPrompts',
        params: { studyGroupId }
    })
    
    return resp.data.result
}

export const saveCustomPrompt = async ({ promptUpdateRequest, customPrompt }: { promptUpdateRequest: PromptUpdateRequest, customPrompt: CustomPrompt }): Promise<{ customPrompt: CustomPrompt, categories: PromptCategory[] } | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-saveCustomPrompt`,
        data: {
            promptUpdateRequest, customPrompt
        }
    })
    return resp.data.result
}

export const checkValidName = async ({ studyGroupId, name }: { studyGroupId: string, name: string }): Promise<Boolean | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-checkValidName`,
        params: {
            studyGroupId, name
        }
    })
    return resp.data.result
}

export const createCustomPrompt = async (missionExpressions: MissionExpressions[]): Promise<CustomPrompt | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-createCustomPrompt`,
        data: {
            missionExpressions
        }
    })
    return resp.data.result
}

export const updateCustomPrompt = async ({ promptId, promptUpdateRequest }: { promptId: string, promptUpdateRequest: PromptUpdateRequest }): Promise<CustomPrompt | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-updateCustomPrompt`,
        data: {
            promptId, promptUpdateRequest
        }
    })
    return resp.data.result
}

export const deleteCustomPrompt = async (promptIds: string[]): Promise<Boolean | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-deleteCustomPrompt`,
        data: {
            promptIds
        }
    })
    return resp.data.result
}