import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import React, { Fragment } from 'react'

import useSession from '../../hooks/use-session'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'


const AccountPopover = () => {
    const { signOut } = useSession()
    const { activeUser } = useSession()
    
    if (!activeUser) return (
        <div className='animate-pulse flex items-center'>
            <div className='rounded-full bg-slate-200 h-7 w-7'/>
            <div className='ml-2 w-20 h-6 bg-slate-200 rounded'/>
        </div>
    )
    
    return (
        <Menu as='div' className='relative'>
            <Menu.Button className={classesOf(
                'flex items-center justify-center rounded-md',
                'text-sm font-semibold leading-6',
                'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                'py-1.5'
            )}>
                <span className='h-7 w-7 rounded-full bg-gray-200 flex items-center justify-center text-xs'>{activeUser?.nickName?.slice(0, 2)}</span>
                <span className='hidden lg:flex lg:items-center'>
                    <span className='ml-2' aria-hidden='true'>{activeUser?.nickName}</span>
                    <ChevronDownIcon className='ml-1 w-auto h-4 text-gray-600' aria-hidden='true'/>
                </span>
            </Menu.Button>
            
            <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
            >
                <Menu.Items className={classesOf(
                    'mt-2',
                    'flex flex-col gap-2 py-2',
                    'absolute right-0 z-40',
                    'rounded-md',
                    'bg-white',
                    'shadow-lg',
                    'ring-1 ring-gray-900/5',
                    'focus:outline-none',
                    'overflow-hidden overflow-y-auto',
                    'divide-y divide-gray-200',
                    'min-w-[100px]'
                )}>
                    <Menu.Item>
                        <div className='text-sm leading-6 text-gray-900 font-semibold cursor-default px-3 py-2'>
                            {activeUser?.email}
                        </div>
                    </Menu.Item>
                    <Menu.Item>
                        <div onClick={signOut} className='text-sm leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer px-3 py-2'>
                            {trans.components.popover.account.items.logout}
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default AccountPopover
