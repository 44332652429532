import { XMarkIcon } from '@heroicons/react/20/solid'
import { ErrorMessage, Field, FieldArray, FormikErrors } from 'formik'
import React from 'react'

import { palette } from '../../../libs/palette'
import { classesOf } from '../../../libs/utils/classes-of'
import { trans } from '../../../locales/ko'
import { ButtonText } from '../../button'
import { IFormBuilderContent } from './builder-content'


interface MissionExpressionInputProps {
    errors: FormikErrors<IFormBuilderContent>,
    values: IFormBuilderContent,
}

const MissionExpressionInput = ({ errors, values }: MissionExpressionInputProps) => {
    return (
        <FieldArray
            name='missionExpressions'
            render={(arrayHelpers) => {
                const missionExpressions = values.missionExpressions
                return (
                    <div>
                        {missionExpressions && missionExpressions.length > 0
                         ? missionExpressions.map((mission, index) => (
                                <div key={index} className='space-y-6 mb-6'>
                                    <div className='grid grid-cols-11 gap-5 bg-gray-100 p-3 rounded-md'>
                                        <div className='col-span-5'>
                                            <label htmlFor={`missionExpressions.${index}.expression`} className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                                {trans.components.builder.content.expression}
                                            </label>
                                            <Field
                                                id={`missionExpressions.${index}.expression`}
                                                name={`missionExpressions.${index}.expression`}
                                                placeholder={trans.components.builder.content.expression_ph}
                                                className={classesOf(
                                                    'block relative w-full rounded-md border border-gray-300 py-2 px-3',
                                                    palette.field.text, palette.field.text_placeholder,
                                                    'shadow-sm',
                                                    'focus:ring-2 focus:ring-inset focus:border-white', palette.field.text_ring_focus,
                                                    'text-sm leading-6',
                                                    errors?.missionExpressions?.[index] ? 'ring-1 ring-inset' : '',
                                                    errors?.missionExpressions?.[index] ? palette.field.text_ring_error : ''
                                                )}
                                            />
                                            <ErrorMessage component='p' name={`missionExpressions.${index}.expression`} className={classesOf('mt-2 text-sm text-left pl-1', palette.field.text_error)}/>
                                        </div>
                                        <div className='col-span-5 '>
                                            
                                            <label htmlFor={`missionExpressions.${index}.meaning`} className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                                                {trans.components.builder.content.meaning}
                                            </label>
                                            <Field
                                                id={`missionExpressions.${index}.meaning`}
                                                name={`missionExpressions.${index}.meaning`}
                                                placeholder={trans.components.builder.content.meaning_ph}
                                                className={classesOf(
                                                    'block relative w-full rounded-md border border-gray-300 py-2 px-3',
                                                    palette.field.text, palette.field.text_placeholder,
                                                    'shadow-sm',
                                                    'focus:ring-2 focus:ring-inset focus:border-white', palette.field.text_ring_focus,
                                                    'text-sm leading-6',
                                                    errors?.missionExpressions?.[index] ? 'ring-1 ring-inset' : '',
                                                    errors?.missionExpressions?.[index] ? palette.field.text_ring_error : ''
                                                )}
                                            />
                                            <ErrorMessage component='p' name={`missionExpressions.${index}.meaning`} className={classesOf('mt-2 text-sm text-left pl-1', palette.field.text_error)}/>
                                        </div>
                                        <div className='flex justify-end items-start'>
                                            <XMarkIcon onClick={() => arrayHelpers.remove(index)} className='w-6 text-gray-500 cursor-pointer hover:text-gray-600'/>
                                        </div>
                                    </div>
                                    {index === missionExpressions.length - 1 &&
                                        <ButtonText onClick={() => arrayHelpers.push({ expression: '', meaning: '' })}>{trans.components.builder.content.add_mission}</ButtonText>
                                    }
                                </div>
                            ))
                         : null}
                    </div>
                )
            }}
        />
    )
}

export default MissionExpressionInput