import { cva, VariantProps } from 'class-variance-authority'
import React, { HTMLAttributes } from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


const BadgeVariants = cva(
    `
     w-fit
     text-xs font-medium
     ring-1 ring-inset
     rounded-md
     inline-flex items-center px-2 py-1
     `,
    {
        variants: {
            variant: {
                gray: [palette.badge.background.gray, palette.badge.text.gray, palette.badge.ring.gray],
                red: [palette.badge.background.red, palette.badge.text.red, palette.badge.ring.red],
                yellow: [palette.badge.background.yellow, palette.badge.text.yellow, palette.badge.ring.yellow],
                green: [palette.badge.background.green, palette.badge.text.green, palette.badge.ring.green],
                blue: [palette.badge.background.blue, palette.badge.text.blue, palette.badge.ring.blue],
                indigo: [palette.badge.background.indigo, palette.badge.text.indigo, palette.badge.ring.indigo],
                purple: [palette.badge.background.purple, palette.badge.text.purple, palette.badge.ring.purple],
                pink: [palette.badge.background.pink, palette.badge.text.pink, palette.badge.ring.pink]
            }
        },
        defaultVariants: {
            variant: 'gray'
        }
    }
)

interface BadgeProps extends HTMLAttributes<HTMLElement>, VariantProps<typeof BadgeVariants> {
    children: React.ReactNode
    className?: string
}

const Badge = ({ children, className = '', variant }: BadgeProps) => {
    
    return (
        <span className={classesOf(BadgeVariants({ variant }), className)}>
            {children}
        </span>
    )
}

export default Badge
