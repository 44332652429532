import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateApplyOptions } from '../libs/firestore/firestore-helper'
import { useActiveStudyGroup } from './use-study-group'


export const useUpdateApplyOptionsMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ options }: {
            options: {
                applyLearningOptions?: boolean,
                applyScoreStrategy?: boolean,
                applyGroupContents?: boolean,
            }
        }) => updateApplyOptions({ studyGroupId, options }),
        onSuccess: (data, variables, context) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
        }
    })
}