import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, Field, useField } from 'formik'
import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


interface FieldTextProps {
    id?: string
    name?: string
    type?: string
    autoComplete?: string
    placeholder?: string
    error?: any
    autoFocus?: boolean
    className?: string
    containerClassName?: string
    
    [key: string]: any
}

const FieldText = ({
    error,
    className = '',
    containerClassName = '',
    autoComplete = 'off',
    autoFocus = false,
    ...props
}: FieldTextProps) => {
    const nameReduced = useMemo(() => {
        return props.id || props.name || props.type || ''
    }, [props])
    
    const fieldEl = useRef<HTMLInputElement>(null)
    
    useEffect(() => {
        if (autoFocus && fieldEl.current) {
            fieldEl.current.focus()
        }
    }, [autoFocus])
    
    const [field, meta, helper] = useField<string>(nameReduced)
    const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const trimmedValue = e.target.value.trim()
        if (trimmedValue !== field.value) {
            helper.setValue(trimmedValue)
        }
    }
    
    return (
        <div className={classesOf('relative flex-col', containerClassName)}>
            <Field {...props} className={classesOf(
                'block relative w-full rounded-md border border-gray-300 py-2 px-3',
                palette.field.text, palette.field.text_placeholder,
                'shadow-sm',
                'focus:ring-2 focus:ring-inset focus:border-white', palette.field.text_ring_focus,
                'text-sm leading-6',
                error ? 'ring-1 ring-inset' : '',
                error ? palette.field.text_ring_error : '',
                className
            )} innerRef={fieldEl}
                   autoComplete={autoComplete}
                   onBlur={handleBlur}
            />
            <ErrorMessage name={nameReduced} component='p' className={classesOf('mt-2 text-sm text-left pl-1', palette.field.text_error)}/>
            
            {!!error && (
                <React.Fragment>
                    <div className={classesOf(
                        'pointer-events-none absolute',
                        'h-full flex flex-col',
                        'bottom-0 top-2.5 right-3',
                        'pb-[28px]'
                    )}>
                        <ExclamationCircleIcon className={classesOf('h-5 w-5 shrink-0', palette.field.text_error)} aria-hidden='true'/>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default FieldText
