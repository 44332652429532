import { ChevronRightIcon } from '@heroicons/react/20/solid'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BuilderGroupUpdater } from '../../../components/builder/group'
import { ButtonText } from '../../../components/button'
import { ExplorerEditorRealName } from '../../../components/explorer/editor/explorer-editor-member'
import ExplorerHeader from '../../../components/explorer/explorer-header'
import ExplorerTextField from '../../../components/explorer/explorer-text-field'
import { FallbackDashboard } from '../../../components/fallback'
import useSession from '../../../hooks/use-session'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { useUserQuery } from '../../../hooks/use-users'
import { AccountState } from '../../../libs/model/account'
import { classesOf } from '../../../libs/utils/classes-of'
import { trans } from '../../../locales/ko'
import LearningStatistics from './learning-statistics/learning-statistics'


const ViewMemberDetails = () => {
    const { id } = useParams()
    const { activeUser } = useSession()
    const { studyGroup } = useActiveStudyGroup()
    
    const [openGroupUpdater, setOpenGroupUpdater] = useState(false)
    
    const { data: user, isLoading } = useUserQuery(id || activeUser?.id)
    
    const ttsLocalType = useMemo(() => {
        switch (user?.ttsLocale) {
            case 'en':
                return trans.views.dashboard.member_details.learning_setting.usa
            case 'gb':
                return trans.views.dashboard.member_details.learning_setting.uk
            case 'au':
                return trans.views.dashboard.member_details.learning_setting.australia
            case 'in':
                return trans.views.dashboard.member_details.learning_setting.india
            default:
                return ''
        }
    }, [user?.ttsLocale])
    
    const accountState = useMemo(() => {
        switch (user?.state) {
            case AccountState.NONE:
                return trans.views.dashboard.member.none
            case AccountState.INVITED:
                return trans.views.dashboard.member.invited
            case AccountState.JOINED:
                return trans.views.dashboard.member.joined
            case AccountState.REJECTED:
                return trans.views.dashboard.member.rejected
            default:
                return ''
        }
    }, [user?.state])
    
    const { subGroups } = useActiveStudyGroup()
    
    const subGroupIdsOfUser = useMemo(() => {
        return Object.values(studyGroup?.subGroups ?? {}).filter(each => each.members.includes(user?.id ?? '')).map(each => each.id)
    }, [studyGroup?.subGroups, user?.id])
    
    const includeSubGroups = useMemo(() => {
        return subGroups.filter(each => subGroupIdsOfUser.includes(each.id)) ?? []
    }, [subGroups, subGroupIdsOfUser])
    
    if (isLoading) return <FallbackDashboard/>
    if (isEmpty(user)) return null
    return (
        <React.Fragment>
            <div className='space-y-6 px-4 sm:px-6 lg:px-8'>
                <div className='w-full sm:w-3/4 text-2xl font-semibold flex items-center gap-2'>
                    <p className='shrink-0'>{trans.views.dashboard.member_details.it}</p>
                    <ChevronRightIcon className='shrink-0 w-7 h-auto'/>
                    <p>{user?.verifiedEmail || user.email}</p>
                </div>
                
                <div className={classesOf(
                    'relative',
                    'p-4 sm:p-0'
                )}>
                    <div className='w-full sm:w-3/4'>
                        {/* 프로필 */}
                        <ExplorerHeader title={trans.views.dashboard.member_details.profile}>
                            <ExplorerTextField
                                label={trans.views.dashboard.member_details.name}
                                content={user?.realName}
                                editor={(close) => <ExplorerEditorRealName user={user} close={close}/>}
                            />
                            <ExplorerTextField
                                label={trans.views.dashboard.member_details.email}
                                content={user?.verifiedEmail || user.email}
                            />
                            <ExplorerTextField
                                label={trans.views.dashboard.member_details.status}
                                content={accountState}
                            />
                        </ExplorerHeader>
                        
                        {/* 현재 그룹 */}
                        <ExplorerHeader title={trans.views.dashboard.member_details.group.it}>
                            <div className='flex items-center justify-between py-3 gap-3'>
                                <span className='break-all'>{includeSubGroups.map(each => each.name).join(', ')}</span>
                                <ButtonText onClick={() => setOpenGroupUpdater(true)}>{trans.views.dashboard.member_details.group.edit}</ButtonText>
                            </div>
                        </ExplorerHeader>
                        
                        {user.state === AccountState.JOINED &&
                            <React.Fragment>
                                <ExplorerHeader title={trans.views.dashboard.member_details.learning_setting.it}>
                                    <ExplorerTextField
                                        label={trans.views.dashboard.member_details.learning_setting.autoHintRecommend}
                                        content={user?.autoHintRecommend ? 'ON' : 'OFF'}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.member_details.learning_setting.hintPronunciation}
                                        content={user?.hintPronunciation ? 'ON' : 'OFF'}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.member_details.learning_setting.ttsSpeed}
                                        content={user?.ttsSpeed ?? '-'}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.member_details.learning_setting.ttsMute}
                                        content={user?.ttsMute ? 'ON' : 'OFF'}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.member_details.learning_setting.ttsLocale}
                                        content={ttsLocalType}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.member_details.learning_setting.conversation_level}
                                        content={`${trans.views.dashboard.member_details.learning_setting.level} ${user?.level}`}
                                    />
                                    <ExplorerTextField
                                        label={trans.views.dashboard.member_details.learning_setting.learningTime}
                                        content={`${user?.learningTime}${trans.views.dashboard.member_details.learning_setting.minute}`}
                                    />
                                </ExplorerHeader>
                            </React.Fragment>
                        }
                    </div>
                    
                    {/* 학습 통계 */}
                    {user.state === AccountState.JOINED && <LearningStatistics userId={user.id}/>}
                </div>
            </div>
            
            <BuilderGroupUpdater open={openGroupUpdater} onClose={() => setOpenGroupUpdater(false)} studyGroups={subGroups} userId={user?.id}/>
        </React.Fragment>
    )
}

export default ViewMemberDetails