import SlideOverActions from './components/slide-over-actions'
import SlideOverContent from './components/slide-over-content'
import SlideOverHeader from './components/slide-over-header'
import SlideOver from './slide-over'


const Slide = {
    Header: SlideOverHeader,
    Actions: SlideOverActions,
    Content: SlideOverContent
}

export {
    Slide,
    SlideOver
}
