import axios from 'axios'

import { trans } from '../../locales/ko'
import { transErrors } from '../../locales/ko-errors'


export const instance = axios.create({
    headers: { 'Content-Type': 'application/json' }
})

instance.interceptors.response.use(function(response) {
    const errorCode = String(response.data.code)
    const errorMessage = transErrors[errorCode] || trans.errors.basic
    if (response.data.code !== 20000) throw new Error(`${response.data.reason} : ${errorMessage}`)
    return response
}, function(error) {
    return Promise.reject(error)
})