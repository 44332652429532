import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useMemo } from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import { ButtonSolid } from '../button'
import ButtonOutline from '../button/button-outline'
import { ModalProps } from './index'


const DualActionModal =
    ({
        open,
        onClose,
        title,
        description,
        type = 'success',
        cancel,
        confirm,
        onConfirm,
        ...props
    }: ModalProps) => {
        const IconComponent = useMemo(() => {
            switch (type) {
                case 'success':
                    return CheckIcon
                case 'alert':
                    return ExclamationTriangleIcon
            }
        }, [type])
        
        const iconStyles = useMemo(() => {
            switch (type) {
                case 'success':
                    return [
                        palette.modal.icon.success_background,
                        palette.modal.icon.success_stroke
                    ]
                case 'alert':
                    return [
                        palette.modal.icon.alert_background,
                        palette.modal.icon.alert_stroke
                    ]
            }
        }, [type])
        
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog as='div' className='relative z-20' onClose={onClose}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'/>
                    </Transition.Child>
                    
                    <div className='fixed inset-0 z-20 w-screen overflow-y-auto'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                                enterTo='opacity-100 translate-y-0 sm:scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            >
                                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                                    <div>
                                        <div className={classesOf(
                                            'mx-auto',
                                            'h-12 w-12',
                                            'flex items-center justify-center',
                                            'rounded-full',
                                            'mb-3 sm:mb-5',
                                            iconStyles[0]
                                        )}>
                                            <IconComponent className={classesOf(
                                                'h-6 w-6',
                                                iconStyles[1]
                                            )} aria-hidden='true'/>
                                        </div>
                                        
                                        
                                        <div className='text-center'>
                                            <Dialog.Title as='h3' className='text-base font-semibold leading-6 text-gray-900'>
                                                {title}
                                            </Dialog.Title>
                                            <div className='mt-2'>
                                                <div className='text-sm text-gray-500 whitespace-pre-line'>
                                                    {description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='mt-3 flex gap-3'>
                                        <ButtonOutline onClick={onClose} size='expand'>
                                            {cancel ?? trans.components.modal.cancel}
                                        </ButtonOutline>
                                        
                                        <ButtonSolid
                                            onClick={onConfirm} variant={props.disabled ? 'disabled' : (type ?? 'default')} size='expand'
                                            loading={props.loading} disabled={props.disabled}
                                        >
                                            {confirm ?? trans.components.modal.confirm}
                                        </ButtonSolid>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

export default DualActionModal
