import { useField, useFormikContext } from 'formik'
import React, { useCallback } from 'react'

import Selector from '../selector/selector'
import { SelectorButton } from '../selector/selector-button'
import { SelectorOption } from '../selector/selector-option'


interface FieldSelectorProps<TValue> {
    name: string
    options: SelectorOption<TValue>[]
    optionAs?: React.ElementType<SelectorOption<TValue>>
    buttonAs?: React.ElementType<SelectorButton<TValue>>
    loaderAs?: React.ElementType<SelectorButton<TValue>>
    className?: string
    containerClassName?: string
    inViewRef?: (node?: Element | null) => void
    disabled?: boolean
    loading?: boolean
    
    onSelect?: (value?: TValue) => void
}

const FieldSelector = <TValue, >({ name, options, ...props }: FieldSelectorProps<TValue>) => {
    const [field] = useField<TValue>(name)
    const { setFieldValue, setFieldTouched } = useFormikContext()
    
    const handleChange = useCallback(async (value: TValue) => {
        props.onSelect?.call(this, value)
        
        if (!value) return
        setFieldTouched(name)
        await setFieldValue(name, value)
    }, [props.onSelect, setFieldTouched, name, setFieldValue])
    
    return (
        <Selector
            options={options}
            value={field.value}
            onChange={handleChange}
            optionAs={props.optionAs}
            buttonAs={props.buttonAs}
            loaderAs={props.loaderAs}
            className={props.className}
            containerClassName={props.containerClassName}
            inViewRef={props.inViewRef}
            disabled={props.disabled}
            loading={props.loading}
        />
    )
}

export default FieldSelector
