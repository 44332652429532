import React from 'react'

import { classesOf } from '../../../libs/utils/classes-of'


interface SlideOverContentProps {
    children?: React.ReactNode
    className?: string
}

const SlideOverContent = ({ children, className = '' }: SlideOverContentProps) => {
    return (
        <div className={classesOf(
            'flex flex-col h-full',
            'px-4 py-6 sm:px-6 sm:py-5',
            className
        )}>
            {children}
        </div>
    )
}

export default SlideOverContent
