import { Dialog, Transition } from '@headlessui/react'
import { useAtom } from 'jotai'
import React, { Fragment, useCallback } from 'react'

import { toastsInlineAtom } from '../../hooks/use-toast'
import { classesOf } from '../../libs/utils/classes-of'
import Toast from '../toast'
import SlideOverActions, { SlideOverActionsProps } from './components/slide-over-actions'
import SlideOverHeader, { SlideOverHeaderProps } from './components/slide-over-header'


interface SlideOverProps extends SlideOverHeaderProps, SlideOverActionsProps {
    open: boolean
    onClose: () => void
    actions?: React.ReactNode
    children: React.ReactNode
    slideWidth?: 'default' | 'wide' | 'narrow' | 'none'
}

const SlideOver = ({
    open, onClose,
    title, description,
    cancel, handleCancel, showCancel,
    confirm, handleConfirm, disableConfirm, showConfirm = true,
    destructive, handleDestructive, showDestructive = false,
    slideWidth = 'default',
    isLoading = false,
    actions,
    children
}: SlideOverProps) => {
    const [toastsInline, setToastsInline] = useAtom(toastsInlineAtom)
    
    const handleClose = useCallback(() => {
        setToastsInline([])
        onClose()
    }, [onClose, setToastsInline])
    
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-20' onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-black/25'/>
                </Transition.Child>
                
                <div className='fixed inset-0 overflow-hidden'>
                    <div className='absolute inset-0 overflow-hidden'>
                        <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full'>
                            <Transition.Child
                                as={Fragment}
                                enter='transform transition ease-in-out duration-500 sm:duration-700'
                                enterFrom='translate-x-full'
                                enterTo='translate-x-0'
                                leave='transform transition ease-in-out duration-500 sm:duration-700'
                                leaveFrom='translate-x-0'
                                leaveTo='translate-x-full'
                            >
                                <Dialog.Panel className={classesOf(
                                    'pointer-events-auto',
                                    'w-screen',
                                    slideWidth === 'default' ? 'max-w-2xl' : '',
                                    slideWidth === 'wide' ? 'max-w-4xl' : '',
                                    slideWidth === 'narrow' ? 'max-w-xl' : '',
                                    slideWidth === 'none' && ''
                                )}>
                                    <div className='flex h-full flex-col overflow-y-hidden bg-white shadow-xl'>
                                        <SlideOverHeader title={title} description={description} onClose={handleClose}/>
                                        <div className='grow overflow-y-scroll'>
                                            {children}
                                        </div>
                                        
                                        {actions ? (
                                            <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
                                                <div className='flex justify-end space-x-3'>
                                                    {actions}
                                                </div>
                                            </div>
                                        ) : (
                                             <React.Fragment>
                                                 {(showCancel || showConfirm) && (
                                                     <SlideOverActions
                                                         cancel={cancel} handleCancel={handleCancel} showCancel={showCancel}
                                                         confirm={confirm} handleConfirm={handleConfirm} showConfirm={showConfirm} disableConfirm={disableConfirm}
                                                         destructive={destructive} handleDestructive={handleDestructive} showDestructive={showDestructive}
                                                         isLoading={isLoading}
                                                         onClose={handleClose}
                                                     />
                                                 )}
                                             </React.Fragment>
                                         )}
                                    </div>
                                    
                                    <Transition.Root show={toastsInline.length > 0} as={Fragment}>
                                        <div className='pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:p-6 z-20'>
                                            <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
                                                {toastsInline.map((toast) => <Toast key={toast.id} {...toast}/>)}
                                            </div>
                                        </div>
                                    </Transition.Root>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default SlideOver
