import { Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'

import { useChangeSubGroupNameMutation, useUpdateLearningOptionsMutation } from '../../../hooks/use-group'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { useToast } from '../../../hooks/use-toast'
import { SubGroup } from '../../../libs/model/study-group'
import { trans } from '../../../locales/ko'
import { ButtonSolid, ButtonText } from '../../button'
import { FieldSelector, FieldText } from '../../field'
import { PopoverClose } from '../../popover'


export interface ExplorerEditor<TValue> {
    close: PopoverClose
    afterSubmit?: (value: TValue | null) => void
}

interface IFormUpdateSubGroupName {
    name: string
}

export const ExplorerEditorSubGroupName = ({ close, afterSubmit, subGroup }: ExplorerEditor<SubGroup> & {
    subGroup?: SubGroup | null
}) => {
    const { showToast } = useToast()
    const { mutateAsync: changeSubGroupName, isPending } = useChangeSubGroupNameMutation()
    const { subGroups } = useActiveStudyGroup()
    
    const initialValues: IFormUpdateSubGroupName = useMemo(() => {
        return { name: subGroup?.name ?? '' }
    }, [subGroup?.name])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            name: Yup.string()
                     .test({
                         test: (value: any) => {
                             return !subGroups?.some(each => each.name === value)
                         },
                         message: trans.views.dashboard.sub_group_detail.error.name_duplicate
                     })
                     .required(trans.views.dashboard.sub_group_detail.error.name_required)
        })
    }, [subGroups])
    
    const onSubmit = useCallback(async (values: IFormUpdateSubGroupName) => {
        if (isEmpty(subGroup) || isPending) return
        try {
            await changeSubGroupName({ subGroupId: subGroup?.id, name: values.name })
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [changeSubGroupName, close, isPending, showToast, subGroup])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='name' className='block text-gray-500 ml-1'>{trans.views.dashboard.sub_group_detail.name}</label>
                        <FieldText
                            id='name' name='name' type='text'
                            error={errors.name && touched.name}
                            placeholder={trans.views.dashboard.sub_group_detail.name}
                            autoFocus
                        />
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.save}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateSpeed {
    ttsSpeed: number
}

export const ExplorerEditorSpeed = ({ close, subGroup }: ExplorerEditor<SubGroup> & {
    subGroup?: SubGroup | null
}) => {
    const { showToast } = useToast()
    const { mutateAsync: updateLearningOptions, isPending } = useUpdateLearningOptionsMutation()
    
    const speedOptions = useMemo(() => ['0.7', '0.8', '0.9', '1.0', '1.1', '1.2', '1.3'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const initialValues: IFormUpdateSpeed = useMemo(() => {
        return { ttsSpeed: subGroup?.learningOptions.ttsSpeed ?? 1 }
    }, [subGroup?.learningOptions.ttsSpeed])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            ttsSpeed: Yup.number()
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateSpeed) => {
        if (isEmpty(subGroup) || isPending) return
        try {
            await updateLearningOptions({ subGroupId: subGroup?.id, params: { ...values } })
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [close, isPending, showToast, subGroup, updateLearningOptions])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='ttsSpeed' className='block text-gray-500 ml-1'>{trans.views.dashboard.sub_group_detail.learning_setting.ttsSpeed}</label>
                        <FieldSelector name='ttsSpeed' options={speedOptions}/>
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.save}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateLocale {
    ttsLocale: string
}

export const ExplorerEditorLocale = ({ close, subGroup }: ExplorerEditor<SubGroup> & {
    subGroup?: SubGroup | null
}) => {
    const { showToast } = useToast()
    const { mutateAsync: updateLearningOptions, isPending } = useUpdateLearningOptionsMutation()
    
    const LocaleOptions = useMemo(() => [
        { label: trans.views.dashboard.member_details.learning_setting.usa, value: 'en' },
        { label: trans.views.dashboard.member_details.learning_setting.uk, value: 'gb' },
        { label: trans.views.dashboard.member_details.learning_setting.australia, value: 'au' },
        { label: trans.views.dashboard.member_details.learning_setting.india, value: 'in' }
    ], [])
    
    const initialValues: IFormUpdateLocale = useMemo(() => {
        return { ttsLocale: subGroup?.learningOptions.ttsLocale ?? '' }
    }, [subGroup?.learningOptions.ttsLocale])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            ttsLocale: Yup.string()
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateLocale) => {
        if (isEmpty(subGroup) || isPending) return
        try {
            await updateLearningOptions({ subGroupId: subGroup?.id, params: { ...values } })
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [close, isPending, showToast, subGroup, updateLearningOptions])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='ttsLocale' className='block text-gray-500 ml-1'>{trans.views.dashboard.sub_group_detail.learning_setting.ttsLocale}</label>
                        <FieldSelector name='ttsLocale' options={LocaleOptions}/>
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.save}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateLevel {
    level: number
}

export const ExplorerEditorLevel = ({ close, subGroup }: ExplorerEditor<SubGroup> & {
    subGroup?: SubGroup | null
}) => {
    const { showToast } = useToast()
    const { mutateAsync: updateLearningOptions, isPending } = useUpdateLearningOptionsMutation()
    
    const levelOptions = useMemo(() => ['1', '2', '3', '4', '5', '6'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const initialValues: IFormUpdateLevel = useMemo(() => {
        return { level: subGroup?.learningOptions.level ?? 1 }
    }, [subGroup?.learningOptions.level])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            level: Yup.number()
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateLevel) => {
        if (isEmpty(subGroup) || isPending) return
        try {
            await updateLearningOptions({ subGroupId: subGroup?.id, params: { ...values } })
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [close, isPending, showToast, subGroup, updateLearningOptions])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='level' className='block text-gray-500 ml-1'>{trans.views.dashboard.sub_group_detail.learning_setting.conversation_level}</label>
                        <FieldSelector name='level' options={levelOptions}/>
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.save}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface IFormUpdateLearningTime {
    learningTime: number
}

export const ExplorerEditorLearningTime = ({ close, subGroup }: ExplorerEditor<SubGroup> & {
    subGroup?: SubGroup | null
}) => {
    const { showToast } = useToast()
    const { mutateAsync: updateLearningOptions, isPending } = useUpdateLearningOptionsMutation()
    
    const learningTimeOptions = useMemo(() => ['5', '10', '15', '20'].map(each => {
        return { label: each, value: parseFloat(each) }
    }), [])
    
    const initialValues: IFormUpdateLearningTime = useMemo(() => {
        return { learningTime: subGroup?.learningOptions.learningTime ?? 5 }
    }, [subGroup?.learningOptions.learningTime])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            learningTime: Yup.number()
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateLearningTime) => {
        if (isEmpty(subGroup) || isPending) return
        try {
            await updateLearningOptions({ subGroupId: subGroup?.id, params: { ...values } })
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [close, isPending, showToast, subGroup, updateLearningOptions])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='learningTime' className='block text-gray-500 ml-1'>{trans.views.dashboard.sub_group_detail.learning_setting.learningTime}</label>
                        <FieldSelector name='learningTime' options={learningTimeOptions}/>
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.save}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}