import { CheckIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useMemo } from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import { StepperItemProps } from './index'


const StepperItemVertical = ({
    index, total, active, onItemClick,
    label, clickable = true
}: StepperItemProps) => {
    const showLineConnector = useMemo(() => index < total - 1, [index, total])
    
    const handleClick = useCallback(() => {
        if (!clickable) return
        onItemClick?.call(this, index)
    }, [clickable, index, onItemClick])
    
    const renderCompleteStep = () => {
        return (
            <div className={classesOf(
                'group w-fit z-10',
                'flex items-center',
                clickable ? 'cursor-pointer' : 'cursor-default'
            )} onClick={handleClick}>
                {showLineConnector && (
                    <div className={classesOf(
                        'absolute left-4 top-4 -ml-px mt-0.5',
                        'h-full w-0.5',
                        palette.stepper.complete
                    )}/>
                )}
                
                <span className='flex h-9 items-center'>
                    <span className={classesOf(
                        'relative flex items-center justify-center',
                        'h-8 w-8 rounded-full',
                        'text-white text-sm',
                        'bg-white ring-2 ring-inset',
                        palette.stepper.complete_ring
                    )}>
                        <CheckIcon className={classesOf(
                            'w-auto h-5 stroke-2',
                            palette.stepper.complete_text
                        )}/>
                    </span>
                </span>
                <span className={classesOf(
                    'mx-2',
                    'text-sm font-bold',
                    palette.stepper.complete_text
                )}>{label}</span>
            </div>
        )
    }
    
    const renderActiveStep = () => {
        return (
            <div id='active-step' className={classesOf(
                'group w-fit z-10',
                'flex items-center',
                'cursor-pointer'
            )}>
                {showLineConnector && (
                    <div className={classesOf(
                        'absolute left-4 top-4 -ml-px mt-0.5',
                        'h-full w-0.5',
                        'bg-gray-300'
                    )}/>
                )}
                
                <span className='flex h-9 items-center'>
                    <span className={classesOf(
                        'relative flex items-center justify-center',
                        'h-8 w-8 rounded-full',
                        'text-white text-sm',
                        palette.stepper.active
                    )}>
                        {index + 1}
                    </span>
                </span>
                <span className={classesOf(
                    'mx-2',
                    'text-sm font-bold',
                    palette.stepper.active_text
                )}>{label}</span>
            </div>
        )
    }
    
    const renderUpcomingStep = () => {
        return (
            <div className={classesOf(
                'group w-fit z-10',
                'flex items-center',
                'cursor-pointer'
            )} onClick={handleClick}>
                {showLineConnector && (
                    <div className={classesOf(
                        'absolute left-4 top-4 -ml-px mt-0.5',
                        'h-full w-0.5',
                        'bg-gray-300'
                    )}/>
                )}
                
                <span className='flex h-9 items-center'>
                    <span className={classesOf(
                        'relative flex items-center justify-center',
                        'h-8 w-8 rounded-full',
                        'bg-white',
                        'border-2 border-gray-300 group-hover:border-gray-400'
                    )}>
                        <span className={classesOf(
                            'h-2.5 w-2.5 rounded-full',
                            'bg-transparent',
                            'group-hover:bg-gray-300'
                        )}/>
                    </span>
                </span>
                <span className={classesOf(
                    'mx-2',
                    'text-sm font-bold',
                    'text-gray-500'
                )}>{label}</span>
            </div>
        )
    }
    
    if (index === active) return renderActiveStep()
    if (index < active) return renderCompleteStep()
    return renderUpcomingStep()
}

export default StepperItemVertical
