import { useMemo } from 'react'

import useSession from './use-session'
import { useActiveStudyGroup } from './use-study-group'


export const useActiveAccount = () => {
    const { activeUser } = useSession()
    const { studyGroup } = useActiveStudyGroup()
    
    const isAdmin = useMemo(() => activeUser?.isStudyGroupAdmin && studyGroup?.admins.includes(activeUser.id), [activeUser?.id, activeUser?.isStudyGroupAdmin, studyGroup?.admins])
    
    return { isAdmin }
}