import { Field, useFormikContext } from 'formik'
import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'


interface FieldTextareaProps {
    id?: string
    name?: string
    type?: string
    autoComplete?: string
    placeholder?: string
    error?: any
    autoFocus?: boolean
    className?: string
    containerClassName?: string
    
    [key: string]: any
}

const FieldTextareaBulkData = ({
    error,
    className = '',
    containerClassName = '',
    autoFocus = false,
    ...props
}: FieldTextareaProps) => {
    const fieldEl = useRef<HTMLInputElement>(null)
    
    const { setFieldValue } = useFormikContext()
    
    const handleBulkDataChange = useCallback(async (event: ChangeEvent<HTMLTextAreaElement>) => {
        const transformedData = event.target.value.replace(/\t/g, '|')
        await setFieldValue('bulkData', transformedData)
    }, [setFieldValue])
    
    useEffect(() => {
        if (autoFocus && fieldEl.current) {
            fieldEl.current.focus()
        }
    }, [autoFocus])
    
    return (
        <div className={classesOf('relative flex-col', containerClassName)}>
            <Field
                {...props}
                className={classesOf(
                    'block relative w-full rounded-md border border-gray-300 py-2 px-3',
                    palette.field.text, palette.field.text_placeholder,
                    'shadow-sm',
                    'resize-none',
                    'focus:ring-2 focus:ring-inset focus:border-white', palette.field.text_ring_focus,
                    'text-sm leading-6',
                    error ? 'ring-1 ring-inset' : '',
                    error ? palette.field.text_ring_error : '',
                    className
                )}
                onChange={handleBulkDataChange}
                innerRef={fieldEl}
                as='textarea'
            />
        </div>
    )
}

export default FieldTextareaBulkData
