export const msToMinutesAndSeconds = (ms: number) => {
    const seconds = Math.floor(ms / 1000)
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return minutes > 0 ? `${minutes}분 ${remainingSeconds}초` : `${remainingSeconds}초`
}

export const msToHoursMinutesAndSeconds = (ms: number) => {
    const seconds = Math.floor(ms / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const remainingSeconds = seconds % 60
    const remainingMinutes = minutes % 60
    
    let result = ''
    if (hours > 0) result += `${hours}시간 `
    if (hours > 0 || remainingMinutes > 0) result += `${remainingMinutes}분 `
    result += `${remainingSeconds}초`
    
    return result.trim()
}

export const msToMinutes = (ms: number) => {
    const seconds = Math.floor(ms / 1000)
    const minutes = Math.floor(seconds / 60)
    
    return `${minutes}`
}