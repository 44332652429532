import lottie from 'lottie-web'
import React, { useEffect, useRef } from 'react'

import { classesOf } from '../../libs/utils/classes-of'


interface FallbackDashboardProps {
    className?: string
}

const FallbackDashboard = ({ className = '' }: FallbackDashboardProps) => {
    const animationContainer = useRef(null)
    
    useEffect(() => {
        if (animationContainer.current) {
            const anim = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/loading_dot_red.json')
            })
            
            return () => anim.destroy()
        }
    }, [])
    
    return (
        <div className={classesOf(
            'w-full h-96',
            'flex flex-col items-center justify-center',
            'overflow-hidden',
            'select-none',
            'z-30',
            className
        )}>
            <div ref={animationContainer}/>
        </div>
    )
}

export default FallbackDashboard
