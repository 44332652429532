import { Form, Formik, FormikErrors, FormikTouched } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { useCheckValidNameMutation, useSaveCustomPromptMutation } from '../../../hooks/use-content'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { useToast } from '../../../hooks/use-toast'
import { CustomPrompt, MissionExpressions } from '../../../libs/model/content'
import { trans } from '../../../locales/ko'
import { Slide, SlideOver } from '../../slide'
import Stepper, { StepperItem } from '../../stepper'
import { StepContentInfo, StepMission } from './index'


export interface IFormBuilderContent {
    name: string
    subGroupIds: string[]
    missionExpressions: MissionExpressions[]
    categoryName: string
}

export type BuilderContentFormikProps = {
    errors: FormikErrors<IFormBuilderContent>,
    touched: FormikTouched<IFormBuilderContent>,
    values: IFormBuilderContent
    dirty?: boolean
}

interface BuilderContentProps {
    open: boolean
    onClose: () => void
}

const BuilderContent = ({ open, onClose }: BuilderContentProps) => {
    const { showToast } = useToast()
    const { studyGroupId } = useActiveStudyGroup()
    const { mutateAsync: saveCustomPrompt, isPending: isCreating } = useSaveCustomPromptMutation()
    const { mutateAsync: checkValidName, isPending: isChecking } = useCheckValidNameMutation()
    
    const [activeStep, setActiveStep] = useState<number>(0)
    const [customPrompt, setCustomPrompt] = useState<CustomPrompt | null>(null)
    
    const initialValues: IFormBuilderContent = {
        name: '',
        subGroupIds: [],
        missionExpressions: [{ expression: '', meaning: '' }],
        categoryName: ''
    }
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            name: Yup.string().required(trans.views.dashboard.content_detail.error.name_required),
            subGroupIds: Yup.array(),
            categoryName: Yup.string().required(trans.views.dashboard.content_detail.error.category_name_required),
            missionExpressions: Yup.array().of(
                Yup.object().shape({
                    expression: Yup.string()
                                   .matches(/^[A-Za-z0-9 ,.!?'"():;\-[\]{}@#$%^&*+=~`<>/\\|_]*$/, trans.components.builder.content.error.english_only)
                                   .required(trans.components.builder.content.error.expression_required),
                    meaning: Yup.string()
                                .matches(/^[A-Za-z0-9 ,.!?'"():;\-[\]{}@#$%^&*+=~`<>/\\|_]*$/, trans.components.builder.content.error.english_only)
                                .required(trans.components.builder.content.error.meaning_required)
                })
            )
        })
    }, [])
    
    const steps: StepperItem[] = useMemo(() => {
        return [
            { label: trans.components.builder.content.content_info },
            { label: trans.components.builder.content.mission_info }
        ]
    }, [])
    
    const goPrev = useCallback(() => {
        setActiveStep(prev => prev - 1)
    }, [])
    
    const goNext = useCallback(() => {
        setActiveStep((prev) => Math.min(prev + 1, steps.length - 1))
    }, [steps.length])
    
    const handleClose = useCallback(() => {
        setActiveStep(0)
        onClose()
    }, [onClose])
    
    const getStepViews = useCallback(({ errors, touched, values, dirty }: BuilderContentFormikProps) => {
        return [
            <StepContentInfo key='content-info' errors={errors} touched={touched} values={values} onNext={goNext} dirty={dirty}/>,
            <StepMission key='mission' errors={errors} touched={touched} values={values} onPrev={goPrev} loading={isCreating || isChecking} customPrompt={customPrompt} setCustomPrompt={setCustomPrompt}/>
        ]
    }, [customPrompt, goNext, goPrev, isChecking, isCreating])
    
    const onSubmit = useCallback(async (values: IFormBuilderContent) => {
        if (isCreating || isChecking || isEmpty(customPrompt)) return
        const response = await checkValidName({ name: values.name })
        if (response === false) return showToast({ title: trans.components.builder.content.error.name_duplicate, type: 'error' })
        
        try {
            await saveCustomPrompt({
                promptUpdateRequest: {
                    name: values.name,
                    studyGroupId,
                    subGroupIds: values.subGroupIds,
                    categoryName: values.categoryName
                },
                customPrompt
            })
            handleClose()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [checkValidName, customPrompt, handleClose, isChecking, isCreating, saveCustomPrompt, showToast, studyGroupId])
    
    return (
        <SlideOver
            open={open}
            onClose={handleClose}
            title={trans.components.builder.content.title}
            showCancel={false}
            showConfirm={false}
            slideWidth='wide'
        >
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ errors, touched, values, dirty }: BuilderContentFormikProps) => {
                    return (
                        <Slide.Content>
                            <Form>
                                <Stepper active={activeStep} steps={steps}
                                         className='pb-12' stepClassName='pb-3' childrenClassName='mx-10 my-3'>
                                    {getStepViews({ errors, touched, values, dirty })}
                                </Stepper>
                            </Form>
                        </Slide.Content>
                    )
                }}
            </Formik>
        </SlideOver>
    )
}

export default BuilderContent