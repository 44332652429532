import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'

import { useActiveStudyGroup } from '../../hooks/use-study-group'
import { SubGroup } from '../../libs/model/study-group'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import { BuilderSubGroupMember } from '../builder/member'
import { ButtonText } from '../button'
import EmptyStateTable from '../empty-state/empty-state-table'
import { FallbackDashboard } from '../fallback'


interface TableGroupProps {
    selectedSubGroups: SubGroup[]
    setSelectedSubGroups: React.Dispatch<React.SetStateAction<SubGroup[]>>
}

const TableGroup = ({ selectedSubGroups, setSelectedSubGroups }: TableGroupProps) => {
    const navigate = useNavigate()
    const { studyGroup, subGroups } = useActiveStudyGroup()
    
    const [currentPage, setCurrentPage] = useState(0)
    const itemsPerPage = 10
    const currentGroups = useMemo(() => {
        const start = currentPage * itemsPerPage
        const end = start + itemsPerPage
        return subGroups.slice(start, end)
    }, [currentPage, subGroups])
    
    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected)
    }
    
    const checkbox = useRef<HTMLInputElement>(null)
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    
    useEffect(() => {
        if (isEmpty(subGroups) || currentPage === 0) return
        if (isEmpty(currentGroups)) setCurrentPage(prev => prev - 1)
    }, [currentGroups, currentPage, subGroups])
    
    const toggleAll = useCallback(() => {
        const allSelected = currentGroups.every(group => selectedSubGroups.includes(group))
        
        if (allSelected) {
            setSelectedSubGroups(selectedSubGroups.filter(group => !currentGroups.includes(group)))
        } else {
            const newSelections = [...selectedSubGroups, ...currentGroups.filter(group => !selectedSubGroups.includes(group))]
            setSelectedSubGroups(newSelections)
        }
    }, [currentGroups, selectedSubGroups, setSelectedSubGroups])
    
    useEffect(() => {
        if (isEmpty(currentGroups)) return
        
        setChecked(false)
        setIndeterminate(false)
        const allSelected = currentGroups.every(group => selectedSubGroups.includes(group))
        const someSelected = currentGroups.some(group => selectedSubGroups.includes(group))
        setChecked(allSelected)
        setIndeterminate(someSelected && !allSelected)
        
        if (checkbox.current) {
            checkbox.current.indeterminate = indeterminate
        }
    }, [currentGroups, indeterminate, selectedSubGroups])
    
    const [selectedStudyGroup, setSelectedStudyGroup] = useState<SubGroup | null>(null)
    const [openMemberBuilder, setOpenMemberBuilder] = useState(false)
    const handleOpenMemberBuilder = useCallback((studyGroup: SubGroup) => {
        setSelectedStudyGroup(studyGroup)
        setOpenMemberBuilder(true)
    }, [])
    
    if (!subGroups) return <FallbackDashboard/>
    return (
        <React.Fragment>
            <div className='mt-8 flow-root'>
                <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='relative inline-block min-w-full min-h-[700px] py-2 align-middle sm:px-2 lg:px-4 space-y-12 mb-12'>
                        <div className='min-w-full table-fixed divide-y divide-gray-300'>
                            <div>
                                <div className='w-full grid grid-cols-14'>
                                    <div className='relative px-7 sm:w-12 sm:px-6'>
                                        <input
                                            type='checkbox'
                                            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                            ref={checkbox}
                                            checked={checked}
                                            onChange={toggleAll}
                                        />
                                    </div>
                                    <div className='pr-3 py-3.5 text-left text-sm font-semibold'/>
                                    <div className='col-span-3 pr-3 py-3.5 text-left text-sm font-semibold'>{trans.views.dashboard.group.name}</div>
                                    <div className='col-span-3 pr-3 py-3.5 text-left text-sm font-semibold'>{trans.views.dashboard.group.learner}</div>
                                    <div className='col-span-3 pr-3 py-3.5 text-left text-sm font-semibold'>{trans.views.dashboard.group.member_edit}</div>
                                    <div className='col-span-3 pr-3 py-3.5 text-left text-sm font-semibold'>{trans.views.dashboard.group.details}</div>
                                </div>
                            </div>
                            {isEmpty(subGroups) ?
                             <div>
                                 <EmptyStateTable content='그룹이 없습니다.'/>
                             </div> :
                             <div className='divide-y divide-gray-200 bg-white'>
                                 <div className='w-full grid grid-cols-14'>
                                     <div className='whitespace-nowrap pr-3 py-4 text-sm'/>
                                     <div className='whitespace-nowrap pr-3 py-4 text-sm'/>
                                     <div className='col-span-3 whitespace-nowrap pr-3 py-4 text-sm'>{trans.views.dashboard.group.all_user}</div>
                                     <div className='col-span-3 truncate whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>{studyGroup?.members.length}</div>
                                     <div className='col-span-3 whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>-</div>
                                     <div className='col-span-3 whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>-</div>
                                 </div>
                                 
                                 {currentGroups?.map((group: SubGroup, index) => (
                                     <div key={group.id} className={classesOf(selectedSubGroups.includes(group) ? 'bg-gray-50' : undefined, 'w-full grid grid-cols-14')}>
                                         <div className='relative px-7 sm:w-12 sm:px-6'>
                                             {selectedSubGroups.includes(group) && (
                                                 <div className='absolute inset-y-0 left-0 w-0.5 bg-orange-500'/>
                                             )}
                                             <input
                                                 type='checkbox'
                                                 className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                                 value={group.id}
                                                 checked={selectedSubGroups.includes(group)}
                                                 onChange={(e) =>
                                                     setSelectedSubGroups(
                                                         e.target.checked
                                                         ? [...selectedSubGroups, group]
                                                         : selectedSubGroups.filter((p) => p !== group)
                                                     )
                                                 }
                                             />
                                         </div>
                                         <div className='pr-3 py-4 text-sm break-words'>{currentPage * itemsPerPage + index + 1}</div>
                                         <div className='col-span-3 truncate whitespace-nowrap pr-3 py-4 text-sm'>{group.name}</div>
                                         <div className='col-span-3 truncate whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>{group.members.length}</div>
                                         <div className='col-span-3 whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>
                                             <ButtonText variant='gray' onClick={() => handleOpenMemberBuilder(group)}>{trans.views.dashboard.group.button_member_edit}</ButtonText>
                                         </div>
                                         <div className='col-span-3 whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>
                                             <ButtonText onClick={() => navigate(`/group/${group.id}`)}>{trans.views.dashboard.group.button_details}</ButtonText>
                                         </div>
                                     </div>
                                 ))}
                             </div>
                            }
                        </div>
                        
                        <div className='absolute left-1/2 -bottom-5 transform -translate-x-1/2'>
                            <ReactPaginate
                                className='mt-8 flex items-center justify-center gap-10'
                                previousLabel={trans.actions.prev}
                                nextLabel={trans.actions.next}
                                breakLabel={'...'}
                                pageCount={Math.ceil(subGroups.length / itemsPerPage) || 1}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            
            </div>
            <BuilderSubGroupMember open={openMemberBuilder} onClose={() => setOpenMemberBuilder(false)} subGroup={selectedStudyGroup}/>
        </React.Fragment>
    )
}

export default TableGroup