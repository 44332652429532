import { GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { useCallback, useContext, useMemo } from 'react'

import { firebaseAuth, kakaoClientId } from '../contexts/firebase/firebase-provider'
import { AuthContext, SessionError } from '../contexts/session'
import AdropLogger from '../libs/adrop-logger'
import { trans } from '../locales/ko'
import { useToast } from './use-toast'


const useSession = () => {
    const context = useContext(AuthContext)
    const cognitoUser = useMemo(() => context.firebaseAuthUser, [context])
    const sessionState = useMemo(() => context.sessionState, [context])
    const activeUser = useMemo(() => context.activeUser, [context])
    const isAdmin = useMemo(() => activeUser?.isStudyGroupAdmin, [activeUser?.isStudyGroupAdmin])
    
    const { showToast } = useToast()
    // const { getUserData } = useUserData()
    
    const signInWithGoogle = useCallback(async (): Promise<any> => {
        try {
            const provider = new GoogleAuthProvider()
            await signInWithPopup(firebaseAuth, provider)
            return true
        } catch (e: any) {
            switch (e.code) {
                case SessionError.USER_NOT_CONFIRM:
                    return false
                default:
                    e.message = trans.views.auth.sign_in.error.fail
                    throw e
            }
        }
    }, [])
    
    const signInWithApple = useCallback(async (): Promise<boolean> => {
        try {
            const provider = new OAuthProvider('apple.com')
            await signInWithPopup(firebaseAuth, provider)
            // const userData = await getUserData()
            // console.log(userData)
            // return userData
            return true
        } catch (e: any) {
            switch (e.code) {
                case SessionError.USER_NOT_CONFIRM:
                    return false
                default:
                    e.message = trans.views.auth.sign_in.error.fail
                    throw e
            }
        }
    }, [])
    
    const signInWithKakao = useCallback(async (): Promise<boolean> => {
        try {
            if (!window.Kakao.isInitialized()) {
                window.Kakao.init(kakaoClientId)
            }
            const redirectUri = `${location.origin}/kakao-auth`
            window.Kakao.Auth.authorize({
                redirectUri
            })
            return true
        } catch (e: any) {
            console.log(e)
            switch (e.code) {
                case SessionError.USER_NOT_CONFIRM:
                    return false
                default:
                    e.message = trans.views.auth.sign_in.error.fail
                    throw e
            }
        }
    }, [])
    
    const signInWithEmail = useCallback(async ({ email, password }: { email: string, password: string }) => {
        try {
            await signInWithEmailAndPassword(firebaseAuth, email, password)
            // const userData = await getUserData();
            // console.log(userData);
            // return userData;
            return true
        } catch (e: any) {
            switch (e.code) {
                case SessionError.USER_NOT_CONFIRM:
                    return false
                default:
                    e.message = trans.views.auth.sign_in.error.fail
                    throw e
            }
        }
    }, [])
    
    const signOut = useCallback(async () => {
        try {
            await firebaseAuth.signOut()
            showToast({ title: '정상적으로 로그아웃되었습니다.', type: 'success' })
        } catch (e: any) {
            AdropLogger.error(e)
        }
    }, [showToast])
    
    return {
        cognitoUser, sessionState, activeUser, isAdmin,
        signOut, signInWithGoogle, signInWithApple, signInWithKakao, signInWithEmail
    }
}

export default useSession
