import { Form, Formik, FormikProps } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useRef } from 'react'
import * as Yup from 'yup'

import { useUpdateScoreStrategyMutation } from '../../../hooks/use-score-strategy'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { useToast } from '../../../hooks/use-toast'
import { ScoreStrategy } from '../../../libs/model/score-strategy'
import { trans } from '../../../locales/ko'
import CheckboxOptionText from '../../checkbox/checkbox-option-text'
import EmptyStateTable from '../../empty-state/empty-state-table'
import { FieldCheckbox } from '../../field'
import { Slide, SlideOver } from '../../slide'


interface IFormBuilderGroupUpdater {
    subGroupIds: string[]
}

interface BuilderGroupUpdaterProps {
    open: boolean
    onClose: () => void
    scoreStrategy: ScoreStrategy
}

const BuilderGroupUpdater = ({ open, onClose, scoreStrategy }: BuilderGroupUpdaterProps) => {
    const formRef = useRef<FormikProps<IFormBuilderGroupUpdater> | null>(null)
    const { showToast } = useToast()
    
    const { subGroups } = useActiveStudyGroup()
    const subGroupOptions = useMemo(() => {
        return subGroups?.map(each => {
            return { value: each.id, id: each.id, label: each.name }
        })
    }, [subGroups])
    const { mutateAsync: updateScoreStrategy, isPending } = useUpdateScoreStrategyMutation()
    
    const initialValues: IFormBuilderGroupUpdater = { subGroupIds: scoreStrategy.subGroupIds }
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            subGroupIds: Yup.array()
        })
    }, [])
    const handleConfirm = useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }, [])
    
    const onSubmit = useCallback(async (values: IFormBuilderGroupUpdater) => {
        if (isPending) return
        
        try {
            await updateScoreStrategy({ strategyId: scoreStrategy.id, strategy: { ...scoreStrategy, ...values } })
            onClose()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [isPending, onClose, scoreStrategy, showToast, updateScoreStrategy])
    
    return (
        <SlideOver
            open={open}
            onClose={onClose}
            title={trans.views.dashboard.member_details.group.edit_title}
            confirm={trans.actions.save}
            disableConfirm={isEmpty(subGroupOptions)}
            handleConfirm={handleConfirm}
            isLoading={isPending}
        >
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={formRef}>
                {({ errors, touched, values }) => {
                    return (
                        <Slide.Content>
                            <Form>
                                {isEmpty(subGroupOptions) ? <EmptyStateTable content='그룹이 없습니다.'/> :
                                 <FieldCheckbox name='subGroupIds' options={subGroupOptions} as={CheckboxOptionText} className='space-y-3'/>}
                            </Form>
                        </Slide.Content>
                    )
                }}
            </Formik>
        </SlideOver>
    )
}

export default BuilderGroupUpdater