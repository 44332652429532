import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import { Badge } from '../badge'
import { DualActionModal, ModalProps } from './index'


interface DestructiveModalProps extends ModalProps {
    confirmCode: string
}

const DestructiveModal = ({
    confirmCode,
    ...props
}: DestructiveModalProps) => {
    const [code, setCode] = useState('')
    
    const accepted = useMemo(() => code.trim() === confirmCode, [code, confirmCode])
    
    const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        setCode(e.currentTarget.value)
    }, [])
    
    const handleConfirm = useCallback(() => {
        if (!accepted) return
        props.onConfirm?.call(this)
    }, [accepted, props.onConfirm])
    
    useEffect(() => {
        if (props.open) {
            setCode('')
        }
    }, [props.open])
    
    return (
        <DualActionModal
            type='alert'
            open={props.open} onClose={props.onClose}
            confirm={props.confirm} onConfirm={handleConfirm} disabled={!accepted}
            title={props.title} loading={props.loading}
            description={(
                <div className='flex flex-col items-center'>
                    <span>{props.description}</span>
                    
                    <div className='my-4 space-y-1'>
                        <p>{trans.components.modal.confirmation_guide}</p>
                        <Badge variant='red'>{confirmCode}</Badge>
                    </div>
                    
                    <input id='confirmCode' name='confirmCode' type='text'
                           className={classesOf(
                               'block w-full rounded-md border border-gray-300 py-2 px-3',
                               palette.field.text, palette.field.text_placeholder,
                               'shadow-sm',
                               'focus:ring-2 focus:ring-inset',
                               'focus:border-0',
                               palette.field.text_ring_focus,
                               'text-sm leading-6'
                           )}
                           autoFocus={true}
                           placeholder={confirmCode}
                           value={code} onChange={handleChange}
                    />
                </div>
            )}
        />
    )
}

export default DestructiveModal
