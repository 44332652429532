import React from 'react'

import { classesOf } from '../../libs/utils/classes-of'


interface ExplorerHeaderProps {
    title: string | React.ReactNode
    description?: string | React.ReactNode
    action?: React.ReactNode
    children?: React.ReactNode
    hasBorder?: boolean
}

const ExplorerHeader = ({ title, description, action, children, hasBorder = true }: ExplorerHeaderProps) => {
    return (
        <div className='mb-8'>
            <div className={classesOf(
                'flex justify-between items-center',
                hasBorder ? 'border-b border-gray-200' : '',
                'pb-3')}>
                <div>
                    <div className='font-semibold leading-7 text-lg text-gray-900'>{title}</div>
                    <div className={classesOf('text-sm leading-6 text-gray-500', 'whitespace-pre-line', 'mt-1')}>{description}</div>
                </div>
                {action ? action : null}
            </div>
            <div className={classesOf('text-sm leading-6')}>
                {children}
            </div>
        </div>
    )
}

export default ExplorerHeader
