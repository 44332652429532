import React, { useState } from 'react'

import { BuilderScoreStrategy, BuilderScoreStrategyUpdater } from '../../../../components/builder/score-strategy'
import { ButtonOutline, ButtonSolid } from '../../../../components/button'
import TableScoreStrategy from '../../../../components/table/table-score-strategy'
import { trans } from '../../../../locales/ko'


const ViewScoreStrategy = () => {
    const [openBuilder, setOpenBuilder] = useState(false)
    const [openUpdater, setOpenUpdater] = useState(false)
    
    return (
        <React.Fragment>
            <div className='flex items-center justify-end gap-4 -mt-12'>
                <ButtonOutline onClick={() => setOpenUpdater(true)} className='z-20'>{trans.actions.edit}</ButtonOutline>
                <ButtonSolid onClick={() => setOpenBuilder(true)} className='z-20'>{trans.actions.add}</ButtonSolid>
            </div>
            <div className='my-6'>
                <TableScoreStrategy/>
            </div>
            
            <BuilderScoreStrategy open={openBuilder} onClose={() => setOpenBuilder(false)}/>
            <BuilderScoreStrategyUpdater open={openUpdater} onClose={() => setOpenUpdater(false)}/>
        </React.Fragment>
    )
}

export default ViewScoreStrategy