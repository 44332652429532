import { useMutation, useQueryClient } from '@tanstack/react-query'

import { createSubGroup, editUsersOfGroup } from '../libs/firestore/sub-group-operation'
import { UsersInput } from '../libs/model/member'
import { useActiveStudyGroup } from './use-study-group'


export const useCreateSubGroupMutation = () => {
    const queryClient = useQueryClient()
    const { studyGroupId } = useActiveStudyGroup()
    
    return useMutation({
        mutationFn: ({ subGroupName, memberIds }: { subGroupName: string, memberIds?: string[] }) => createSubGroup({ studyGroupId, subGroupName, memberIds }),
        onSuccess: (data, variables) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['users'] })
            
            queryClient.setQueryData(['studyGroup', studyGroupId], data.studyGroup)
            queryClient.invalidateQueries({ queryKey: ['studyGroup', studyGroupId] })
        }
    })
}

export const useEditUsersOfGroupMutation = () => {
    const queryClient = useQueryClient()
    
    return useMutation({
        mutationFn: (input: UsersInput) => editUsersOfGroup(input),
        onSuccess: (data, variables, context) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['users'] })
            queryClient.invalidateQueries({ queryKey: ['studyGroup', variables.studyGroupId] })
        }
    })
}