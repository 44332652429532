import { Listbox, Transition } from '@headlessui/react'
import { isEqual } from 'lodash'
import React, { Fragment, useCallback, useEffect, useState } from 'react'

import { classesOf } from '../../libs/utils/classes-of'
import SelectorButtonText from './selector-button-text'
import SelectorLoaderText from './selector-loader-text'
import { SelectorOption } from './selector-option'
import SelectorOptionText from './selector-option-text'


interface SelectorProps<TValue> {
    value: TValue
    onChange?: (value: TValue) => void
    options: SelectorOption<TValue>[]
    className?: string
    containerClassName?: string
    optionAs?: React.ElementType
    buttonAs?: React.ElementType
    loaderAs?: React.ElementType
    inViewRef?: (node?: Element | null) => void
    disabled?: boolean
    loading?: boolean
}

const Selector = <TValue, >({
    className = '',
    containerClassName = '',
    optionAs: SelectorOptionComponent = SelectorOptionText,
    buttonAs: SelectorButtonComponent = SelectorButtonText,
    loaderAs: SelectorLoaderComponent = SelectorLoaderText,
    inViewRef,
    disabled,
    loading,
    ...props
}: SelectorProps<TValue>) => {
    const initial = props.options.find(option => isEqual(option.value, props.value)) || props.options[0]
    const [selected, setSelected] = useState<SelectorOption<TValue>>(initial)
    
    useEffect(() => {
        setSelected(initial)
        props.onChange?.call(this, initial?.value)
    }, [initial, props.onChange])
    
    const handleSelect = useCallback((value: TValue) => {
        const target = props.options.find(e => isEqual(e.value, value))
        if (target) {
            setSelected(target)
            props.onChange?.call(this, value)
        }
    }, [props])
    
    return (
        <Listbox value={props.value} onChange={handleSelect}>
            <div className={classesOf('relative text-sm', containerClassName)}>
                {
                    loading
                    ? <SelectorLoaderComponent/>
                    : <SelectorButtonComponent selected={selected} disabled={disabled}/>
                }
                
                <Transition
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <Listbox.Options className={classesOf(
                        'absolute z-10 mt-1',
                        'max-h-60 w-full',
                        'overflow-auto',
                        'rounded-md',
                        'bg-white',
                        'py-1',
                        'shadow-lg',
                        'ring-1 ring-black/5',
                        'focus:outline-none'
                    )}>
                        {props.options.map((option, index) => {
                            return (
                                <React.Fragment key={`${option.label}#${index}`}>
                                    <SelectorOptionComponent {...option}/>
                                    {index === props.options.length - 1 && <div ref={inViewRef}/>}
                                </React.Fragment>
                            )
                        })}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default Selector
