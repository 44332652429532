import { ChevronRightIcon } from '@heroicons/react/20/solid'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { BuilderContentGroupUpdater } from '../../../components/builder/content'
import ThreadRolePlay from '../../../components/builder/content/thread-role-play'
import { ButtonText } from '../../../components/button'
import { ExplorerEditorCategoryName, ExplorerEditorContentName } from '../../../components/explorer/editor/explorer-editor-content-info'
import ExplorerHeader from '../../../components/explorer/explorer-header'
import ExplorerTextField from '../../../components/explorer/explorer-text-field'
import { FallbackDashboard } from '../../../components/fallback'
import { useCustomPromptListQuery } from '../../../hooks/use-content'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { trans } from '../../../locales/ko'


const ViewContentDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { subGroups } = useActiveStudyGroup()
    
    const [openGroupUpdater, setOpenGroupUpdater] = useState(false)
    
    const { data, isLoading } = useCustomPromptListQuery()
    const content = useMemo(() => data?.result.find(each => each.id === id), [data?.result, id])
    const categoryName = useMemo(() => data?.categories.find(each => each.id === content?.categoryId)?.name ?? '', [content?.categoryId, data?.categories])
    
    if (isLoading) return <FallbackDashboard/>
    if (!content) return null
    return (
        <React.Fragment>
            <div className='space-y-6 px-4 sm:px-6 lg:px-8 pb-12'>
                <div className='w-full text-2xl font-semibold flex items-center gap-2'>
                    <p className='shrink-0 hover:font-bold cursor-pointer' onClick={() => navigate('/content')}>{trans.views.dashboard.content_detail.it}</p>
                    <ChevronRightIcon className='shrink-0 w-7 h-auto'/>
                    <p className='truncate'>{content.name}</p>
                </div>
                
                <ExplorerHeader title={trans.views.dashboard.content_detail.group}>
                    <div className='flex items-center justify-between py-3 gap-3'>
                        <span className='break-all'>{subGroups.filter(each => content.subGroupIds?.includes(each.id)).map(each => each.name).join(', ')}</span>
                        <ButtonText onClick={() => setOpenGroupUpdater(true)}>{trans.views.dashboard.content_detail.group_edit}</ButtonText>
                    </div>
                </ExplorerHeader>
                <ExplorerHeader title={trans.views.dashboard.content_detail.content_info}>
                    <ExplorerTextField label={trans.views.dashboard.content_detail.name}
                                       content={content.name}
                                       editor={(close) => <ExplorerEditorContentName content={content} categoryName={categoryName} close={close}/>}
                    />
                    <ExplorerTextField label={trans.views.dashboard.content_detail.category}
                                       content={categoryName}
                                       editor={(close) => <ExplorerEditorCategoryName content={content} categoryName={categoryName} close={close}/>}
                    />
                </ExplorerHeader>
                <ExplorerHeader title={trans.views.dashboard.content_detail.mission}>
                    <div className='grid grid-cols-7 p-3 border-b border-gray-200'>
                        <div className='col-span-1'/>
                        <div className='col-span-3'>{trans.views.dashboard.content_detail.expression}</div>
                        <div className='col-span-3'>{trans.views.dashboard.content_detail.meaning}</div>
                    </div>
                    {content.missionExpressions.map((mission, index) =>
                        <div key={`${mission.expression}-${mission.meaning}-${index}`} className='grid grid-cols-7 p-3 border-b border-gray-100 gap-x-4'>
                            <div className='col-span-1 break-all'>{index + 1}</div>
                            <div className='col-span-3 break-all'>{mission.expression}</div>
                            <div className='col-span-3 break-all'>{mission.meaning}</div>
                        </div>
                    )}
                </ExplorerHeader>
                <ExplorerHeader title={trans.components.builder.content.role_play_example}>
                    <ExplorerTextField label={'시나리오'}
                                       content={content?.scenario}
                    />
                    <ExplorerTextField label={'줄거리'}
                                       content={content?.setting}
                    />
                    <ExplorerTextField label={trans.components.builder.content.ai}
                                       content={`${content.character.name}, ${content.character.characteristic}`}
                    />
                    <ExplorerTextField label={trans.components.builder.content.learner}
                                       content={`${content.user.name}, ${content.user.characteristic}`}
                    />
        
                    <div className='space-y-4 mt-6'>
                        {content.promptUsages.map((chat, index) => <ThreadRolePlay key={`${chat.content}-${index}`} chat={chat}/>)}
                    </div>
                </ExplorerHeader>
            </div>
            
            <BuilderContentGroupUpdater open={openGroupUpdater} onClose={() => setOpenGroupUpdater(false)} content={content} categoryName={categoryName}/>
        </React.Fragment>
    )
}

export default ViewContentDetail