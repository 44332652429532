import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { Form, Formik, FormikProps } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'

import { useDeleteUsersFromSubGroupMutation } from '../../../hooks/use-group'
import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { useEditUsersOfGroupMutation } from '../../../hooks/use-sub-group'
import { useToast } from '../../../hooks/use-toast'
import { useUserQueries } from '../../../hooks/use-users'
import { Account, AccountState } from '../../../libs/model/account'
import { SubGroup } from '../../../libs/model/study-group'
import { classesOf } from '../../../libs/utils/classes-of'
import { trans } from '../../../locales/ko'
import EmptyStateTable from '../../empty-state/empty-state-table'
import FieldTextareaBulkData from '../../field/field-textarea-bulkData'
import { Slide, SlideOver } from '../../slide'
import { TabSimple } from '../../tab'


interface IFormBuilderSubGroupMember {
    bulkData: string
}

interface BuilderSubGroupMemberProps {
    open: boolean
    onClose: () => void
    subGroup: SubGroup | null
}

const BuilderSubGroupMember = ({ open, onClose, subGroup }: BuilderSubGroupMemberProps) => {
    const tabItems = useMemo(() => {
        return [
            { name: trans.views.dashboard.member.add, id: 'member-add' },
            { name: trans.views.dashboard.member.delete, id: 'member-delete' }
        ]
    }, [])
    
    const [activeTab, setActiveTab] = useState(tabItems[0].id)
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedPeople, setSelectedPeople] = useState<Account[]>([])
    
    const checkbox = useRef<HTMLInputElement>(null)
    const formRef = useRef<FormikProps<IFormBuilderSubGroupMember> | null>(null)
    
    const { showToastInline } = useToast()
    const { studyGroupId } = useActiveStudyGroup()
    
    const { mutateAsync: editUsersOfGroup, isPending: isAdding } = useEditUsersOfGroupMutation()
    const { data: members } = useUserQueries(subGroup?.members ?? [])
    const { mutateAsync: deleteUsersFromSubGroup, isPending: isDeleting } = useDeleteUsersFromSubGroupMutation()
    
    const validateBulkData = useCallback((bulkData: string) => {
        if (!bulkData) return { isValid: false, errorMessage: trans.components.builder.sub_group_member.error.bulk_data_required }
        
        const emailList = bulkData.trim().split('\n')
        
        const emailSet = new Set()
        const emailRegex = /^.+@.+\..+$/
        
        let isValid = true
        let errorMessage = ''
        
        for (let email of emailList) {
            const trimmedEmail = email.trim()
            if (emailSet.has(trimmedEmail)) {
                isValid = false
                errorMessage = `${trans.components.builder.sub_group_member.error.bulk_data_email_duplicate} ${email}`
                break
            }
            
            if (!emailRegex.test(trimmedEmail)) {
                isValid = false
                errorMessage = `${trans.components.builder.sub_group_member.error.bulk_data_email_invalid} ${email}`
                break
            }
            
            emailSet.add(trimmedEmail)
        }
        
        return { isValid, errorMessage }
    }, [])
    
    const parseBulkData = useCallback((bulkData: string) => {
        return bulkData.split('\n').map((email) => {
            const subGroupNames = subGroup ? [subGroup.name] : []
            return { email, subGroupNames }
        })
    }, [subGroup])
    
    useEffect(() => {
        if (isEmpty(members)) return
        
        setChecked(false)
        setIndeterminate(false)
        const allSelected = members.every(group => selectedPeople.includes(group))
        const someSelected = members.some(group => selectedPeople.includes(group))
        setChecked(allSelected)
        setIndeterminate(someSelected && !allSelected)
        
        if (checkbox.current) {
            checkbox.current.indeterminate = indeterminate
        }
    }, [selectedPeople, indeterminate, members])
    
    const toggleAll = useCallback(() => {
        const allSelected = members.every(group => selectedPeople.includes(group))
        
        if (allSelected) {
            setSelectedPeople(selectedPeople.filter(group => !members.includes(group)))
        } else {
            const newSelections = [...selectedPeople, ...members.filter(group => !selectedPeople.includes(group))]
            setSelectedPeople(newSelections)
        }
    }, [members, selectedPeople, setSelectedPeople])
    
    const initialValues: IFormBuilderSubGroupMember = { bulkData: '' }
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            bulkData: Yup.string()
        })
    }, [])
    
    const handleConfirm = useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }, [])
    
    const onSubmit = useCallback(async (values: IFormBuilderSubGroupMember) => {
        if (isAdding || isDeleting || isEmpty(subGroup)) return
        
        if (activeTab === 'member-add') {
            const { isValid, errorMessage } = validateBulkData(values.bulkData)
            if (!isValid) {
                showToastInline({ title: errorMessage, type: 'error' })
                return
            }
            const parsedData = parseBulkData(values.bulkData)
            
            try {
                await editUsersOfGroup({ studyGroupId: studyGroupId, bulkData: parsedData })
                onClose()
            } catch (e: any) {
                showToastInline({ title: e.message, type: 'error' })
            }
        } else {
            const userIds = selectedPeople.map(each => each.id)
            if(isEmpty(userIds)) return showToastInline({title: trans.views.dashboard.sub_group_detail.error.empty_member, type: 'error'})
            
            try {
                await deleteUsersFromSubGroup({ subGroupId: subGroup?.id, userIds })
                setSelectedPeople([])
                onClose()
            } catch (e: any) {
                showToastInline({ title: e.message, type: 'error' })
            }
        }
    }, [isAdding, isDeleting, subGroup, selectedPeople, activeTab, onClose, validateBulkData, parseBulkData, showToastInline, editUsersOfGroup, studyGroupId, deleteUsersFromSubGroup])
    
    const getAccountState = useCallback((state: AccountState) => {
        switch (state) {
            case AccountState.NONE:
                return trans.views.dashboard.member.none
            case AccountState.INVITED:
                return trans.views.dashboard.member.invited
            case AccountState.JOINED:
                return trans.views.dashboard.member.joined
            case AccountState.REJECTED:
                return trans.views.dashboard.member.rejected
            default:
                return trans.views.dashboard.member.invited
        }
    }, [])
    
    return (
        <SlideOver
            open={open}
            onClose={onClose}
            title={trans.components.builder.sub_group_member.title}
            handleConfirm={handleConfirm}
            confirm={activeTab === 'member-add' ? trans.actions.add : trans.actions.delete}
            slideWidth='wide'
            isLoading={isAdding || isDeleting}
        >
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={formRef}>
                {({ errors, touched, values, dirty }) => {
                    return (
                        <Slide.Content>
                            <Form>
                                <div className='mb-6'>
                                    <TabSimple items={tabItems} activeTab={activeTab} setActiveTab={setActiveTab}/>
                                </div>
                                {activeTab === 'member-add' ?
                                 <div className='space-y-6 pb-5'>
                                     <h1 className='font-semibold text-lg'>{trans.components.builder.sub_group_member.instruction}</h1>
                                     <div className='grid grid-cols-7 gap-2'>
                                         <div className='col-span-3 flex justify-center items-center'>
                                             <img className='ml-10' src='/assets/email_copy_sub_group.png' alt='member_copy_sub_group_excel'/>
                                         </div>
                                         <div className='col-span-1 flex items-center justify-center'>
                                             <ArrowLongRightIcon className='w-16 h-auto stroke-2 text-orange-500 animate-pulse'/>
                                         </div>
                                         <div className='col-span-3'>
                                             <FieldTextareaBulkData
                                                 id='bulkData'
                                                 name='bulkData'
                                                 className='font-mono'
                                                 placeholder={trans.components.builder.sub_group_member.placeholder}
                                                 cols={30}
                                                 rows={20}
                                             />
                                         </div>
                                     </div>
                                 </div> :
                                 <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                                     <div className='inline-block min-w-full py-2 align-middle sm:px-2 lg:px-4'>
                                         <div className='min-w-full table-fixed divide-y divide-gray-300'>
                                             <div>
                                                 <div className='w-full grid grid-cols-11'>
                                                     <div className='relative px-7 sm:w-12 sm:px-6'>
                                                         <input
                                                             type='checkbox'
                                                             className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                                             ref={checkbox}
                                                             checked={checked}
                                                             onChange={toggleAll}
                                                         />
                                                     </div>
                                                     <div className='pr-3 py-3.5 text-left text-sm font-semibold'/>
                                                     <div className='col-span-3 pr-3 py-3.5 text-left text-sm font-semibold'>{trans.views.dashboard.member.name}</div>
                                                     <div className='col-span-3 pr-3 py-3.5 text-left text-sm font-semibold'>{trans.views.dashboard.member.email}</div>
                                                     <div className='col-span-3 pr-3 py-3.5 text-left text-sm font-semibold'>{trans.views.dashboard.member.status}</div>
                                                 </div>
                                             </div>
                                             <div className='divide-y divide-gray-200 bg-white'>
                                                 {isEmpty(members) ? <EmptyStateTable content='멤버가 없습니다.'/> :
                                                  <React.Fragment>
                                                      {members?.map((user: Account, index) => (
                                                          <div key={user.id} className={classesOf(selectedPeople.includes(user) ? 'bg-gray-50' : undefined, 'w-full grid grid-cols-11')}>
                                                              <div className='relative px-7 sm:w-12 sm:px-6'>
                                                                  {selectedPeople.includes(user) && (
                                                                      <div className='absolute inset-y-0 left-0 w-0.5 bg-orange-500'/>
                                                                  )}
                                                                  <input
                                                                      type='checkbox'
                                                                      className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                                                      value={user.id}
                                                                      checked={selectedPeople.includes(user)}
                                                                      onChange={(e) =>
                                                                          setSelectedPeople(
                                                                              e.target.checked
                                                                              ? [...selectedPeople, user]
                                                                              : selectedPeople.filter((p) => p !== user)
                                                                          )
                                                                      }
                                                                  />
                                                              </div>
                                                              <div className='pr-3 py-4 text-sm break-words'>{index + 1}</div>
                                                              <div className='col-span-3 pr-3 py-4 text-sm break-words'>{user.realName}</div>
                                                              <div className='col-span-3 pr-3 py-4 text-sm text-gray-500 break-words'>{user.verifiedEmail || user.email}</div>
                                                              <div className='col-span-3 pr-3 py-4 text-sm text-gray-500 break-words'>{getAccountState(user.state)}</div>
                                                          </div>
                                                      ))}
                                                  </React.Fragment>
                                                 }
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                }
                            </Form>
                        </Slide.Content>
                    )
                }}
            </Formik>
        </SlideOver>
    )
}

export default BuilderSubGroupMember