import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'

import { useCustomPromptListQuery } from '../../hooks/use-content'
import { CustomPrompt } from '../../libs/model/content'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import { ButtonText } from '../button'
import EmptyStateTable from '../empty-state/empty-state-table'
import { FallbackDashboard } from '../fallback'


interface TableContentProps {
    selectedContent: CustomPrompt[]
    setSelectedContent: React.Dispatch<React.SetStateAction<CustomPrompt[]>>
}

const TableContent = ({ selectedContent, setSelectedContent }: TableContentProps) => {
    const navigate = useNavigate()
    
    const { data, isLoading } = useCustomPromptListQuery()
    const contentList = useMemo(() => data?.result ?? [], [data?.result])
    const categoryList = useMemo(() => data?.categories ?? [], [data?.categories])
    
    const [currentPage, setCurrentPage] = useState(0)
    const itemsPerPage = 10
    const currentContentList = useMemo(() => {
        const start = currentPage * itemsPerPage
        const end = start + itemsPerPage
        return contentList.slice(start, end)
    }, [currentPage, contentList])
    
    const checkbox = useRef<HTMLInputElement>(null)
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    
    useEffect(() => {
        if (!data || currentPage === 0) return
        if (isEmpty(currentContentList)) setCurrentPage(prev => Math.max(0, prev - 1))
    }, [currentContentList, currentPage, data])
    
    useEffect(() => {
        if (isEmpty(currentContentList)) return
        
        setChecked(false)
        setIndeterminate(false)
        const allSelected = currentContentList.every(group => selectedContent.includes(group))
        const someSelected = currentContentList.some(group => selectedContent.includes(group))
        setChecked(allSelected)
        setIndeterminate(someSelected && !allSelected)
        
        if (checkbox.current) {
            checkbox.current.indeterminate = indeterminate
        }
    }, [currentPage, currentContentList, selectedContent, indeterminate])
    
    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected)
    }
    
    const toggleAll = useCallback(() => {
        const allSelected = currentContentList.every(group => selectedContent.includes(group))
        
        if (allSelected) {
            setSelectedContent(selectedContent.filter(group => !currentContentList.includes(group)))
        } else {
            const newSelections = [...selectedContent, ...currentContentList.filter(group => !selectedContent.includes(group))]
            setSelectedContent(newSelections)
        }
    }, [currentContentList, selectedContent, setSelectedContent])
    
    if (isLoading) return <FallbackDashboard/>
    return (
        <div className='mt-8 flow-root'>
            <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='relative inline-block w-full min-h-[700px] py-2 align-middle sm:px-2 lg:px-4 space-y-12 mb-12'>
                    <div className='min-w-full table-fixed divide-y divide-gray-300'>
                        <div>
                            <div className='flex items-center ml-1'>
                                <div className='relative w-14 px-6'>
                                    <input
                                        type='checkbox'
                                        className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                        ref={checkbox}
                                        checked={checked}
                                        onChange={toggleAll}
                                    />
                                </div>
                                <div className='w-14 pr-3 py-4 text-left text-sm font-semibold text-gray-900'/>
                                <div className='w-full grid grid-cols-4'>
                                    <div className='pr-3 py-3.5 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.content.name}</div>
                                    <div className='pr-3 py-3.5 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.content.mission}</div>
                                    <div className='pr-3 py-3.5 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.content.category}</div>
                                    <div className='pr-3 py-3.5 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.content.detail}</div>
                                </div>
                            </div>
                        </div>
                        {isEmpty(currentContentList) ?
                         <EmptyStateTable content='컨텐츠가 없습니다.'/> :
                         <div className='divide-y divide-gray-200 bg-white'>
                             {currentContentList?.map((content: CustomPrompt, index) => {
                                 return (
                                     <div key={content.id} className={classesOf('flex items-center ml-1')}>
                                         <div className='relative w-14 px-6'>
                                             {selectedContent.includes(content) && <div className='absolute inset-y-0 left-0 w-0.5 bg-orange-500'/>}
                                             <input
                                                 type='checkbox'
                                                 className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                                 value={content.id}
                                                 checked={selectedContent.includes(content)}
                                                 onChange={(e) => setSelectedContent(e.target.checked ? [...selectedContent, content] : selectedContent.filter((each) => each !== content))}
                                             />
                                         </div>
                                         <div className='w-14 pr-3 py-4 text-sm break-words'>{currentPage * itemsPerPage + index + 1}</div>
                                         <div className='w-full grid grid-cols-4'>
                                             <div className='whitespace-nowrap truncate pr-3 py-4 text-sm'>{content.name}</div>
                                             <div className='whitespace-nowrap truncate pr-3 py-4 text-sm'>
                                                 {content.missionExpressions.map(each => each.expression).join(', ')}
                                             </div>
                                             <div className='whitespace-nowrap truncate pr-3 py-4 text-sm'>
                                                 {categoryList.find(each => each.id === content.categoryId)?.name}
                                             </div>
                                             <div className='whitespace-nowrap truncate pr-3 py-4 text-sm text-gray-500'>
                                                 <ButtonText onClick={() => navigate(`/content/${content.id}`)} variant='gray'>{trans.views.dashboard.setting.score_strategy.button_details}</ButtonText>
                                             </div>
                                         </div>
                                     </div>
                                 )
                             })}
                         </div>
                        }
                    </div>
                    <div className='absolute left-1/2 -bottom-5 transform -translate-x-1/2'>
                        <ReactPaginate
                            className='mt-8 flex items-center justify-center gap-10'
                            previousLabel={trans.actions.prev}
                            nextLabel={trans.actions.next}
                            breakLabel={'...'}
                            pageCount={Math.ceil(contentList.length / itemsPerPage) || 1}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableContent