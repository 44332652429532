import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'

import { ButtonSolid } from '../../components/button'
import { useActiveStudyGroup } from '../../hooks/use-study-group'
import { trans } from '../../locales/ko'


const ViewDashboard: React.FC = () => {
    const { studyGroup, subGroups } = useActiveStudyGroup()
    
    const remainingInvitations = useMemo(() => {
        if (!studyGroup) return
        return studyGroup?.userLimit - studyGroup?.members.length
    }, [studyGroup])
    
    const statistics = useMemo(() => [
        { name: trans.keywords.learner, value: !isEmpty(studyGroup?.members) ? studyGroup?.members.length : 0 },
        { name: trans.keywords.manager, value: !isEmpty(studyGroup?.admins) ? studyGroup?.admins.length : 0 },
        { name: trans.views.dashboard.summary.remaining_invitations, value: remainingInvitations ?? 0 },
        { name: trans.keywords.group, value: !isEmpty(subGroups) ? subGroups?.length : 0 }
    ] ?? [], [remainingInvitations, studyGroup?.admins, studyGroup?.members, subGroups])
    
    return (
        <React.Fragment>
            <div className='px-4 sm:px-6 lg:px-8 w-2/3'>
                <div className='sm:flex-auto'>
                    <h1 className='text-2xl font-semibold leading-6 text-gray-900'>{trans.views.dashboard.summary.it}</h1>
                </div>
                
                <ButtonSolid className='mt-5 px-5'>{trans.views.dashboard.summary.invitation_link}</ButtonSolid>
                <div className='mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4'>
                    {statistics.map((each) => (
                        <div key={each.name} className='rounded-xl bg-gray-50 px-4 py-5 sm:p-6'>
                            <p className='truncate text-sm font-medium'>{each.name}</p>
                            {studyGroup ?
                             <p className='mt-1 text-3xl font-semibold tracking-tight text-gray-900 break-words'>{each.value}</p> :
                             <p className='mt-3 animate-pulse bg-gray-200 w-full h-8 rounded-md'/>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}

export { ViewDashboard }