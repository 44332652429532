import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import FirebaseProvider from './contexts/firebase'
import { AuthProvider } from './contexts/session'
import { FirestoreProvider } from './contexts/session/session-provider'
import StudyGroupProvider from './contexts/study-group'
import DashboardRoutes from './routes'


declare global {
    interface Window {
        Kakao: any;
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 300_000
        }
    }
})

function ChitchatDashboardApp() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <FirebaseProvider>
                    <AuthProvider>
                        <FirestoreProvider>
                            <StudyGroupProvider>
                                <HelmetProvider>
                                    <DashboardRoutes/>
                                </HelmetProvider>
                            </StudyGroupProvider>
                        </FirestoreProvider>
                    </AuthProvider>
                </FirebaseProvider>
                <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left'/>
            </QueryClientProvider>
        </BrowserRouter>
    )
}

export default ChitchatDashboardApp
