import isEmpty from 'lodash/isEmpty'
import React, { useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { useUserQueries } from '../../hooks/use-users'
import { LearningHistoryData, LearningHistoryPerDay } from '../../libs/model/learning-history'
import { classesOf } from '../../libs/utils/classes-of'
import { msToHoursMinutesAndSeconds } from '../../libs/utils/fomatter-time'
import { trans } from '../../locales/ko'


interface TableLearningHistoryGroupProps {
    learningHistoryByWeek: LearningHistoryPerDay[][]
    weekIndex: number
    members: string[]
    filters: string[]
}

const TableLearningHistoryGroup = ({ learningHistoryByWeek, weekIndex, members, filters }: TableLearningHistoryGroupProps) => {
    const membersWeekData = useMemo(() => {
        return learningHistoryByWeek?.map((userData) => userData[weekIndex])
    }, [learningHistoryByWeek, weekIndex])
    const days = useMemo(() => Object.keys(membersWeekData[0]) ?? [], [membersWeekData])
    
    const { data: memberList } = useUserQueries(members)
    const realNameList = useMemo(() => memberList.map(each => each.realName || each.nickName), [memberList])
    
    const calculateAverage = (data: LearningHistoryData[]) => {
        if (isEmpty(data)) return {}
        const sum = data.reduce((acc: any, curr: any) => ({
            accuracyPoint: acc.accuracyPoint + curr.accuracyPoint,
            fluencyPoint: acc.fluencyPoint + curr.fluencyPoint,
            missionPoint: acc.missionPoint + curr.missionPoint,
            assistantPoint: acc.assistantPoint + curr.assistantPoint,
            levelPoint: acc.levelPoint + curr.levelPoint,
            timeInMs: acc.timeInMs + curr.timeInMs,
            count: acc.count + 1
        }), { accuracyPoint: 0, fluencyPoint: 0, missionPoint: 0, assistantPoint: 0, levelPoint: 0, timeInMs: 0, count: 0 })
        
        return {
            accuracyPoint: (sum.accuracyPoint / sum.count).toFixed(1),
            fluencyPoint: (sum.fluencyPoint / sum.count).toFixed(1),
            missionPoint: (sum.missionPoint / sum.count).toFixed(1),
            assistantPoint: (sum.assistantPoint / sum.count).toFixed(1),
            levelPoint: (sum.levelPoint / sum.count),
            timeInMs: msToHoursMinutesAndSeconds(sum.timeInMs / sum.count)
        }
    }
    
    const [currentPage, setCurrentPage] = useState(0)
    const itemsPerPage = 10
    const currentList = useMemo(() => {
        const start = currentPage * itemsPerPage
        const end = start + itemsPerPage
        return membersWeekData.slice(start, end)
    }, [currentPage, membersWeekData])
    
    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected)
    }
    
    return (
        <div className='relative'>
            <h2 className='my-5 text-lg font-semibold'>{`${weekIndex + 1}주차`}</h2>
            <div className='overflow-x-scroll space-y-12 mb-10 border border-gray-200'>
                <div className='rounded-md min-w-max divide-y divide-gray-100'>
                    <div className='grid grid-flow-col'>
                        {days.map((each, index) =>
                            <div key={index} className='divide-y divide-gray-200'>
                                <p className={classesOf('px-6 py-4 text-center text-xs font-semibold border-r border-gray-200')}>{days[index]}</p>
                                <div className={classesOf(index === 0 ? `grid-cols-custom-${filters.length + 1}` : `grid-cols-custom-${filters.length || 1}`, 'grid text-center text-sm font-semibold border-r border-gray-200 divide-x divide-gray-200')}>
                                    {index === 0 && (<p className='px-6 py-4 break-all sticky left-0 z-50 bg-white'>{trans.views.dashboard.member_details.name}</p>)}
                                    {[
                                        <p id='accuracyPoint' key='accuracyPoint' className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.accuracyPoint}</p>,
                                        <p id='fluencyPoint' key='fluencyPoint' className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.fluencyPoint}</p>,
                                        <p id='missionPoint' key='missionPoint' className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.missionPoint}</p>,
                                        <p id='assistantPoint' key='assistantPoint' className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.assistantPoint}</p>,
                                        <p id='levelPoint' key='levelPoint' className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.levelPoint}</p>,
                                        <p id='conversation_time' key='conversation_time' className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.conversation_time}</p>,
                                        <p id='learning_count' key='learning_count' className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.learning_count}</p>,
                                        <p id='memo_count' key='memo_count' className='px-6 py-4'>{trans.views.dashboard.member_details.learning_statistics.memo_count}</p>
                                    ].filter(each => filters.includes(each.props.id))}
                                </div>
                            </div>
                        )}
                    </div>
                    {currentList?.map((members, index) => {
                        return <div key={index} className='grid grid-flow-col'>
                            {Object.values(members).map(((each, dayIndex) => {
                                const averages = calculateAverage(each)
                                const memoCount = each.filter((data) => data.memo).length
                                return (
                                    <div key={dayIndex} className={classesOf(dayIndex === 0 ? `grid-cols-custom-${filters.length + 1}` : `grid-cols-custom-${filters.length || 1}`, 'grid text-center text-sm text-gray-700 font-semibold border-r border-gray-200 divide-x divide-gray-200')}>
                                        {dayIndex === 0 && (<p className='px-6 py-4 text-gray-900 break-all sticky left-0 z-10 bg-white flex items-center justify-center'>{realNameList[currentPage * itemsPerPage + index] ?? ''}</p>)}
                                        {[
                                            <p id='accuracyPoint' key='accuracyPoint' className='px-6 py-4 break-all flex items-center justify-center'>{averages.accuracyPoint ?? '-'}</p>,
                                            <p id='fluencyPoint' key='fluencyPoint' className='px-6 py-4 break-all flex items-center justify-center'>{averages.fluencyPoint ?? '-'}</p>,
                                            <p id='missionPoint' key='missionPoint' className='px-6 py-4 break-all flex items-center justify-center'>{averages.missionPoint ?? '-'}</p>,
                                            <p id='assistantPoint' key='assistantPoint' className='px-6 py-4 break-all flex items-center justify-center'>{averages.assistantPoint ?? '-'}</p>,
                                            <p id='levelPoint' key='levelPoint' className='px-6 py-4 break-all flex items-center justify-center'>{averages.levelPoint ?? '-'}</p>,
                                            <p id='conversation_time' key='conversation_time' className='px-6 py-4 break-all flex items-center justify-center'>{averages.timeInMs ?? '-'}</p>,
                                            <p id='learning_count' key='learning_count' className='px-6 py-4 break-all flex items-center justify-center'>{!isEmpty(each) ? each.length : '-'}</p>,
                                            <p id='memo_count' key='memo_count' className='px-6 py-4 break-all flex items-center justify-center'>{!isEmpty(each) ? memoCount : '-'}</p>
                                        ].filter(each => filters.includes(each.props.id))}
                                    </div>
                                )
                            }))}
                        </div>
                    })}
                </div>
            </div>
            <div className='absolute left-1/2 transform -translate-x-1/2'>
                <ReactPaginate
                    className='my-8 flex items-center justify-center gap-10'
                    previousLabel={trans.actions.prev}
                    nextLabel={trans.actions.next}
                    breakLabel={'...'}
                    pageCount={Math.ceil(membersWeekData.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    )
}

export default TableLearningHistoryGroup