import isEmpty from 'lodash/isEmpty'
import React, { useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { LearningHistoryData } from '../../libs/model/learning-history'
import { msToHoursMinutesAndSeconds } from '../../libs/utils/fomatter-time'
import { trans } from '../../locales/ko'
import { ButtonText } from '../button'
import { ThreadChatContent } from '../thread'


interface TableLearningSessionProps {
    learningSessions: LearningHistoryData[]
}

const TableLearningSession = ({ learningSessions }: TableLearningSessionProps) => {
    const [openThread, setOpenThread] = useState(false)
    const [learningSession, setLearningSession] = useState<LearningHistoryData | null>(null)
    const [currentPage, setCurrentPage] = useState(0)
    const itemsPerPage = 10
    
    const currentLearningSessions = useMemo(() => {
        const start = currentPage * itemsPerPage
        const end = start + itemsPerPage
        return learningSessions.slice(start, end)
    }, [currentPage, learningSessions])
    
    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected)
    }
    
    if (isEmpty(learningSessions)) return <div className='mt-6 text-base font-semibold'>{trans.views.dashboard.member_details.learning_statistics.empty}</div>
    return (
        <React.Fragment>
            <div className='flow-root space-y-6 px-4 sm:px-6 lg:px-8'>
                <div className='-mx-4 -my-2 sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2'>
                        <div className='min-w-full divide-y divide-gray-300'>
                            <div className='grid grid-cols-18 items-center text-center'>
                                <div className='col-span-2 py-3.5 pr-3 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.date}</div>
                                <div className='col-span-2 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.subject}</div>
                                <div className='col-span-2 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.reflection}</div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.accuracyPoint}</div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.fluencyPoint}</div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.missionPoint}</div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.assistantPoint}</div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.levelPoint}</div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.score}</div>
                                <div className='col-span-2 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.conversation_time}</div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.sentence_word}</div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.grammar_error}</div>
                                <div className='col-span-2 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member_details.learning_statistics.conversation_history}</div>
                            </div>
                            <div className='divide-y divde-gray-100'>
                                {currentLearningSessions.map((each, index) => {
                                    return (
                                        <div key={index} className='grid grid-cols-18 items-center text-center'>
                                            <div className='col-span-2 whitespace-normal break-all pr-3 py-4 text-sm'>{each.date}</div>
                                            <div className='col-span-2 whitespace-normal break-all pr-3 py-4 text-sm'>{each.topicId ?? '-'}</div>
                                            <div className='col-span-2 whitespace-normal break-all pr-3 py-4 text-sm'>{each.memo || '-'}</div>
                                            <div className='whitespace-normal break-all pr-3 py-4 text-sm'>{each.accuracyPoint.toFixed(1) ?? '-'}</div>
                                            <div className='whitespace-normal break-all pr-3 py-4 text-sm'>{each.fluencyPoint.toFixed(1) ?? '-'}</div>
                                            <div className='whitespace-normal break-all pr-3 py-4 text-sm'>{each.missionPoint.toFixed(1) ?? '-'}</div>
                                            <div className='whitespace-normal break-all pr-3 py-4 text-sm'>{each.assistantPoint.toFixed(1) ?? '-'}</div>
                                            <div className='whitespace-normal break-all pr-3 py-4 text-sm'>{each.levelPoint ?? '-'}</div>
                                            <div className='whitespace-normal break-all pr-3 py-4 text-sm'>{each.score.toFixed(1) ?? '-'}</div>
                                            <div className='col-span-2 whitespace-normal break-all pr-3 py-4 text-sm'>{msToHoursMinutesAndSeconds(each.timeInMs) ?? '-'}</div>
                                            <div className='whitespace-normal break-all pr-3 py-4 text-sm'>{`${each.sentences}/${each.words}` ?? '-'}</div>
                                            <div className='whitespace-normal break-all pr-3 py-4 text-sm'>{each.grammarFeedbackCount ?? '-'}</div>
                                            <div className='col-span-2 whitespace-normal break-all pr-3 py-4 text-sm flex justify-center items-center'>
                                                <ButtonText onClick={() => {
                                                    setLearningSession(each)
                                                    setOpenThread(true)
                                                }}
                                                >
                                                    {trans.views.dashboard.member_details.learning_statistics.conversation_history_button}
                                                </ButtonText>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactPaginate
                className='my-16 flex items-center justify-center gap-10'
                previousLabel={trans.actions.prev}
                nextLabel={trans.actions.next}
                breakLabel={'...'}
                pageCount={Math.ceil(learningSessions.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
            <ThreadChatContent
                open={openThread}
                onClose={() => setOpenThread(false)}
                title={trans.views.dashboard.member_details.thread.title}
                learningData={learningSession}
            />
        </React.Fragment>
    )
}

export default TableLearningSession