import React from 'react'

import { trans } from '../../../locales/ko'
import { ButtonOutline, ButtonSolid, ButtonText } from '../../button'


export interface SlideOverActionsProps {
    cancel?: string
    handleCancel?: () => void
    showCancel?: boolean
    
    confirm?: string
    handleConfirm?: () => void
    disableConfirm?: boolean
    showConfirm?: boolean
    
    destructive?: string
    handleDestructive?: () => void
    showDestructive?: boolean
    
    isLoading?: boolean
    onClose: () => void
}

const SlideOverActions = ({
    cancel, handleCancel, showCancel = true,
    confirm, handleConfirm, disableConfirm = false, showConfirm = true,
    destructive, handleDestructive, showDestructive = false,
    isLoading = false,
    onClose
}: SlideOverActionsProps) => {
    return (
        <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
            <div className='flex justify-end space-x-3'>
                {showDestructive && (
                    <ButtonText
                        variant={isLoading ? 'disabled' : 'alert'}
                        onClick={handleDestructive ? handleDestructive : onClose}
                        className='mr-4'
                    >
                        {destructive ?? trans.actions.delete}
                    </ButtonText>
                )}
                {showCancel && (
                    <ButtonOutline
                        variant={isLoading ? 'disabled' : 'default'}
                        onClick={handleCancel ? handleCancel : onClose}
                    >
                        {cancel ?? trans.actions.cancel}
                    </ButtonOutline>
                )}
                {showConfirm && (
                    <ButtonSolid
                        variant={disableConfirm ? 'disabled' : 'default'}
                        onClick={!disableConfirm && handleConfirm ? handleConfirm : undefined}
                        loading={isLoading}
                    >
                        {confirm ?? trans.actions.confirm}
                    </ButtonSolid>
                )}
            </div>
        </div>
    )
}

export default SlideOverActions
