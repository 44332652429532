import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'

import { useActiveStudyGroup } from '../../hooks/use-study-group'
import { useUserListQuery } from '../../hooks/use-users'
import { Account, AccountState } from '../../libs/model/account'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import { ButtonText } from '../button'
import EmptyStateTable from '../empty-state/empty-state-table'
import { FallbackDashboard } from '../fallback'


interface TableMemberLearnerProps {
    userList: Account[]
    setUserList: React.Dispatch<React.SetStateAction<Account[]>>
    selectedPeople: Account[]
    setSelectedPeople: React.Dispatch<React.SetStateAction<Account[]>>
    itemsPerPage: number
}

const TableMemberLearner = ({ selectedPeople, setSelectedPeople, itemsPerPage, userList, setUserList }: TableMemberLearnerProps) => {
    const checkbox = useRef<HTMLInputElement>(null)
    
    const [currentPage, setCurrentPage] = useState(0)
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [total, setTotal] = useState(itemsPerPage)
    
    const navigate = useNavigate()
    const { subGroups } = useActiveStudyGroup()
    const { data, isLoading } = useUserListQuery(currentPage, itemsPerPage)
    
    userList = useMemo(() => data?.result ?? [], [data?.result])
    const totalLength = useMemo(() => data?.total || total, [data?.total, total])
    
    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected)
    }
    
    useEffect(() => {
        if (!data) return
        setUserList(userList)
        setTotal(data.total)
    }, [data, userList, setUserList])
    
    useEffect(() => {
        if (!data || currentPage === 0) return
        if (isEmpty(userList)) setCurrentPage(prev => Math.max(0, prev - 1))
    }, [currentPage, data, userList])
    
    useEffect(() => {
        if (isEmpty(userList)) return
        
        setChecked(false)
        setIndeterminate(false)
        const allSelected = userList.every(group => selectedPeople.includes(group))
        const someSelected = userList.some(group => selectedPeople.includes(group))
        setChecked(allSelected)
        setIndeterminate(someSelected && !allSelected)
        
        if (checkbox.current) {
            checkbox.current.indeterminate = indeterminate
        }
    }, [currentPage, userList, selectedPeople, indeterminate])
    
    const toggleAll = useCallback(() => {
        const allSelected = userList.every(user => selectedPeople.includes(user))
        
        if (allSelected) {
            setSelectedPeople(selectedPeople.filter(user => !userList.includes(user)))
        } else {
            const newSelections = [...selectedPeople, ...userList.filter(user => !selectedPeople.includes(user))]
            setSelectedPeople(newSelections)
        }
    }, [selectedPeople, setSelectedPeople, userList])
    
    const getAccountState = useCallback((state: AccountState) => {
        switch (state) {
            case AccountState.NONE:
                return trans.views.dashboard.member.none
            case AccountState.INVITED:
                return trans.views.dashboard.member.invited
            case AccountState.JOINED:
                return trans.views.dashboard.member.joined
            case AccountState.REJECTED:
                return trans.views.dashboard.member.rejected
            default:
                return trans.views.dashboard.member.invited
        }
    }, [])
    
    return (
        <div className='mt-8 flow-root'>
            <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='relative inline-block min-w-full min-h-[700px] py-2 align-middle sm:px-2 lg:px-4 space-y-12 mb-12'>
                    <div className='min-w-full table-fixed divide-y divide-gray-300'>
                        <div>
                            <div className='w-full grid grid-cols-18'>
                                <div className='relative px-7 sm:w-12 sm:px-6'>
                                    <input
                                        type='checkbox'
                                        className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                        ref={checkbox}
                                        checked={checked}
                                        onChange={toggleAll}
                                    />
                                </div>
                                <div className='pr-3 py-3.5 text-sm font-semibold'/>
                                <div className='col-span-3 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member.name}</div>
                                <div className='col-span-5 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member.email}</div>
                                <div className='col-span-2 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member.status}</div>
                                <div className='col-span-4 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member.group}</div>
                                <div className='col-span-2 pr-3 py-3.5 text-sm font-semibold'>{trans.views.dashboard.member.details}</div>
                            </div>
                        </div>
                        <div className='divide-y divide-gray-200 bg-white'>
                            {isLoading ?
                             <div className='flex absolute left-1/2 transform -translate-x-1/2'>
                                 <FallbackDashboard/>
                             </div> :
                             <React.Fragment>
                                 {isEmpty(userList) ? <EmptyStateTable content='멤버가 없습니다.'/> :
                                  <React.Fragment>
                                      {userList?.map((user: Account, index) => (
                                          <div key={user.id} className={classesOf(selectedPeople.includes(user) ? 'bg-gray-50' : undefined, 'w-full grid grid-cols-18')}>
                                              <div className='relative px-7 sm:w-12 sm:px-6'>
                                                  {selectedPeople.includes(user) && (
                                                      <div className='absolute inset-y-0 left-0 w-0.5 bg-orange-500'/>
                                                  )}
                                                  <input
                                                      type='checkbox'
                                                      className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500 cursor-pointer'
                                                      value={user.id}
                                                      checked={selectedPeople.includes(user)}
                                                      onChange={(e) =>
                                                          setSelectedPeople(
                                                              e.target.checked
                                                              ? [...selectedPeople, user]
                                                              : selectedPeople.filter((p) => p !== user)
                                                          )
                                                      }
                                                  />
                                              </div>
                                              <div className='pr-3 py-4 text-sm break-words'>{currentPage * itemsPerPage + index + 1}</div>
                                              <div className='col-span-3 truncate whitespace-nowrap pr-3 py-4 text-sm'>{user.realName}</div>
                                              <div className='col-span-5 truncate whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>{user.verifiedEmail || user.email}</div>
                                              <div className='col-span-2 truncate whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>{getAccountState(user.state)}</div>
                                              <div className='col-span-4 truncate whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>
                                                  {subGroups
                                                      .filter(sub => sub.members.includes(user.id))
                                                      .map(sub => sub.name)
                                                      .join(', ')}
                                              </div>
                                              
                                              <div className='col-span-2 whitespace-nowrap pr-3 py-4 text-sm text-gray-500'>
                                                  <ButtonText onClick={() => navigate(`/member/${user.id}`)}>{trans.views.dashboard.member.button_details}</ButtonText>
                                              </div>
                                          </div>
                                      ))}
                                  </React.Fragment>
                                 }
                             </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className='absolute left-1/2 -bottom-5 transform -translate-x-1/2'>
                        <ReactPaginate
                            key={`paginate-${currentPage}`}
                            className='mt-8 flex items-center justify-center gap-10'
                            previousLabel={trans.actions.prev}
                            nextLabel={trans.actions.next}
                            breakLabel={'...'}
                            pageCount={Math.ceil(totalLength / itemsPerPage) || 1}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={currentPage}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableMemberLearner