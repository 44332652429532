import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'

import { useActiveStudyGroup } from '../../../hooks/use-study-group'
import { trans } from '../../../locales/ko'
import { ButtonSolid } from '../../button'
import { FieldCheckbox, FieldText } from '../../field'
import { StepProps } from '../index'
import { BuilderContentFormikProps } from './builder-content'


const StepContentInfo = ({ errors, touched, onNext, dirty }: BuilderContentFormikProps & StepProps) => {
    const { subGroups } = useActiveStudyGroup()
    
    const subGroupOptions = useMemo(() => {
        return subGroups?.map(each => {
            return { value: each.id, id: each.id, label: each.name }
        })
    }, [subGroups])
    
    const disabled = useMemo(() => {
        if (!dirty) return true
        if (errors.name) return true
        if (errors.subGroupIds) return true
        if (errors.categoryName) return true
    }, [dirty, errors.categoryName, errors.name, errors.subGroupIds])
    
    return (
        <React.Fragment>
            <div className='space-y-6 pb-5'>
                <div className='sm:max-w-sm'>
                    <label htmlFor='name' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                        {trans.components.builder.content.name}
                    </label>
                    <FieldText
                        id='name' name='name'
                        error={errors.name && touched.name}
                        placeholder={trans.components.builder.content.name}
                        autoFocus
                    />
                </div>
                <div className='sm:max-w-sm'>
                    <label htmlFor='subGroupIds' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                        {trans.components.builder.content.sub_group}
                    </label>
                    {isEmpty(subGroupOptions) ?
                     <div className='flex flex-col items-center gap-3'>
                         <img src='/assets/empty_state.png' className='h-20'/>
                         <p className='text-gray-300 font-semibold text-xl'>그룹이 없습니다.</p>
                     </div> :
                     <FieldCheckbox name='subGroupIds' options={subGroupOptions} className='space-y-3 text-sm max-h-52 overflow-y-scroll p-3 rounded-md border border-gray-300 shadow-sm'/>
                    }
                </div>
                <div className='sm:max-w-sm'>
                    <label htmlFor='categoryName' className='block text-base font-semibold leading-7 text-gray-900 mb-2'>
                        {trans.components.builder.content.category_name}
                    </label>
                    <FieldText
                        id='categoryName' name='categoryName'
                        error={errors.categoryName && touched.categoryName}
                        placeholder={trans.components.builder.content.category_name}
                    />
                </div>
                
                <div className='flex gap-3 mt-6'>
                    <ButtonSolid onClick={onNext} variant={disabled ? 'disabled' : 'default'}>{trans.actions.next}</ButtonSolid>
                </div>
            </div>
        </React.Fragment>
    )
}

export default StepContentInfo