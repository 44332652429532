import { useMutation, useQueryClient } from '@tanstack/react-query'

import { addMembers } from '../libs/firestore/member-operation'
import { UsersInput } from '../libs/model/member'


export const useAddUsersMutation = () => {
    const queryClient = useQueryClient()
    
    return useMutation({
        mutationFn: (input: UsersInput) => addMembers(input),
        onSuccess: (data, variables, context) => {
            if (!data) return
            
            queryClient.invalidateQueries({ queryKey: ['users'] })
            queryClient.invalidateQueries({ queryKey: ['studyGroup', variables.studyGroupId] })
        }
    })
}