import React from 'react'

import { classesOf } from '../../libs/utils/classes-of'
import CheckboxOptionCard from './checkbox-option-card'
import { CheckboxOption } from './index'


export interface CheckboxProps<TValue> {
    options: CheckboxOption<TValue>[]
    value: TValue[]
    onChange: (value: TValue) => Promise<void>
    as?: React.ElementType
    className?: string
    inViewRef?: (node?: Element | null) => void
}

const Checkbox = <TValue, >({
    onChange,
    as: OptionComponent = CheckboxOptionCard,
    className = '',
    inViewRef,
    ...props
}: CheckboxProps<TValue>) => {
    return (
        <ul className={classesOf(className)}>
            {props.options.map((option, index) => (
                <React.Fragment key={index}>
                    <OptionComponent option={option} checked={props.value.includes(option.value)} onChange={() => onChange(option.value)}/>
                    {index === props.options.length - 1 && <div ref={inViewRef}/>}
                </React.Fragment>
            ))}
        </ul>
    )
}

export default Checkbox
