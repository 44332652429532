import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { Form, Formik } from 'formik'
import React, { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { ButtonSolid, ButtonText } from '../../components/button'
import { FieldText } from '../../components/field'
import LogoVertical from '../../components/logo/logo-vertical'
import useSession from '../../hooks/use-session'
import { useToast } from '../../hooks/use-toast'
import { trans } from '../../locales/ko'


interface IFormSignIn {
    email: string
    password: string
}

const ViewEmailAuth = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { showToast } = useToast()
    const navigate = useNavigate()
    const { signInWithEmail } = useSession()
    
    const initialValues: IFormSignIn = { email: '', password: '' }
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            email: Yup.string().required(trans.views.auth.sign_in.error.email_required),
            password: Yup.string().required(trans.views.auth.sign_in.error.password_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormSignIn) => {
        try {
            setIsLoading(true)
            await signInWithEmail({ ...values })
            navigate('/dashboard', { replace: true })
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        } finally {
            setIsLoading(false)
        }
    }, [navigate, showToast, signInWithEmail])
    
    return (
        <React.Fragment>
            <Helmet>
                <title>{trans.views.auth.sign_in.title} | {trans.meta.adrop}</title>
            </Helmet>
            
            <div className='sm:mx-auto sm:w-full sm:max-w-sm whitespace-pre-wrap'>
                <LogoVertical to='/' label={trans.views.auth.sign_in.title}/>
            </div>
            
            <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ errors, touched }) => (
                        <Form className='space-y-6'>
                            <div>
                                <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
                                    {trans.views.auth.sign_in.label_email}
                                </label>
                                <div className='relative mt-2'>
                                    <FieldText
                                        id='email'
                                        name='email'
                                        type='email'
                                        autoComplete='email'
                                        error={errors.email && touched.email}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='flex items-center justify-between'>
                                    <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>
                                        {trans.views.auth.sign_in.label_password}
                                    </label>
                                </div>
                                <div className='relative mt-2'>
                                    <FieldText
                                        id='password'
                                        name='password'
                                        type='password'
                                        autoComplete='password'
                                        error={errors.password && touched.password}
                                    />
                                </div>
                            </div>
                            <div className='space-y-2'>
                                <ButtonSolid type='submit' loading={isLoading} size='expand'>
                                    {trans.views.auth.sign_in.it}
                                </ButtonSolid>
                            </div>
                            <div className='text-sm mt-5'>
                                <ButtonText to='/'>
                                    <ArrowLeftIcon className='w-4 h-4 mr-3'/>
                                    <span> {trans.views.auth.sign_in.social_sign_in}</span>
                                </ButtonText>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}

export default ViewEmailAuth