import { cva, VariantProps } from 'class-variance-authority'
import React, { ButtonHTMLAttributes, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import Spinner from '../spinner'
import { ButtonProps } from './index'


const ButtonCircularVariants = cva(
    `rounded-full
    flex justify-center items-center
    p-2
    text-sm font-semibold leading-6
    shadow-sm
    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`,
    {
        variants: {
            variant: {
                alert: Object.values(palette.button.solid.alert),
                // FIXME Styles
                success: Object.values(palette.button.solid.default),
                disabled: Object.values(palette.button.solid.disabled),
                default: Object.values(palette.button.solid.default)
            },
            shadow: {
                md: 'shadow-md'
            }
        },
        defaultVariants: {
            variant: 'default'
        }
    }
)

interface ButtonCircularProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'onClick'>, VariantProps<typeof ButtonCircularVariants>, ButtonProps {
}

const ButtonCircular = ({
    children,
    type = 'button',
    className = '',
    onClick, href, to, loading, navigateOptions,
    variant,
    ...props
}: ButtonCircularProps) => {
    const navigate = useNavigate()
    
    const shouldHandleClick = useMemo(() => {
        return !!onClick || !!href || !!to
    }, [onClick, href, to])
    
    const handleClick = useCallback(() => {
        if (loading) return
        if (onClick) {
            onClick()
        } else if (href) {
            window.open(href, '_blank', 'noopener')
        } else if (to) {
            navigate(to, navigateOptions)
        }
    }, [loading, onClick, href, to, navigate, navigateOptions])
    
    return (
        <button
            {...props}
            type={type}
            onClick={variant !== 'disabled' && shouldHandleClick ? handleClick : undefined}
            className={classesOf(ButtonCircularVariants({ variant }), className)}
        >
            {loading ? <Spinner/> : children}
        </button>
    )
}

export default ButtonCircular
