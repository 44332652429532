import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { classesOf } from '../../libs/utils/classes-of'


export enum SidebarItemType {
    itemWithIcon,
    itemWithInitial
}

export interface SidebarItemProps {
    name: string
    href?: string
    to?: string
    icon?: any
    type?: SidebarItemType
}

const SidebarItem: React.FC<SidebarItemProps> = ({
    name, href, to, icon: IconComponent,
    type = SidebarItemType.itemWithInitial
}) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const current = useMemo(() => pathname === to, [to, pathname])
    
    const handleClick = useCallback(() => {
        if (href) {
            window.open(href, '_blank', 'noopener')
        } else if (to) {
            navigate(to)
        }
    }, [href, navigate, to])
    
    switch (type) {
        case SidebarItemType.itemWithIcon:
            return (
                <div
                    onClick={handleClick}
                    className={classesOf(
                        current
                        ? 'bg-gray-50 text-orange-500'
                        : 'text-gray-700 hover:text-orange-500 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold cursor-pointer'
                    )}
                >
                    <span
                        className={classesOf(
                            current
                            ? 'text-orange-500 border-indigo-600'
                            : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-orange-500',
                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                        )}
                    >{Array.from(name)[0]}</span>
                    <span className='truncate'>{name}</span>
                </div>
            )
        default:
            return (
                <div
                    onClick={handleClick}
                    className={classesOf(
                        current
                        ? 'bg-gray-50 text-orange-500'
                        : 'text-gray-700 hover:text-orange-500 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 pl-7 leading-6 font-semibold cursor-pointer'
                    )}
                >
                    {IconComponent ?
                     <IconComponent
                         className={classesOf(
                             current ? 'text-orange-500' : 'text-gray-400 group-hover:text-orange-500',
                             'h-6 w-6 shrink-0'
                         )}
                         aria-hidden='true'
                     /> : null}
                    {name}
                </div>
            )
    }
}

export default SidebarItem
