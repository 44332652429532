import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo } from 'react'

import { useCreateCustomPromptMutation } from '../../../hooks/use-content'
import { useToast } from '../../../hooks/use-toast'
import { CustomPrompt } from '../../../libs/model/content'
import { trans } from '../../../locales/ko'
import { ButtonOutline, ButtonSolid } from '../../button'
import { FallbackDashboard } from '../../fallback'
import { StepProps } from '../index'
import { BuilderContentFormikProps } from './builder-content'
import MissionExpressionInput from './mission-expression-input'
import ThreadRolePlay from './thread-role-play'


const StepMission = ({ errors, touched, values, onPrev, loading, customPrompt, setCustomPrompt }: BuilderContentFormikProps & StepProps &
    { customPrompt: CustomPrompt | null, setCustomPrompt: React.Dispatch<React.SetStateAction<CustomPrompt | null>> }) => {
    
    const { showToastInline } = useToast()
    const { mutateAsync: createCustomPrompt, isPending } = useCreateCustomPromptMutation()
    
    const disabled = useMemo(() => {
        if (errors.missionExpressions) return true
        if (isEmpty(values.missionExpressions)) return true
    }, [errors.missionExpressions, values.missionExpressions])
    
    const submitDisabled = useMemo(() => {
        if (!isEmpty(errors) || isEmpty(customPrompt)) return true
        if (isPending) return true
    }, [customPrompt, errors, isPending])
    
    const handleCreatePrompt = useCallback(async () => {
        if (isPending || isEmpty(values.missionExpressions)) return
        
        try {
            const response = await createCustomPrompt(values.missionExpressions)
            setCustomPrompt(response)
        } catch (e: any) {
            showToastInline({ title: e.message, type: 'error' })
        }
    }, [createCustomPrompt, isPending, setCustomPrompt, showToastInline, values.missionExpressions])
    
    return (
        <React.Fragment>
            <div className='pb-5'>
                <h1 className='block pb-3 text-base font-semibold text-gray-900'>{trans.components.builder.content.mission}</h1>
                <div className='space-y-6 mb-6'>
                    <MissionExpressionInput errors={errors} values={values}/>
                </div>
                
                {!customPrompt ?
                 <div className='flex justify-center'>
                     <ButtonSolid onClick={handleCreatePrompt} loading={isPending} variant={disabled ? 'disabled' : 'default'} className='px-4 py-3'>{trans.components.builder.content.create_role_play}</ButtonSolid>
                 </div> : null
                }
                
                {customPrompt ?
                 <React.Fragment>
                     <div className='flex justify-between items-center mb-3'><h1 className='block text-base font-semibold text-gray-900'>{trans.components.builder.content.role_play_example}</h1>
                         <ButtonSolid onClick={handleCreatePrompt} loading={isPending} variant={disabled ? 'disabled' : 'default'} className='px-4 py-3'>{trans.components.builder.content.recreate}</ButtonSolid>
                     </div>
                     {isPending ? <FallbackDashboard/> :
                      <React.Fragment>
                          <div className='rounded-md divide-y divide-gray-200 border'>
                              <div className='grid grid-cols-2 divide-x divide-gray-200'>
                                  <div className='p-3'>{trans.components.builder.content.ai}</div>
                                  <div className='p-3'>{`${customPrompt.character.name}, ${customPrompt.character.characteristic}`}</div>
                              </div>
                              <div className='grid grid-cols-2 divide-x divide-gray-200'>
                                  <div className='p-3'>{trans.components.builder.content.learner}</div>
                                  <div className='p-3'>{`${customPrompt.user.name}, ${customPrompt.user.characteristic}`}</div>
                              </div>
                          </div>
                          <div className='space-y-4 mt-6'>
                              {customPrompt.promptUsages.map((chat, index) => <ThreadRolePlay key={`${chat.content}-${index}`} chat={chat}/>)}
                          </div>
                      </React.Fragment>
                     }
                 </React.Fragment> : null
                }
            </div>
            
            <div className='flex gap-3'>
                <ButtonOutline onClick={onPrev}>{trans.actions.prev}</ButtonOutline>
                <ButtonSolid type='submit' loading={loading} className='px-4 py-3' variant={submitDisabled ? 'disabled' : 'default'}>{trans.components.builder.content.add_button}</ButtonSolid>
            </div>
        </React.Fragment>
    )
}

export default StepMission