import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'

import { LearningHistoryData, LearningHistoryPerDay } from '../../libs/model/learning-history'
import { classesOf } from '../../libs/utils/classes-of'
import { msToHoursMinutesAndSeconds } from '../../libs/utils/fomatter-time'
import { trans } from '../../locales/ko'


interface TableLearningHistoryByWeekProps {
    weekData: LearningHistoryPerDay
    index: number
    filters: string[]
}

const TableLearningHistoryByWeek = ({ weekData, index, filters }: TableLearningHistoryByWeekProps) => {
    const days = useMemo(() => Object.keys(weekData) ?? [], [weekData])
    
    const calculateAverage = (data: LearningHistoryData[]) => {
        if (isEmpty(data)) return {}
        const sum = data.reduce((acc, curr) => ({
            accuracyPoint: acc.accuracyPoint + curr.accuracyPoint,
            fluencyPoint: acc.fluencyPoint + curr.fluencyPoint,
            missionPoint: acc.missionPoint + curr.missionPoint,
            assistantPoint: acc.assistantPoint + curr.assistantPoint,
            levelPoint: acc.levelPoint + curr.levelPoint,
            timeInMs: acc.timeInMs + curr.timeInMs,
            score: acc.score + curr.score,
            count: acc.count + 1
        }), { accuracyPoint: 0, fluencyPoint: 0, missionPoint: 0, assistantPoint: 0, levelPoint: 0, timeInMs: 0, score: 0, count: 0 })
        
        return {
            accuracyPoint: (sum.accuracyPoint / sum.count).toFixed(1),
            fluencyPoint: (sum.fluencyPoint / sum.count).toFixed(1),
            missionPoint: (sum.missionPoint / sum.count).toFixed(1),
            assistantPoint: (sum.assistantPoint / sum.count).toFixed(1),
            levelPoint: (sum.levelPoint / sum.count),
            timeInMs: msToHoursMinutesAndSeconds(sum.timeInMs / sum.count),
            count: sum.count,
            score: (sum.score / sum.count).toFixed(1)
        }
    }
    
    const calculateOverallAverage = () => {
        const allData = Object.values(weekData).flat()
        return calculateAverage(allData)
    }
    const learningDayLength = useMemo(() => Object.values(weekData).filter(perDayData => !isEmpty(perDayData)).length, [weekData])
    const overallAverage = calculateOverallAverage()
    
    return (
        <React.Fragment>
            <div className='flow-root space-y-6 px-4 sm:px-6 lg:px-8'>
                <div className='-mx-4 -my-2 sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle'>
                        <div className='p-3 mt-3 mb-3 rounded-md flex items-center justify-between'>
                            <h2 className='text-base font-semibold'>{`${index + 1}주차`}</h2>
                        </div>
                        <div className='min-w-full divide-y divide-gray-300 rounded-xl'>
                            <div className={classesOf(`grid grid-cols-${filters.length + 1} items-center text-center text-sm font-semibold`)}>
                                <div className='pr-3 py-3.5'>{trans.views.dashboard.member_details.learning_statistics.date}</div>
                                {[
                                    <div id='accuracyPoint' key='accuracyPoint' className='pr-3 py-3.5'>{trans.views.dashboard.member_details.learning_statistics.accuracyPoint}</div>,
                                    <div id='fluencyPoint' key='fluencyPoint' className='pr-3 py-3.5'>{trans.views.dashboard.member_details.learning_statistics.fluencyPoint}</div>,
                                    <div id='missionPoint' key='missionPoint' className='pr-3 py-3.5 shrink-0'>{trans.views.dashboard.member_details.learning_statistics.missionPoint}</div>,
                                    <div id='assistantPoint' key='assistantPoint' className='pr-3 py-3.5 shrink-0'>{trans.views.dashboard.member_details.learning_statistics.assistantPoint}</div>,
                                    <div id='levelPoint' key='levelPoint' className='pr-3 py-3.5'>{trans.views.dashboard.member_details.learning_statistics.levelPoint}</div>,
                                    <div id='conversation_time' key='conversation_time' className='pr-3 py-3.5'>{trans.views.dashboard.member_details.learning_statistics.conversation_time}</div>,
                                    <div id='learning_count' key='learning_count' className='pr-3 py-3.5'>{trans.views.dashboard.member_details.learning_statistics.learning_count}</div>,
                                    <div id='score' key='score' className='pr-3 py-3.5'>{trans.views.dashboard.member_details.learning_statistics.score}</div>
                                ].filter(each => filters.includes(each.props.id))}
                            </div>
                            <div className='divide-y divde-gray-100'>
                                {Object.values(weekData).map((perDayData, index) => {
                                    const averages = calculateAverage(perDayData)
                                    return (
                                        <div key={index} className={classesOf(`grid grid-cols-${filters.length + 1} items-center text-sm text-center`)}>
                                            <div className='pr-3 py-4 font-medium break-words'>{days[index]}</div>
                                            {[
                                                <div id='accuracyPoint' key='accuracyPoint' className='pr-3 py-4 break-words'>{averages.accuracyPoint ?? '-'}</div>,
                                                <div id='fluencyPoint' key='fluencyPoint' className='pr-3 py-4 break-words'>{averages.fluencyPoint ?? '-'}</div>,
                                                <div id='missionPoint' key='missionPoint' className='pr-3 py-4 break-words'>{averages.missionPoint ?? '-'}</div>,
                                                <div id='assistantPoint' key='assistantPoint' className='pr-3 py-4 break-words'>{averages.assistantPoint ?? '-'}</div>,
                                                <div id='levelPoint' key='levelPoint' className='pr-3 py-4 break-words'>{averages?.levelPoint ?? '-'}</div>,
                                                <div id='conversation_time' key='conversation_time' className='pr-3 py-4 break-words'>{averages?.timeInMs ?? '-'}</div>,
                                                <div id='learning_count' key='learning_count' className='pr-3 py-4 break-words'>{averages?.count ?? '-'}</div>,
                                                <div id='score' key='score' className='pr-3 py-4 break-words'>{averages?.score ?? '-'}</div>
                                            ].filter(each => filters.includes(each.props.id))}
                                        </div>
                                    )
                                })}
                                <div className={classesOf(`grid grid-cols-${filters.length + 1} items-center text-sm text-center`)}>
                                    <div className='pr-3 py-4 font-medium'>{trans.views.dashboard.member_details.learning_statistics.average}</div>
                                    {[
                                        <div id='accuracyPoint' key='accuracyPoint' className='pr-3 py-4 break-words'>{overallAverage.accuracyPoint ?? '-'}</div>,
                                        <div id='fluencyPoint' key='fluencyPoint' className='pr-3 py-4 break-words'>{overallAverage.fluencyPoint ?? '-'}</div>,
                                        <div id='missionPoint' key='missionPoint' className='pr-3 py-4 break-words'>{overallAverage.missionPoint ?? '-'}</div>,
                                        <div id='assistantPoint' key='assistantPoint' className='pr-3 py-4 break-words'>{overallAverage.assistantPoint ?? '-'}</div>,
                                        <div id='levelPoint' key='levelPoint' className='pr-3 py-4 break-words'>{overallAverage?.levelPoint ?? '-'}</div>,
                                        <div id='conversation_time' key='conversation_time' className='pr-3 py-4 break-words'>{overallAverage?.timeInMs ?? '-'}</div>,
                                        <div id='learning_count' key='learning_count' className='pr-3 py-4 break-words'>{overallAverage?.count ? (overallAverage.count / learningDayLength).toFixed(1) : '-'}</div>,
                                        <div id='score' key='score' className='pr-3 py-4 break-words'>{overallAverage?.score ?? '-'}</div>
                                    ].filter(each => filters.includes(each.props.id))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TableLearningHistoryByWeek
