import { cva, VariantProps } from 'class-variance-authority'
import React, { ButtonHTMLAttributes, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { palette } from '../../libs/palette'
import { classesOf } from '../../libs/utils/classes-of'
import Spinner from '../spinner'
import { ButtonProps } from './index'


const ButtonTextVariants = cva(
    `flex justify-center items-center
    text-sm font-semibold
    whitespace-nowrap`,
    {
        variants: {
            variant: {
                alert: Object.values(palette.button.text.alert),
                // FIXME Styles
                success: Object.values(palette.button.text.default),
                gray: Object.values(palette.button.text.gray),
                disabled: Object.values(palette.button.text.disabled),
                default: Object.values(palette.button.text.default)
            }
        },
        defaultVariants: {
            variant: 'default'
        }
    }
)

interface ButtonTextProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'onClick'>, VariantProps<typeof ButtonTextVariants>, ButtonProps {
}

const ButtonText = ({
    children,
    type = 'button',
    onClick, href, to, loading, navigateOptions,
    variant, className = '',
    ...props
}: ButtonTextProps) => {
    const navigate = useNavigate()
    
    const shouldHandleClick = useMemo(() => {
        return !!onClick || !!href || !!to
    }, [onClick, href, to])
    
    const handleClick = useCallback(() => {
        if (loading) return
        if (onClick) {
            onClick()
        } else if (href) {
            window.open(href, '_blank', 'noopener')
        } else if (to) {
            navigate(to, navigateOptions)
        }
    }, [loading, onClick, href, to, navigate, navigateOptions])
    
    return (
        <button
            {...props}
            type={type}
            onClick={shouldHandleClick ? handleClick : undefined}
            className={classesOf(ButtonTextVariants({ variant }), className)}
        >
            {loading && (
                <div className='mr-2'>
                    <Spinner/>
                </div>
            )}
            {children}
        </button>
    )
}

export default ButtonText
