import { getFirestore } from '@firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import * as firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'
import React from 'react'


export const kakaoClientId = '868fd5ce601bab3ffdc1b14a8b15c304'
const firebaseConfig = {
    apiKey: 'AIzaSyCgETji21VQjzHZ15_mr6omGORXevL4JT0',
    authDomain: 'chloe-ai-english.firebaseapp.com',
    projectId: 'chloe-ai-english',
    storageBucket: 'chloe-ai-english.appspot.com',
    messagingSenderId: '10785683556',
    appId: '1:10785683556:web:4206e7245cc7e223494771',
    measurementId: 'G-H9Q9YKHG4B'
}

const app = firebase.initializeApp(firebaseConfig)
getAnalytics(app)

export const firebaseAuth = getAuth(app)
export const db = getFirestore(app)

export interface FirebaseContextProps {
    app: firebase.FirebaseApp
    uid?: string
}

export const FirebaseContext = React.createContext<FirebaseContextProps>({ app: app, uid: undefined })

const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <FirebaseContext.Provider value={{ app }}>{children}</FirebaseContext.Provider>
}

export default FirebaseProvider