import React, { useCallback, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'

import { useScoreStrategyListQuery } from '../../hooks/use-score-strategy'
import { ScoreStrategy, ScoreType } from '../../libs/model/score-strategy'
import { classesOf } from '../../libs/utils/classes-of'
import { trans } from '../../locales/ko'
import { ButtonText } from '../button'
import { FallbackDashboard } from '../fallback'


const TableScoreStrategy = () => {
    const navigate = useNavigate()
    
    const { data, isLoading } = useScoreStrategyListQuery()
    const scoreStrategyList = useMemo(() => data?.strategies ?? [], [data?.strategies])
    
    const [currentPage, setCurrentPage] = useState(0)
    const itemsPerPage = 10
    const currentScoreStrategyList = useMemo(() => {
        const start = currentPage * itemsPerPage
        const end = start + itemsPerPage
        return scoreStrategyList.slice(start, end)
    }, [currentPage, scoreStrategyList])
    
    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected)
    }
    
    const getScoreTypeName = useCallback((scoreType: ScoreType) => {
        switch (scoreType) {
            case ScoreType.STARS:
                return trans.views.dashboard.setting.score_strategy.score_type_stars
            case ScoreType.SATISFACTORY:
                return trans.views.dashboard.setting.score_strategy.score_type_satisfactory
            case ScoreType.EXCELLENT:
                return trans.views.dashboard.setting.score_strategy.score_type_excellent
            default:
                return trans.views.dashboard.setting.score_strategy.score_type_stars
        }
    }, [])
    
    if (isLoading) return <FallbackDashboard/>
    return (
        <div className='mt-8 flow-root'>
            <div className='-my-2 overflow-x-auto'>
                <div className='relative inline-block w-full min-h-[700px] py-2 align-middle space-y-12 mb-12'>
                    <div className='min-w-full table-fixed divide-y divide-gray-300 mb-12'>
                        <div>
                            <div className='flex items-center ml-1'>
                                <div className='w-14 pr-3 py-4 text-left text-sm font-semibold text-gray-900'/>
                                <div className='w-full grid grid-cols-3'>
                                    <div className='py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.setting.score_strategy.name}</div>
                                    <div className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.setting.score_strategy.score_type}</div>
                                    <div className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>{trans.views.dashboard.setting.score_strategy.details}</div>
                                </div>
                            </div>
                        </div>
                        <div className='divide-y divide-gray-200 bg-white'>
                            {currentScoreStrategyList?.map((scoreStrategy: ScoreStrategy) => {
                                if (scoreStrategy.id === 'default') return (
                                    <div className='flex items-center ml-1' key={scoreStrategy.id}>
                                        <div className='w-14 whitespace-nowrap pr-3 py-4 text-sm'>1</div>
                                        <div className='w-full grid grid-cols-3'>
                                            <div className='whitespace-nowrap truncate pr-3 py-4 text-sm'>{scoreStrategy.name}</div>
                                            <div className='whitespace-nowrap truncate px-3 py-4 text-sm text-gray-500'>{getScoreTypeName(scoreStrategy.scoreType)}</div>
                                            <div className='whitespace-nowrap truncate px-3 py-4 text-sm text-gray-500'>
                                                <ButtonText variant='gray' onClick={() => navigate(`/setting/${scoreStrategy.id}`)}>{trans.views.dashboard.setting.score_strategy.button_details}</ButtonText>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {currentScoreStrategyList?.map((scoreStrategy: ScoreStrategy, index) => {
                                if (scoreStrategy.id !== 'default') return (
                                    <div className={classesOf('flex items-center ml-1')} key={scoreStrategy.id}>
                                        <div className='w-14 break-words pr-3 py-4 text-sm'>{currentPage * itemsPerPage + index + 1}</div>
                                        <div className='w-full grid grid-cols-3'>
                                            <div className='whitespace-nowrap truncate pr-3 py-4 text-sm'>{scoreStrategy.name}</div>
                                            <div className='whitespace-nowrap truncate px-3 py-4 text-sm text-gray-500'>{getScoreTypeName(scoreStrategy.scoreType)}</div>
                                            <div className='whitespace-nowrap truncate px-3 py-4 text-sm text-gray-500'>
                                                <ButtonText variant='gray' onClick={() => navigate(`/setting/${scoreStrategy.id}`)}>{trans.views.dashboard.setting.score_strategy.button_details}</ButtonText>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='absolute left-1/2 -bottom-5 transform -translate-x-1/2'>
                        <ReactPaginate
                            className='mt-8 flex items-center justify-center gap-10'
                            previousLabel={trans.actions.prev}
                            nextLabel={trans.actions.next}
                            breakLabel={'...'}
                            pageCount={Math.ceil(scoreStrategyList.length / itemsPerPage) || 1}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableScoreStrategy