import { ScoreStrategy } from '../model/score-strategy'
import { instance } from './instance'


export const getScoreStrategy = async (studyGroupId: string): Promise<{ strategies: ScoreStrategy[], priority: string[] } | null> => {
    const resp = await instance({
        method: 'GET',
        url: 'https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-getScoreStrategy',
        params: { studyGroupId }
    })
    
    const scoreStrategies: ScoreStrategy[] = Object.values(resp.data.result.strategies)
    
    const sortedScoreStrategies = scoreStrategies.sort((a, b) => {
        if (a.id === 'default') return -1
        if (b.id === 'default') return 1
        
        const indexA = resp.data.result.priority.indexOf(a.id)
        const indexB = resp.data.result.priority.indexOf(b.id)
        
        return indexA - indexB
    })
    return { strategies: sortedScoreStrategies, priority: resp.data.result.priority }
}

export const addScoreStrategy = async ({ studyGroupId, strategy }: { studyGroupId: string, strategy: Partial<ScoreStrategy> }): Promise<{ strategy: ScoreStrategy, priority: string[] } | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-addScoreStrategy`,
        data: {
            studyGroupId, strategy
        }
    })
    return resp.data.result
}

export const deleteScoreStrategy = async ({ studyGroupId, strategyIds }: { studyGroupId: string, strategyIds: string[] }): Promise<Boolean | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-deleteScoreStrategy`,
        data: {
            studyGroupId, strategyIds
        }
    })
    return resp.data.result
}

export const updateScoreStrategy = async ({ studyGroupId, strategyId, strategy }: {
    studyGroupId: string, strategyId: string | null, strategy: ScoreStrategy
}): Promise<ScoreStrategy | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-updateScoreStrategy`,
        data: {
            studyGroupId, strategyId, strategy
        }
    })
    return resp.data.result
}

export const updateScoreStrategyPriority = async ({ studyGroupId, priority }: { studyGroupId: string, priority: string[] }): Promise<Boolean | null> => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-updateScoreStrategyPriority`,
        data: {
            studyGroupId, priority
        }
    })
    return resp.data.result
}