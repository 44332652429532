import React, { SVGProps } from 'react'


const LogoChitChat = ({ ref, ...props }: SVGProps<SVGElement>) => {
    return (
        <svg width='192' height='192' viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_i_556_5470)">
                <path d="M91.7889 46.3278C65.1442 46.3278 43.5444 67.9276 43.5444 94.5722C43.5444 121.217 65.1442 142.817 91.7889 142.817C101.112 142.817 109.817 140.172 117.195 135.593C122.103 140.318 129.299 141.515 135.327 139.863C136.499 139.542 137.085 139.381 137.14 138.963C137.194 138.544 136.606 138.202 135.429 137.519C129.792 134.246 127.707 128.605 128.916 125.381C135.859 117.024 140.033 106.286 140.033 94.5722C140.033 67.9276 118.434 46.3278 91.7889 46.3278Z" fill="#F55A2B"/>
            </g>
            <circle cx="157.333" cy="150.933" r="6.93333" fill="#52CBBC"/>
            {/*<defs>*/}
            {/*    <filter id="filter0_i_556_5470" x="33.5444" y="32.3278" width="106.489" height="110.489" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">*/}
            {/*        <feFlood floodOpacity="0" result="BackgroundImageFix"/>*/}
            {/*        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>*/}
            {/*        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*        <feOffset dx="-10" dy="-14"/>*/}
            {/*        <feGaussianBlur stdDeviation="23"/>*/}
            {/*        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>*/}
            {/*        <feColorMatrix type="matrix" values="0 0 0 0 0.883333 0 0 0 0 0.302498 0 0 0 0 0.121458 0 0 0 0.8 0"/>*/}
            {/*        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_556_5470"/>*/}
            {/*    </filter>*/}
            {/*</defs>*/}
        </svg>
    )
}

export default LogoChitChat
