import { Icon } from '@iconify/react'
import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import { ButtonOutline } from '../../components/button'
import LogoVertical from '../../components/logo/logo-vertical'
import useSession from '../../hooks/use-session'
import { useToast } from '../../hooks/use-toast'
import { trans } from '../../locales/ko'


interface IFormSignIn {
    email: string
    password: string
}

const ViewSignIn = () => {
    const { signInWithGoogle, signInWithApple, signInWithKakao } = useSession()
    const { showToast } = useToast()
    const navigate = useNavigate()
    
    const handleLoginWithGoogle = useCallback(async () => {
        try {
            await signInWithGoogle()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [showToast, signInWithGoogle])
    
    const handleLoginWithApple = useCallback(async () => {
        try {
            await signInWithApple()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [showToast, signInWithApple])
    
    const handleLoginWithKakao = useCallback(async () => {
        try {
            await signInWithKakao()
        } catch (e: any) {
            console.log(e)
            showToast({ title: e.message, type: 'error' })
        }
    }, [showToast, signInWithKakao])
    
    return (
        <React.Fragment>
            <Helmet>
                <title>{trans.views.auth.sign_in.it} | {trans.meta.adrop}</title>
            </Helmet>
            
            <div className='sm:mx-auto sm:w-full sm:max-w-sm whitespace-pre-wrap'>
                <LogoVertical to='/' label={trans.views.auth.sign_in.title}/>
            </div>
            
            <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
                <div>
                    <div className='mt-6'>
                        <ButtonOutline onClick={handleLoginWithKakao} size='expand'>
                            <img src='/assets/icon/img_kakao.svg' className='w-auto h-6 scale-125 mr-2'/>
                            <span className='text-sm font-semibold leading-6'>{trans.views.auth.sign_in.kakao_sign_in}</span>
                        </ButtonOutline>
                    </div>
                    <div className='mt-6'>
                        <ButtonOutline onClick={handleLoginWithGoogle} size='expand'>
                            <Icon icon='devicon:google' className='w-auto h-5 mr-2'/>
                            <span className='text-sm font-semibold leading-6'>{trans.views.auth.sign_in.google_sign_in}</span>
                        </ButtonOutline>
                    </div>
                    <div className='mt-6'>
                        <ButtonOutline onClick={handleLoginWithApple} size='expand'>
                            <Icon icon='devicon:apple' className='w-auto h-5 mr-2'/>
                            <span className='text-sm font-semibold leading-6'>{trans.views.auth.sign_in.apple_sign_in}</span>
                        </ButtonOutline>
                    </div>
                    <div className='mt-6'>
                        <ButtonOutline onClick={() => navigate('/sign-in')} size='expand'>
                            <Icon icon='ic:baseline-email' className='w-auto h-5 mr-2'/>
                            <span className='text-sm font-semibold leading-6'>{trans.views.auth.sign_in.email_sign_in}</span>
                        </ButtonOutline>
                    </div>
                    <div className='mt-10 flex justify-center'>
                        <a href='mailto:contact@chitchat.study' className='text-gray-500 hover:text-gray-900'>
                            <span className='text-sm leading-6 underline underline-offset-4 decoration-gray-500'>{trans.views.auth.sign_in.email_inquiry}</span>
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ViewSignIn
