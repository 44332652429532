import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AuthGuard from '../components/guard/auth-guard'
import { SessionState } from '../contexts/session'
import LayoutAuth from '../layouts/auth'
import LayoutDashboard from '../layouts/dashboard/layout-dashboard'
import LayoutLanding from '../layouts/landing'
import { ViewSignIn } from '../views/auth'
import ViewEmailAuth from '../views/auth/view-email-auth'
import ViewKakaoAuth from '../views/auth/view-kakao-auth'
import { ViewMemberDetails } from '../views/dashboard/member'


const MemberRoutes = () => {
    return (
        <Routes>
            <Route element={<LayoutLanding/>}>
                <Route element={
                    <AuthGuard condition={SessionState.SignedOut}>
                        <LayoutAuth/>
                    </AuthGuard>
                }>
                    <Route path='/' element={<ViewSignIn/>}/>
                    <Route path='/sign-in' element={<ViewEmailAuth/>}/>
                    <Route path='/kakao-auth' element={<ViewKakaoAuth/>}/>
                </Route>
                <Route element={
                    <AuthGuard condition={SessionState.SignedIn}>
                        <LayoutDashboard/>
                    </AuthGuard>
                }>
                    <Route path='/dashboard' element={<ViewMemberDetails/>}/>
                </Route>
                <Route path='*' element={<Navigate to='/'/>}/>
            </Route>
        </Routes>
    )
}

export default MemberRoutes