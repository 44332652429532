import { Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'

import { useToast } from '../../../hooks/use-toast'
import { useUpdateUserMutation } from '../../../hooks/use-users'
import { Account } from '../../../libs/model/account'
import { trans } from '../../../locales/ko'
import { ButtonSolid, ButtonText } from '../../button'
import { FieldText } from '../../field'
import { PopoverClose } from '../../popover'


export interface ExplorerEditor<TValue> {
    close: PopoverClose
    afterSubmit?: (value: TValue | null) => void
}

interface IFormUpdateRealName {
    realName?: string
}

export const ExplorerEditorRealName = ({
    close,
    afterSubmit,
    user
}: ExplorerEditor<Account> & { user?: Account | null }) => {
    const { mutateAsync: updateUser, isPending } = useUpdateUserMutation()
    const { showToast } = useToast()
    
    const initialValues: IFormUpdateRealName = useMemo(() => {
        return { realName: user?.realName }
    }, [user?.realName])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            realName: Yup.string().required(trans.views.dashboard.member_details.error.real_name_required)
        })
    }, [])
    
    const onSubmit = useCallback(async (values: IFormUpdateRealName) => {
        if (isEmpty(user) || isPending) return
        try {
            const response = await updateUser({ userId: user?.id, ...values })
            afterSubmit?.call(this, response)
            close()
        } catch (e: any) {
            showToast({ title: e.message, type: 'error' })
        }
    }, [afterSubmit, close, isPending, showToast, updateUser, user])
    
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ errors, touched, values }) => (
                <Form>
                    <div className='space-y-2'>
                        <label htmlFor='realName' className='block text-gray-500 ml-1'>{trans.views.dashboard.member_details.name}</label>
                        <FieldText
                            id='realName' name='realName' type='text'
                            error={errors.realName && touched.realName}
                            placeholder={trans.views.dashboard.member_details.name}
                            autoFocus
                        />
                        <div className='flex items-center justify-end space-x-4'>
                            <ButtonText onClick={close}>{trans.actions.cancel}</ButtonText>
                            <ButtonSolid type='submit' loading={isPending}>{trans.actions.update}</ButtonSolid>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}