import { useSetAtom } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { SessionState } from '../../contexts/session'
import useSession from '../../hooks/use-session'
import { redirectionAtom } from '../../states/state-adrop'
import { Fallback } from '../fallback'


type Prop = {
    condition: SessionState
    children: React.ReactNode
}

const AuthGuard: React.FC<Prop> = ({ condition, children }) => {
    const { sessionState } = useSession()
    const { pathname } = useLocation()
    const setRedirection = useSetAtom(redirectionAtom)
    
    const to = useMemo(() => {
        switch (condition) {
            case SessionState.SignedIn:
                return '/'
            case SessionState.SignedOut:
                return '/dashboard'
            default:
                return '/'
        }
    }, [condition])
    
    useEffect(() => {
        if (condition === SessionState.SignedIn && pathname.length > 1 && pathname !== '/sign-in') {
            setRedirection(pathname)
        } else if (condition === SessionState.SignedOut) {
            setRedirection(undefined)
        }
    }, [condition, pathname, setRedirection])
    
    switch (sessionState) {
        case SessionState.Unset:
            return <Fallback/>
        default:
            if (sessionState !== condition) {
                return <Navigate to={to} replace/>
            } else {
                return children
            }
    }
}

export default AuthGuard
