import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Form, Formik, FormikProps } from 'formik'
import { range } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'

import { useChatContentsListQuery, useCreateOrUpdateAdminCommentMutation } from '../../hooks/use-chat-content'
import { useToast } from '../../hooks/use-toast'
import { LearningHistoryData } from '../../libs/model/learning-history'
import { trans } from '../../locales/ko'
import { ButtonOutline, ButtonSolid, ButtonText } from '../button'
import Divider from '../divider'
import FieldTextarea from '../field/field-textarea'
import EditDeleteMenu from '../menu/edit-delete-menu'
import SkeletonThreadListItem from '../skeleton/skeleton-thread-list-item'
import { SlideOverHeaderProps } from '../slide/components/slide-over-header'
import { ThreadListItem } from './index'


interface IFormThreadChatContent {
    comment: string
}

interface ThreadChatContentProps extends SlideOverHeaderProps {
    open: boolean
    onClose: () => void
    learningData: LearningHistoryData | null
}

const ThreadChatContent = ({ open, onClose, title, learningData }: ThreadChatContentProps) => {
    const formRef = useRef<FormikProps<IFormThreadChatContent> | null>(null)
    const [scrolledId, setScrolledId] = useState('')
    const { showToastInline } = useToast()
    const [learningSession, setLearningSession] = useState<LearningHistoryData | null>(null)
    const [isEditing, setIsEditing] = useState(false)
    
    useEffect(() => {
        if (isEmpty(learningData)) return
        setLearningSession(learningData)
    }, [learningData])
    
    const { data: chatContents, isLoading } = useChatContentsListQuery(learningSession?.id)
    const { mutateAsync: createOrUpdateAdminComment, isPending } = useCreateOrUpdateAdminCommentMutation()
    
    useEffect(() => {
        if (isEmpty(chatContents) && isLoading) return
        
        const chatContainer = document.getElementById('chat-container')
        if (chatContainer) {
            setTimeout(() => {
                chatContainer.scrollTo({
                    top: chatContainer.scrollHeight,
                    behavior: 'smooth'
                })
            }, 500)
        }
    }, [chatContents, isEditing, isLoading, open])
    
    const initialValues = useMemo(() => {
        return { comment: learningSession?.adminComment ?? '' }
    }, [learningSession?.adminComment])
    
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            comment: Yup.string()
        })
    }, [])
    
    const handleComment = useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }, [])
    
    const handleClose = useCallback(() => {
        onClose()
        setIsEditing(false)
    }, [onClose])
    
    const handleDeleteComment = useCallback(async () => {
        if (isPending || isEmpty(learningSession)) return
        
        try {
            const comment = ''
            const response = await createOrUpdateAdminComment({ learningSession, comment })
            if (response) {
                setLearningSession(prev => ({ ...prev, ...response }))
            }
        } catch (e: any) {
            showToastInline({ title: e.message, type: 'error' })
        }
    }, [createOrUpdateAdminComment, isPending, learningSession, showToastInline])
    
    const onSubmit = useCallback(async (values: IFormThreadChatContent) => {
        if (isPending || isEmpty(learningSession)) return
        
        try {
            const comment = values.comment.trim()
            const response = await createOrUpdateAdminComment({ learningSession, comment })
            if (response) {
                setLearningSession(prev => ({ ...prev, ...response }))
            }
        } catch (e: any) {
            showToastInline({ title: e.message, type: 'error' })
        }
        if (isEditing) {
            setIsEditing(false)
        }
    }, [createOrUpdateAdminComment, isEditing, isPending, learningSession, showToastInline])
    
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-20' onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-black/5'/>
                </Transition.Child>
                
                <div className='fixed inset-0 overflow-hidden'>
                    <div className='absolute inset-0 overflow-hidden'>
                        <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                            <Transition.Child
                                as={Fragment}
                                enter='transform transition ease-in-out duration-500 sm:duration-700'
                                enterFrom='translate-x-full'
                                enterTo='translate-x-0'
                                leave='transform transition ease-in-out duration-500 sm:duration-700'
                                leaveFrom='translate-x-0'
                                leaveTo='translate-x-full'
                            >
                                <Dialog.Panel className='pointer-events-auto relative w-screen max-w-2xl'>
                                    <Transition.Child
                                        as={Fragment}
                                        enter='ease-in-out duration-500'
                                        enterFrom='opacity-0'
                                        enterTo='opacity-100'
                                        leave='ease-in-out duration-500'
                                        leaveFrom='opacity-100'
                                        leaveTo='opacity-0'
                                    >
                                        <div className='absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:pr-4'>
                                            <button
                                                type='button'
                                                className='relative rounded-md text-gray-500 hover:text-gray-400 focus:outline-none'
                                                onClick={handleClose}
                                            >
                                                <span className='absolute -inset-2.5'/>
                                                <span className='sr-only'>Close panel</span>
                                                <XMarkIcon className='h-6 w-6' aria-hidden='true'/>
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    
                                    
                                    <div className='flex flex-col h-full bg-white shadow-xl'>
                                        <div className='shrink-0'>
                                            <div className='py-6 px-4 sm:px-6'>
                                                <Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>
                                                    {title}
                                                </Dialog.Title>
                                            </div>
                                        </div>
                                        
                                        <div className='grow overflow-y-scroll my-6' id='chat-container'>
                                            <div className='flex flex-col space-y-4'>
                                                {open ? isLoading ? range(6).map((_, index) => <SkeletonThreadListItem key={`pulse#${index}`}/>) :
                                                 chatContents?.map((content, index) => (
                                                     <ThreadListItem key={content.id} content={content}/>
                                                 )) : null}
                                                {learningSession?.adminComment ?
                                                 <div className={`flex w-full justify-start px-4 sm:px-6`}>
                                                     <div className={`flex gap-x-4 max-w-[60%] items-start`}>
                                                         <img src='/assets/icon/img_people_02.png' className='h-6 shrink-0 rounded-full' alt='manager_icon'/>
                                                         <div className={`w-96 rounded-md p-3 ring-1 ring-inset bg-emerald-100 ring-emerald-200`}>
                                                             <div className='flex justify-between items-center mb-1'>
                                                                 <div className='text-xs'>{trans.views.dashboard.member_details.thread.manager}</div>
                                                                 <EditDeleteMenu onDelete={handleDeleteComment} onEdit={() => setIsEditing(true)} isLoading={isPending}/>
                                                             </div>
                                                             <p className='text-sm whitespace-pre-wrap'>
                                                                 {learningSession.adminComment}
                                                             </p>
                                                         </div>
                                                     </div>
                                                 </div> : null}
                                            </div>
                                        </div>
                                        {learningSession?.adminComment ? null :
                                         <div className='shrink-0'>
                                             <Divider/>
                                             <div className='flex shrink-0 gap-x-3 py-6 px-4 sm:px-6'>
                                                 <img src='/assets/icon/img_people_02.png' className='h-6 shrink-0 rounded-full' alt='manager_icon'/>
                                                 
                                                 <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={formRef} enableReinitialize>
                                                     {({ errors, touched, values }) => {
                                                         return (
                                                             <Form className='relative flex-auto'>
                                                                 <label htmlFor='comment' className='sr-only'/>
                                                                 <FieldTextarea name='comment' rows={2} placeholder={trans.views.dashboard.member_details.thread.ph}/>
                                                                 <div className='flex justify-end mt-2'>
                                                                     <ButtonOutline onClick={handleComment} loading={isPending}>
                                                                         {trans.views.dashboard.member_details.thread.action}
                                                                     </ButtonOutline>
                                                                 </div>
                                                             </Form>
                                                         )
                                                     }}
                                                 </Formik>
                                             </div>
                                         </div>
                                        }
                                        {learningSession?.adminComment && isEditing ?
                                         <div className='shrink-0'>
                                             <Divider/>
                                             <div className='flex shrink-0 gap-x-3 py-6 px-4 sm:px-6'>
                                                 <img src='/assets/icon/img_people_02.png' className='h-6 shrink-0 rounded-full' alt='manager_icon'/>
                                                 
                                                 <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={formRef} enableReinitialize>
                                                     {({ errors, touched, values }) => {
                                                         return (
                                                             <Form className='relative flex-auto'>
                                                                 <label htmlFor='comment' className='sr-only'/>
                                                                 <FieldTextarea name='comment' rows={2} placeholder={trans.views.dashboard.member_details.thread.ph}/>
                                                                 <div className='flex justify-end mt-2 gap-6'>
                                                                     <ButtonText onClick={() => setIsEditing(false)}>
                                                                         {trans.actions.cancel}
                                                                     </ButtonText>
                                                                     <ButtonSolid onClick={handleComment} loading={isPending}>
                                                                         {trans.actions.edit}
                                                                     </ButtonSolid>
                                                                 </div>
                                                             </Form>
                                                         )
                                                     }}
                                                 </Formik>
                                             </div>
                                         </div> : null
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ThreadChatContent