import { UsersInput } from '../model/member'
import { instance } from './instance'


export const addMembers = async ({ studyGroupId, bulkData, joinedOnly }: UsersInput) => {
    const resp = await instance({
        method: 'POST',
        url: `https://asia-northeast3-chloe-ai-english.cloudfunctions.net/apiDashboard-inviteUsers`,
        data: {
            studyGroupId, bulkData, joinedOnly
        }
    })
    return resp.data.result
}