import React, { useCallback, useMemo } from 'react'
import { NavigateOptions } from 'react-router/dist/lib/context'
import { useNavigate } from 'react-router-dom'

import { classesOf } from '../../libs/utils/classes-of'
import LogoChitChat from './logo-chitchat'


interface LogoHorizontalProps {
    label?: string
    onClick?: () => void
    href?: string
    to?: string
    navigateOptions?: NavigateOptions
}

const LogoHorizontal = ({
    label,
    onClick, href, to, navigateOptions
}: LogoHorizontalProps) => {
    const navigate = useNavigate()
    
    const shouldHandleClick = useMemo(() => {
        return !!onClick || !!href || !!to
    }, [onClick, href, to])
    
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick()
        } else if (href) {
            window.open(href, '_blank', 'noopener')
        } else if (to) {
            navigate(to, navigateOptions)
        }
    }, [onClick, href, to, navigateOptions, navigate])
    
    return (
        <div className={classesOf(
            'min-[44px]',
            'flex flex-row justify-start items-center',
            shouldHandleClick ? 'cursor-pointer' : ''
        )} onClick={shouldHandleClick ? handleClick : undefined}>
            <LogoChitChat className='h-10 w-auto'/>
        </div>
    )
}

export default LogoHorizontal
