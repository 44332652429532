import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'

import { getLearningHistoryByMonth, getLearningHistoryOfGroupByMonth, getLearningHistoryRows } from '../libs/firestore/firestore-helper'


export const useLearningHistoryRows = (bulkData: any[], monthString: string) => {
    return useQuery({
        queryKey: ['learning-history-rows', bulkData, monthString],
        queryFn: () => {
            return getLearningHistoryRows({ bulkData, monthString, gmtDiff: 9 })
        },
        enabled: !isEmpty(bulkData)
    })
}

export const useExportLearningHistoryRowsMutation = () => {
    const queryClient = useQueryClient()
    
    return useMutation({
        mutationFn: ({ bulkData, monthString, gmtDiff }: { bulkData: any[], monthString: string, gmtDiff: number }) => getLearningHistoryRows({ bulkData, monthString, gmtDiff }),
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['learningHistoryRows', variables.monthString] })
        }
    })
}

export const useLearningHistoryListQuery = (userIds: string[], monthString: string) => {
    return useQuery({
        queryKey: ['learning-history', userIds[0], monthString],
        queryFn: () => {
            return getLearningHistoryByMonth({ userIds, monthString })
        },
        enabled: !isEmpty(userIds)
    })
}

export const useLearningHistoryOfGroupQuery = (studyGroupId: string, monthString: string) => {
    return useQuery({
        queryKey: ['learning-history-group', studyGroupId, monthString],
        queryFn: () => {
            return getLearningHistoryOfGroupByMonth(studyGroupId, monthString)
        }
    })
}